import React, { useEffect, useState, useRef } from 'react';
import { TextField, Box, Button, Container, Typography, Select, MenuItem, FormControl, 
    InputLabel, Avatar, Tooltip, IconButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Editor } from '@tinymce/tinymce-react';

const CourseRegisterPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [course, setCourse] = useState({
        title: '',
        description: '',
        category: '',
        price: '',
        isTrending: false,
        thumbnailUrl: null,
        previewVideoName: null,
        authors: [],
        memberships: []
    });

    const [availableMemberships, setAvailableMemberships] = useState([]);
    const [availableAuthors, setAvailableAuthors] = useState([]);
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    const [selectedPreviewVideo, setSelectedPreviewVideo] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [previewVideoName, setPreviewVideoName] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const navigate = useNavigate();
    const editorRef = useRef(null);

    useEffect(() => {
        const fetchMemberships = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/memberships`);
                setAvailableMemberships(response.data);
            } catch (error) {
                console.error('Error fetching memberships:', error);
                setError('Failed to fetch memberships.');
                setSnackbarOpen(true);
            }
        };

        const fetchAuthors = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/authors`);
                setAvailableAuthors(response.data);
            } catch (err) {
                console.error('Error fetching authors:', err);
                setError('Failed to load authors.');
                setSnackbarOpen(true);
            }
        };

        fetchMemberships();
        fetchAuthors();
    }, []);

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setCourse({ ...course, [name]: value });
    // };

    const handleInputChange = (e, fieldName) => {
        if (typeof e === 'string') {
            // ReactQuill provides the updated value directly
            setCourse((prev) => ({ ...prev, [fieldName]: e }));
        } else {
            // Handle regular inputs (events)
            const { name, value } = e.target;
            setCourse((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (!file) return;

        if (type === "thumbnail") {
            const reader = new FileReader();
            reader.onloadend = () => {
                setThumbnailUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setSelectedThumbnail(file);
        } 
        else if (type === "previewVideo") {
            setSelectedPreviewVideo(file);
            setPreviewVideoName(file.name);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        const formData = new FormData();
        formData.append('title', course.title);
        formData.append('description', editorRef.current.getContent());
        formData.append('category', course.category);
    
        // If price is not empty, parse it and append to FormData
        if (course.price) {
            const parsedPrice = parseFloat(course.price);
            if (!isNaN(parsedPrice)) {
                formData.append('price', parsedPrice); // Append parsed price only if it's valid
            } else {
                setError('Please provide a valid price.');
                return; // Don't proceed if price is invalid
            }
        }

        formData.append('isTrending', course.isTrending);
    
        // if (selectedThumbnail) {
        //     formData.append('thumbnailUrl', selectedThumbnail);
        // }

        // if (selectedPreviewVideo) {
        //     formData.append('previewVideoUrl', selectedPreviewVideo);
        // }

        // Append files (thumbnail and preview video)
        if (selectedThumbnail) {
            formData.append("thumbnailFile", selectedThumbnail);
        }

        if (selectedPreviewVideo) {
            formData.append("previewVideoFile", selectedPreviewVideo); 
        }
    
        // MembershipIds (including empty array)
        formData.append('membershipIds', JSON.stringify(course.memberships.map((m) => m.id)));
        //formData.append('membershipIds', course.memberships.map((m) => m.id)); // Fix: send as plain array


        // AuthorIds (including empty array)
        formData.append('authorIds', JSON.stringify(course.authors.map((a) => a.id)));
        //formData.append('authorIds', course.authors.map((a) => a.id));


        try {
            const response = await axios.post(`${API_BASE_URL}/api/courses/course/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 201) {
                setSuccess('Course created successfully!');
                navigate('/courses');
            }
    
            console.log('Course created:', response.data);
        } catch (error) {
            console.error('Error creating course:', error.response?.data || error.message);
            setError('Creating course failed. Please refresh the page and try again.');
        }
    };

    const handleCancel = () => {
        navigate('/courses');
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Typography variant="h2" gutterBottom>
                Create a new course
            </Typography>
            
            <Box 
                sx={{
                    mt: 4,
                    p: 6,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                {error && <Typography color="error" marginBottom="50px">{error}</Typography>}
                {success && <Typography color="primary" marginBottom="50px">{success}</Typography>}
                <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
                    <TextField
                        name="title"
                        label="Course Title"
                        value={course.title}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Box>

                {/* <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
                    <TextField
                        name="description"
                        label="Course Description"
                        value={course.description}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        multiline
                        maxRows={5}
                        minRows={5}
                        sx={{ marginBottom: '20px' }}
                    />
                </Box> */}

                {/* WYSIWYG Editor for Description */}
                <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '70px' }}>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        Description <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Editor
                        apiKey='16xkbsggpf5mf009anlqhpejh1xhoh0hjv6itfy16u948tin'
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={course.description}
                        init={{
                            plugins: [
                            // Core editing features
                            'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                            // Your account includes a free trial of TinyMCE premium features
                            // Try the most popular premium features until Feb 19, 2025:
                            'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown','importword', 'exportword', 'exportpdf'
                            ],
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                            { value: 'First.Name', title: 'First Name' },
                            { value: 'Email', title: 'Email' },
                            ],
                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                        }}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
                    {/* Category Dropdown */}
                    <FormControl fullWidth sx={{ marginBottom: '30px' }}>
                        <InputLabel id="category-label" required>Category</InputLabel>
                        <Select
                            name="category"
                            value={course.category}
                            label="Category"
                            onChange={handleInputChange}
                            fullWidth
                            style={{ height: '45px' }}
                        >
                            <MenuItem value="">
                                <em>Please select a category</em>
                            </MenuItem>
                            <MenuItem value="money">Money</MenuItem>
                            <MenuItem value="investments">Investments</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        name="price"
                        label="Price"
                        type="number"
                        value={course.price}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: '20px' }}
                    />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginBottom: '50px' }}>
                    {/* isTrending Checkbox */}
                    <FormGroup>
                        <Typography 
                            variant="h6" 
                            sx={{ marginBottom: 2, color: 'primary.main' }}
                        >
                            Do you want this course to be in the Trending list?
                        </Typography>
                        <FormControlLabel
                            key={course.isTrending}
                            control={
                                <Checkbox
                                    checked={course.isTrending}
                                    onChange={(e) => setCourse({ ...course, isTrending: e.target.checked })}
                                />
                            }
                        />
                    </FormGroup>
                    
                    {/* Membership Selection */}
                    <FormGroup>
                        <Typography 
                            variant="h6" 
                            sx={{ marginBottom: 2, color: 'primary.main' }}
                        >
                            Memberships
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                            {availableMemberships.map((membership) => (
                                <FormControlLabel
                                    key={membership.id}
                                    control={
                                        <Checkbox
                                            checked={Array.isArray(course.memberships) && course.memberships.some((m) => m.id === membership.id)}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                setCourse((prevCourse) => {
                                                    const updatedMemberships = checked
                                                        ? [...prevCourse.memberships, membership] // Add the full membership object if checked
                                                        : prevCourse.memberships.filter((m) => m.id !== membership.id); // Remove the object if unchecked
                                                    return { ...prevCourse, memberships: updatedMemberships };
                                                });
                                            }}
                                        />
                                    }
                                    label={membership.name}
                                />
                            ))}
                        </Box>
                    </FormGroup>

                    {/* Author Selection */}
                    <FormGroup>
                        <Typography 
                            variant="h6" 
                            sx={{ marginBottom: 2, color: 'primary.main' }}
                        >
                                Authors
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                            {availableAuthors.map((author) => (
                                <FormControlLabel
                                    key={author.id}
                                    control={
                                        <Checkbox
                                            checked={course.authors?.some((a) => a.id === author.id)}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                setCourse((prevCourse) => {
                                                    const updatedAuthors = checked
                                                        ? [...prevCourse.authors, author]  // Add if checked
                                                        : prevCourse.authors.filter((a) => a.id !== author.id);  // Remove if unchecked
                                                    return { ...prevCourse, authors: updatedAuthors };
                                                });
                                            }}
                                        />
                                    }
                                    label={author.name}
                                />
                            ))}
                        </Box>
                    </FormGroup>
                </Box>

                <Typography 
                    variant="h6" 
                    sx={{ marginBottom: 2, color: 'primary.main' }}>
                        Thumbnail <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '30px' }}>
                    {thumbnailUrl && (
                        <Box
                            component="img"
                            src={thumbnailUrl}
                            alt={course.title}
                            sx={{
                                width: "50%",
                                height: "auto",
                                borderRadius: 2,
                            }}
                            style={{ border: '2px solid #189AB4' }}
                        />
                    )}

                    <Tooltip title="Upload a thumbnail">
                        <IconButton component="label" style={{ color: '#189AB4' }}>
                            <EditIcon sx={{ fontSize: 30 }} />
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={(e) => handleFileChange(e, "thumbnail")}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>

                {/* Preview Video Upload */}
                <Typography 
                    variant="h6" 
                    sx={{ marginBottom: 2, color: 'primary.main' }}
                >
                    Preview Video
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: "30px" }}>
                    <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => handleFileChange(e, "previewVideo")}
                        style={{ display: "none" }}
                        id="preview-video-file"
                    />
                    <label htmlFor="preview-video-file">
                        <Button variant="contained" color="primary" component="span" sx={{ color: '#fff' }} startIcon={<AddCircleOutlineOutlinedIcon />}>
                            {previewVideoName ? "Change Video" : "Upload Video"}
                        </Button>
                    </label>
                    {previewVideoName && <Typography>{previewVideoName}</Typography>}
                </Box>
                
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<SaveIcon />}
                        onClick={handleSubmit}
                        style={{ color: 'white'}}
                        fullWidth
                    >
                        Create Course
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<CancelIcon />}
                        onClick={handleCancel}
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default CourseRegisterPage;