import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';

const UnauthorizedPage = () => {
  <Container>
    <Box my={4}>
      <Typography variant="h2" gutterBottom>
        Unauthorized
      </Typography>
      <Typography variant="body1" paragraph>
        Sorry, you don't have access to this page.
      </Typography>
      <Button variant="contained" color="primary" href="/login" style={{color: 'white'}}>
        Login
      </Button>
    </Box>
  </Container>
};

export default UnauthorizedPage;
