import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const UserRegisterPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();

    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const validateForm = () => {
        let newErrors = {};
    
        if (!/^[\p{L}\s]+$/u.test(firstname.trim())) {
            newErrors.firstname = "First name can only contain letters and spaces.";
        }
        
        if (!/^[\p{L}\s]+$/u.test(lastname.trim())) {
            newErrors.lastname = "Last name can only contain letters and spaces.";
        }
        
        if (!/^\S+@\S+\.\S+$/.test(email)) {
            newErrors.email = "Invalid email format.";
        }
    
        if (!/^\d+$/.test(phonenumber)) {
            newErrors.phonenumber = "Phone number must contain only digits.";
        }
    
        if (password.length < 8 || !/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password) || !/[\W_]/.test(password)) {
            newErrors.password = "Password must be at least 8 characters and include uppercase, lowercase, number, and special character.";
        }
    
        if (password !== confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
        }
    
        setErrors(newErrors);
    
        return Object.keys(newErrors).length === 0;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!validateForm()) {
            return;
        }

        const userData = {
            firstName: firstname,
            lastName: lastname,
            //userName: `${firstname}${lastname}`.toLowerCase(),
            //userName: firstname.toLowerCase().replace(/\s+/g, '') + lastname.toLowerCase().replace(/\s+/g, ''),
            //userName: email.split('@')[0],
            userName: email,
            email: email,
            phoneNumber: phonenumber,
            city: city,
            country: country,
            password: password,
            confirmPassword: confirmPassword,
            membershipId: "4532b508-9400-4aed-955a-814ae8812477",
        };

        try {
            const response = await axios.post(`${API_BASE_URL}/api/account/user-register`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setSuccess("User registered successfully!");
                navigate(response.data.redirectUrl || '/mycourses');
            }
        } catch (error) {
            console.error("Registration error:", error.response?.data || error.message);
            if (error.response?.data?.message) {
                // If the API returns a specific error message, set it
                setError(error.response.data.message);
            } else {
                // If no specific error message from API, use a generic message
                setError("Registration failed. Please try again.");
            }
        }
    };

    return (
        <Container sx={{ mt: { xs: 3, xxl: 8 }, maxWidth: '800px !important' }}>
            <Box
                sx={{
                    mt: { xs: 2, xxl: 4 },
                    px: { xs: 2, xxl: 6 },
                    py: { xs: 4, xxl: 6 }, 
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: "#fff"
                }}
            >
                <Typography variant="h4" gutterBottom sx={{ textAlign: "center", marginBottom: '30px' }}>
                    Create Your Account
                </Typography>

                {error && <Typography color="error">{error}</Typography>}
                {success && <Typography color="primary">{success}</Typography>}

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: { xs: 0, xxl: 2 } }}>
                    <Box sx={{ display: 'flex', gap: { xs: 1, xxl: 4 }, marginBottom: '20px' }}>
                        <TextField
                            label="First Name"
                            placeholder="John"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            error={!!errors.firstname}
                            helperText={errors.firstname}
                        />
                        <TextField
                            label="Last Name"
                            placeholder="Doe"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            error={!!errors.lastname}
                            helperText={errors.lastname}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: { xs: 1, xxl: 4 }, marginBottom: '20px' }}>
                        <TextField
                            label="Email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                        <TextField
                            label="Phone number"
                            placeholder="0712345678"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={phonenumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            error={!!errors.phonenumber}
                            helperText={errors.phonenumber}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: { xs: 1, xxl: 4 }, marginBottom: '20px' }}>
                        <TextField
                            label="City"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            required
                            error={!!errors.city}
                            helperText={errors.city}
                        />
                        <TextField
                            label="Country"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            required
                            error={!!errors.country}
                            helperText={errors.country}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: { xs: 1, xxl: 4 }, marginBottom: '15px' }}>
                        <TextField
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                        <TextField
                            label="Confirm password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                        />
                    </Box>

                    <Button type="submit" 
                        variant="contained" 
                        color="primary" 
                        style={{ color: 'white', textAlign: "center" }}
                        sx={{ mt: { xs: 0, xx: 2 } }}
                    >
                        Register
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default UserRegisterPage;