import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const TermsAndConditionsPage = () => {
  return (
    <Box sx={{ backgroundColor: '#f8f8f8', display: 'flex0', alignItems: 'center', justifyContent: 'center'}}>
      <Container sx={{ backgroundColor: '#fff', p: 4, borderRadius: 2, boxShadow: 2, maxWidth: '1450px !important' }}>
        {/* Header Section */}
        <Typography
          variant="h4"
          sx={{
            color: '#189AB4',
            fontWeight: 'bold',
            mb: 4,
            fontFamily: 'Instrument Sans, sans-serif',
          }}
        >
          Politica de Confidențialitate și Protecția Datelor Personale
        </Typography>

        {/* Content */}
        <Typography
          variant="body1"
          sx={{
            color: '#1a2b4c',
            lineHeight: 1.8,
          }}
        >
          S.C. ACADEMILLION S.R.L. (denumit în continuare „Prestatorul”) respectă confidențialitatea și protecția datelor personale ale Beneficiarilor săi (denumiți în continuare „Beneficiarul”). În cadrul furnizării serviciilor, Prestatorul colectează, prelucrează și stochează datele personale ale Beneficiarului, în conformitate cu Regulamentul General privind Protecția Datelor (GDPR).
        </Typography>

        {/* Sections */}
        {[
          {
            title: '1. Scopurile prelucrării datelor',
            content: `Datele personale ale Beneficiarului vor fi colectate și prelucrate în scopuri specifice, inclusiv:
            • Administrarea calității de membru și gestionarea participării la serviciile oferite (plan financiar personalizat, acces la platforma Academillion, workshopuri, sesiuni de mastermind, evenimente și experiențe).
            • Trimiterea de informații despre serviciile și produsele noastre, inclusiv newslettere și materiale educaționale.
            • Procesarea plăților și îndeplinirea obligațiilor legale și fiscale.
            • Activități de marketing, pentru a informa Beneficiarul informații relevante.
            `,
          },
          {
            title: '2. Drepturile Beneficiarului',
            content: `Beneficiarul are următoarele drepturi privind datele sale personale:
            • Dreptul de acces: să solicite informații privind datele personale pe care le deținem.
            • Dreptul la rectificare: să solicite modificarea datelor incorecte.
            • Dreptul la ștergerea datelor (dreptul de a fi uitat): să solicite ștergerea datelor, în anumite condiții.
            • Dreptul la restricționarea prelucrării: să solicite limitarea modului în care prelucrăm datele.
            • Dreptul la portabilitatea datelor: să solicite transferul datelor către un alt operator.
            • Dreptul de a se opune prelucrării: să se opună prelucrării datelor, inclusiv profilării, în anumite cazuri.
            Pentru exercitarea acestor drepturi, Beneficiarul poate trimite o solicitare la adresa de email: office@academillion.com.
            `,
          },
          {
            title: '3. Stocarea datelor',
            content: `Datele personale vor fi păstrate pe durata calității de membru și pentru o perioadă de 3 ani după încetarea acesteia, conform legislației fiscale. În cazul în care Beneficiarul își retrage consimțământul, datele vor fi șterse, cu excepția cazurilor în care păstrarea acestora este necesară din motive legale.`,
          },
          {
            title: '4. Măsuri de securitate',
            content:
              'Prestatorul aplică măsuri tehnice și organizatorice pentru a proteja datele personale ale Beneficiarului, inclusiv criptarea, controlul accesului și backup-uri regulate.',
          },
          {
            title: '5. Consimțământul',
            content: `Prin înregistrare, Beneficiarul își exprimă consimțământul explicit pentru colectarea și prelucrarea datelor sale personale în scopurile menționate mai sus. Consimțământul poate fi retras în orice moment, printr-o solicitare trimisă la adresa office@academillion.com.`,
          },
          {
            title: '6. Transferul datelor',
            content: `Datele personale pot fi partajate cu terți, precum parteneri de afaceri sau furnizori de servicii, în scopurile menționate mai sus. În cazul în care datele sunt transferate în afacerea Spațiului Economic European (SEE), Prestatorul va asigura măsurile adecvate de protecție.`,
          },
          {
            title: '7. Contact',
            content: `Pentru orice întrebări sau solicitări privind protecția datelor personale, Beneficiarul poate contacta Prestatorul la:
            • E-mail: office@academillion.com
            • Telefon: +40 749 345 433
            `,
          },
          {
            title: 'Acceptarea Politicii de Confidențialitate și Protecția Datelor Personale',
            content: `Prin înregistrarea ca Membru, Beneficiarul confirmă că a citit, înțeles și acceptat Politica de Confidențialitate și Protecția Datelor Personale a S.C. ACADEMILLION S.R.L. pentru colectarea și prelucrarea datelor sale personale în scopurile menționate în acest document. Beneficiarul poate retrage consimțământul în orice moment, iar acest lucru nu va afecta legalitatea prelucrării datelor înainte de retragerea consimțământului.`,
          },
        ].map((section, index) => (
          <Box key={index} sx={{ mt: 4 }}>
            <Typography
              variant="h5"
              sx={{
                color: '#189AB4',
                fontWeight: 'bold',
                mb: 1,
              }}
            >
              {section.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#1a2b4c',
                whiteSpace: 'pre-line',
                lineHeight: 1.8,
              }}
            >
              {section.content}
            </Typography>
          </Box>
        ))}

        {/* Footer */}
        <Typography
          variant="body2"
          align="center"
          sx={{
            mt: 6,
            pt: 4,
            color: '#1a2b4c',
            borderTop: '1px solid #ddd',
          }}
        >
          Politica de Confidențialitate și Protecția Datelor Personale
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsAndConditionsPage;
