import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import VisitorsPage from './pages/VisitorsPage';
import OurClub from './pages/visitors/OurClub';
import OurCulture from './pages/visitors/OurCulture';
import ContactPage from './pages/visitors/ContactPage';
import BlogsPage from './pages/visitors/BlogsPage';
import UnauthorizedPage from './pages/auth/UnauthorizedPage';
import ProfilePage from './pages/ProfilePage';
import AdminDashboard from './pages/admin/AdminDashboard';

import PurschasePage from './pages/PurchasePage';
import PaymentPage from './pages/members/PaymentPage';
import TermsAndConditionsPage from './pages/members/TermsAndConditionsPage';

import MembersCoursesPage from './pages/members/MembersCoursesPage';
import CoursePage from './pages/members/CoursePage';
import LessonPage from './pages/members/LessonPage';
import AuthorDetailsPage from './pages/members/AuthorDetailsPage';

import LoginPage from './pages/auth/LoginPage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import RequestPasswordResetPage from './pages/auth/RequestPasswordResetPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';

import UserRegisterPage from './pages/members/UserRegisterPage';
import MembersPage from './pages/admin/members/MembersPage';
import MemberRegisterPage from './pages/admin/members/MemberRegisterPage';
import MemberDetailPage from './pages/admin/members/MemberDetailPage';
import MembershipsPage from './pages/admin/memberships/MembershipsPage';
import MembershipDetailPage from './pages/admin/memberships/MembershipDetailPage';
import EditMembershipPage from './pages/admin/memberships/EditMembershipPage';
import MembershipRegisterPage from './pages/admin/memberships/MembershipRegisterPage';

import CoursesPage from './pages/admin/courses/CoursesPage';
import CourseRegisterPage from './pages/admin/courses/CourseRegisterPage';
import CourseDetailPage from './pages/admin/courses/CourseDetailPage';
import AuthorsPage from './pages/admin/authors/AuthorsPage';
import AuthorDetailPage from './pages/admin/authors/AuthorDetailPage';
import AuthorRegisterPage from './pages/admin/authors/AuthorRegisterPage';
import LessonsPage from './pages/admin/lessons/LessonsPage';
import LessonDetailPage from './pages/admin/lessons/LessonDetailPage';
import LessonRegisterPage from './pages/admin/lessons/LessonRegisterPage';
import SubLessonsPage from './pages/admin/sublessons/SubLessonsPage';
import SubLessonDetailPage from './pages/admin/sublessons/SubLessonDetailPage';
import MarketingPage from './pages/admin/marketing/MarketingPage';

import Navbar from './components/Navbar';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React themes
import theme from "./assets/theme";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AAD_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_AAD_REDIRECT_URI,
  },
};

const pca = new PublicClientApplication(msalConfig);

function App() {
  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Navbar />
          <Routes>
            <Route path="/" element={<OurClub />} />
            <Route path="/ourculture" element={<OurCulture />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route path="/register" element={<UserRegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/request-password-reset" element={<RequestPasswordResetPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/mycourses" element={<MembersCoursesPage />} />
            <Route path="/mycourses/course/:id" element={<CoursePage />} />
            <Route path="/mycourses/course/:id/lesson/:lessonId" element={<LessonPage />} />
            <Route path="/authordetails/:id" element={<AuthorDetailsPage />} />

            <Route path="/purchase" element={<PurschasePage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/termsandconditions" element={<TermsAndConditionsPage />} />
            


            <Route path="/members" element={<MembersPage />} />
            <Route path="/member/:userName" element={<MemberDetailPage />} />
            <Route path="/member/register" element={<MemberRegisterPage />} />
            <Route path="/courses" element={<CoursesPage />} />
            <Route path="/course/register" element={<CourseRegisterPage />} />
            <Route path="/course/:id" element={<CourseDetailPage />} />
            <Route path="/authors" element={<AuthorsPage />} />
            <Route path="/author/:id" element={<AuthorDetailPage />} />
            <Route path="/author/register" element={<AuthorRegisterPage />} />
            <Route path="/lessons" element={<LessonsPage/>} />
            <Route path="/lesson/:id" element={<LessonDetailPage/>} />
            <Route path="/lesson/register" element={<LessonRegisterPage />} />
            <Route path="/sublessons" element={<SubLessonsPage/>} />
            <Route path="/sublessons/:id" element={<SubLessonDetailPage/>} />
            <Route path="/marketing" element={<MarketingPage/>} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/memberships" element={<MembershipsPage />} />
            <Route path="/memberships/:id" element={<MembershipDetailPage />} />
            <Route path="/memberships/edit/:id" element={<EditMembershipPage />} />
            <Route path="/membership/register" element={<MembershipRegisterPage />} />

          </Routes>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
