/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import colors from "../base/colors";
import typography from "../base/typography";

const { secondary } = colors;
const { size } = typography;

const link = {
  defaultProps: {
    underline: "none",
  },
  styleOverrides: {
    root: {
      color: secondary.main,
      textDecoration: "none",
      //fontSize: "0.9rem",
      fontSize: size.md,
      cursor: "pointer",
      "&:visited": {
        color: secondary.main,
      },
    },
  },
};

export default link;
