import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, CircularProgress, Box, FormControl, FormGroup, FormControlLabel, Checkbox  } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

const MembershipRegisterPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [membership, setMembership] = useState({ 
        name: '', 
        description: '', 
        price: '', 
        courses: [] 
    });
    
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/courses/courses`); // Adjust API endpoint accordingly
                setCourses(response.data);
            } catch (err) {
                setError('Error fetching courses. Please refresh and try again.');
            }
        };

        fetchCourses();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMembership((prevMembership) => ({
            ...prevMembership,
            [name]: value,
        }));
    };

    const handleCourseChange = (courseId) => {
        setMembership((prevMembership) => {
            const isCourseSelected = prevMembership.courses.includes(courseId);
            const newCourses = isCourseSelected
                ? prevMembership.courses.filter(id => id !== courseId) // Remove course if it was selected
                : [...prevMembership.courses, courseId]; // Add course if it was not selected

            return { ...prevMembership, courses: newCourses };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        const formData = new FormData();
        formData.append('name', membership.name);
        formData.append('description', membership.description);
        formData.append('price', parseFloat(membership.price));

        membership.courses.forEach(courseId => {
            formData.append('courseIds', courseId);
        });

        try {
            const response = await axios.post(`${API_BASE_URL}/api/memberships/membership/register`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                setSuccess('Membership registered successfully!');
                navigate('/memberships');
            }            
        } catch (err) {
            console.error('Error registering membership:', err.response ? err.response.data : err.message);
            setError('Error registering membership. Please refresh and try again.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><CircularProgress style={{ color: '#189AB4' }} /></Container>;
    }

    if (error) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><Typography color="error">{error}</Typography></Container>;
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 8 }}>
            <Typography variant="h2" gutterBottom>
                Create a new membership
            </Typography>

            {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
            {success && <Typography color="primary" sx={{ mb: 2 }}>{success}</Typography>}

            <form onSubmit={handleSubmit}>
                <Box 
                    sx={{
                        mt: 4,
                        p: 6,
                        boxShadow: 3,
                        borderRadius: 2,
                    }}
                >
                    <TextField
                        label="Name"
                        name="name"
                        value={membership.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        style={{marginBottom: '50px'}}
                    />
                    <TextField
                        label="Description"
                        name="description"
                        value={membership.description}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        multiline
                        rows={5}
                        style={{marginBottom: '50px'}}
                    />
                    <TextField
                        label="Price"
                        name="price"
                        type="number"
                        value={membership.price}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        style={{marginBottom: '0px'}}
                    />

                    {/* Checkboxes for courses */}
                    <FormControl component="fieldset" sx={{ mt: 4 }}>
                        <FormGroup>
                            <Typography variant="h6">Select Courses</Typography>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between'
                            }}>
                                {courses.map((course) => (
                                    <FormControlLabel
                                        key={course.id}
                                        control={
                                            <Checkbox
                                                checked={membership.courses.includes(course.id)}
                                                onChange={() => handleCourseChange(course.id)}
                                            />
                                        }
                                        label={course.title}
                                    />
                                ))}
                            </Box>
                        </FormGroup>
                    </FormControl>

                    {error && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            style={{ color: 'white'}}
                            startIcon={<SaveOutlinedIcon />}
                            disabled={loading}
                        >
                            {loading ? 'Creating...' : 'Create'}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => navigate('/memberships')}
                            startIcon={<CancelIcon />}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </form>
        </Container>
    );
};

export default MembershipRegisterPage;