import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, Select, MenuItem, InputLabel, FormControl, IconButton, InputAdornment } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AuthService from '../../../services/auth-service.js';

const MemberRegisterPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [username, setUserName] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [membershipType, setMembershipType] = useState('');
    const [memberships, setMemberships] = useState([]); 
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchMemberships = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/memberships`);
                setMemberships(response.data);
            } catch (error) {
                console.error("Error fetching memberships:", error);
                setError("Failed to load memberships.");
            }
        };

        fetchMemberships();
    }, [baseUrl]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        const generatedUsername = `${firstname}${lastname}`.toLowerCase();

        const userData = {
            firstName: firstname,
            lastName: lastname,
            userName: generatedUsername,
            city: city,
            county: county,
            country: country,
            address: address,
            zipCode: zipcode,
            phoneNumber: phonenumber,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            membershipId: membershipType, // Add selected membership type
        };

        try {
            const token = AuthService.getAuthToken();
            if (!token) {
                setError('No authentication token found. Please log in.');
                return;
            }

            const response = await axios.post(`${API_BASE_URL}/api/users/member/register`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setSuccess('User registered successfully!');
                navigate('/members');
            }
        } catch (error) {
            console.error('Error creating member:', error.response?.data || error.message);
            setError('Registration failed. Please refresh the page and try again.');
        }
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Typography variant="h2" gutterBottom>
                Create a new member
            </Typography>

            <Box 
                sx={{
                    mt: 4,
                    p: 6,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                {error && <Typography color="error" marginBottom="20px">{error}</Typography>}
                {success && <Typography color="primary" marginBottom="20px">{success}</Typography>}
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
                        <TextField
                            label="First Name"
                            placeholder="John"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <TextField
                            label="Last Name"
                            placeholder="Doe"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
                        {/* Membership Type Selection */}
                        <FormControl fullWidth variant="outlined" margin="normal" required>
                            <InputLabel id="membership-type-label">Membership Type</InputLabel>
                            <Select
                                labelId="membership-type-label"
                                value={membershipType}
                                onChange={(e) => setMembershipType(e.target.value)}
                                label="Membership type"
                                fullWidth
                                style={{marginBottom: '20px', height: '45px'}}
                            >
                                <MenuItem value="">
                                    <em>Please select a membership</em>
                                </MenuItem>
                                {memberships.map((membership) => (
                                    <MenuItem key={membership.id} value={membership.id}>
                                        {membership.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Email"
                            type="email"
                            placeholder="john.doe@gmail.com"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
                        <TextField
                            label="Phone number"
                            placeholder="0712345678"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={phonenumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                        <TextField
                            label="Street address"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
                        <TextField
                            label="City"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            required
                        />
                        <TextField
                            label="Postal code"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={zipcode}
                            onChange={(e) => setZipCode(e.target.value)}
                            required
                        />
                    </Box>

                    <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
                        <TextField
                            label="State/Province"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={county}
                            onChange={(e) => setCounty(e.target.value)}
                            required
                        />
                        <TextField
                            label="Country"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            required
                        />
                    </Box>

                    {/* Password Fields with Toggle Visibility */}
                    <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
                        <TextField
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Confirm password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="secondary" 
                            style={{ color: 'white' }}
                            startIcon={<SaveOutlinedIcon />}
                        >
                            Create
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => navigate('/members')}
                            startIcon={<CancelIcon />}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default MemberRegisterPage;
