import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import ReactPlayer from "react-player";
import { Box, Typography } from "@mui/material";

const VideoPlayer = ({ blobUrl, trackProgress = false, userId, videoId, onComplete }) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [completed, setCompleted] = useState(false);


  // ✅ Fetch saved progress only when tracking is enabled
  useEffect(() => {
    if (!trackProgress || !userId || !videoId) return;

    axios.get(`${API_BASE_URL}/api/videoprogress/progress/${userId}/${videoId}`)
      .then((response) => {
        if (response.data?.progress !== null) {
          setProgress(response.data.progress);
          setCompleted(response.data.completed);
        }
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          console.warn("No saved progress, starting from 0.");
        } else {
          console.error("Error fetching video progress:", error);
        }
      });
  }, [userId, videoId, trackProgress]);

  // ✅ Save progress every 5 seconds if tracking is enabled
  useEffect(() => {
    if (!trackProgress || !userId || !videoId) return;

    const interval = setInterval(() => {
      if (playerRef.current && playing) {
        const currentTime = playerRef.current.getCurrentTime();
        
        if (currentTime !== progress){
          setProgress(currentTime);

          axios.post(`${API_BASE_URL}/api/videoprogress/save`, {
            memberId: userId,
            lessonId: videoId,
            progress: currentTime,
            completed: completed
          }).catch((error) => console.error("Error saving progress:", error));
        }
        
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [userId, videoId, trackProgress, completed, playing]);

  const handleSeek = (seekTime) => {
    playerRef.current.isSeeking = true;

    setPlaying(false); 
    setProgress(seekTime);
    
    setTimeout(() => { playerRef.current.isSeeking = false; }, 1000);
  };

  const handleVideoProgress = ({ playedSeconds }) => {
    if (!playerRef.current?.seeking) {
      setProgress(playedSeconds);
    }
  
    if (playedSeconds >= duration * 0.95 && !completed) {
      handleMarkAsComplete();
    }
  };
  

  const handleMarkAsComplete = () => {
    if (!trackProgress || !userId || !videoId) return;

    setCompleted(true);
    axios.post(`${API_BASE_URL}/api/videoprogress/complete`, {
      memberId: userId,
      lessonId: videoId,
      completed: true,
    }).then(() => {
      if (onComplete) onComplete(videoId);
    }).catch((error) => console.error("Error marking lesson as complete:", error));
  };

  return (
    <Box
      ref={containerRef} // Reference for fullscreen
      sx={{
        backgroundColor: "#f0f0f0",
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      {/* React Player */}
      <ReactPlayer
        ref={playerRef}
        url={blobUrl}
        controls
        width="100%"
        height="auto"
        playing={playing}
        onProgress={handleVideoProgress}
        onDuration={setDuration}
        progressInterval={1000}
        onEnded={handleMarkAsComplete}
        onSeek={handleSeek}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onReady={() => {
          if (progress > 0 && !playerRef.current?.hasSeeked) {
            playerRef.current?.seekTo(progress, "seconds");
            playerRef.current.hasSeeked = true;
          }
        }}
      />

      {/* ✅ Show Progress Only If Tracking is Enabled */}
      {trackProgress && (
        <Typography variant="body2">Progress: {Math.floor(progress)}s</Typography>
      )}
    </Box>
  );
};

export default VideoPlayer;