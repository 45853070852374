import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, CircularProgress, Box, Avatar, Tooltip, IconButton, Dialog,
    DialogTitle, DialogActions, DialogContent } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Editor } from '@tinymce/tinymce-react';

const AuthorDetailPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    
    const { id } = useParams(); 
    const [author, setAuthor] = useState({ name: '', expertise: '', description: '', pictureUrl: null });
    const [pictureUrl, setPictureUrl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const navigate = useNavigate();
    const editorRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/api/authors/author/${id}`);
                const authorData = response.data;
                setAuthor(authorData);
                setPictureUrl(authorData.pictureUrl);
            } catch (err) {
                setError('Error fetching author details.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAuthor((prevAuthor) => ({
            ...prevAuthor,
            [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPictureUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setSelectedFile(file);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/api/authors/author/${id}`);
            navigate('/authors'); 
        } catch (error) {
            console.error('Error deleting author:', error);
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setSaving(true);
    //     try {
    //         await axios.put(`${API_BASE_URL}/api/authors/author/${id}`, author);
    //         navigate(`/authors`); 
    //     } catch (err) {
    //         setError('Error updating author. Please refresh and try again.');
    //     } finally {
    //         setSaving(false);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
    
        const formData = new FormData();
        formData.append('name', author.name);
        formData.append('expertise', author.expertise);
        formData.append('description', editorRef.current.getContent());
    
        if (selectedFile) {
            formData.append('pictureUrl', selectedFile);
        }
        else if (pictureUrl) {
            formData.append('pictureUrl', pictureUrl);
        }
    
        try {
            await axios.put(`${API_BASE_URL}/api/authors/author/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            navigate('/authors');
        } catch (err) {
            console.error('Error updating author:', err.response?.data || err.message);
            setError('Error updating author. Please refresh and try again.');
        } finally {
            setSaving(false);
        }
    };
    

    if (loading) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><CircularProgress style={{ color: '#189AB4' }} /></Container>;
    }

    if (error) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><Typography color="error">{error}</Typography></Container>;
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Edit author details
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    onClick={() => setConfirmDelete(true)}
                    style={{ color: 'white'}}
                >
                    Delete Author
                </Button>

                <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this author?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete} color="secondary" variant="outlined" style={{color: '#189AB4'}}>
                            Delete
                        </Button>
                        <Button onClick={() => setConfirmDelete(false)} color="error" variant="outlined" style={{color: '#05445E'}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <form onSubmit={handleSubmit}>
                <Box 
                    sx={{
                        mt: 4,
                        p: 6,
                        boxShadow: 3,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 0.1 }}>
                        {/* Picture Section and Edit Icon */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '50px' }}>
                            {pictureUrl ? (
                                <Avatar
                                    src={pictureUrl}
                                    alt="Profile picture"
                                    sx={{ width: 150, height: 150 }}
                                    style={{ border: '2px solid #189AB4' }}
                                />
                            ) : (
                                <Avatar
                                    sx={{ width: 150, height: 150 }}
                                    alt="User icon"
                                    style={{ border: '2px solid #189AB4' }}
                                />
                            )}

                            <Tooltip title="Upload a new profile picture">
                                <Typography color="error" sx={{ fontSize: 16 }}>
                                    *
                                </Typography>
                                <IconButton component="label" style={{ color: '#189AB4' }} >
                                    <EditIcon sx={{ fontSize: 30 }} />
                                    <input
                                        key={pictureUrl}
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 0.9 }}>
                        <Box sx={{ display: 'flex', gap: 2, marginBottom: '15px' }}>
                            <TextField
                                label="Name"
                                name="name"
                                value={author.name}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                            <TextField
                                label="Expertise"
                                name="expertise"
                                value={author.expertise}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Box>
                        
                        <Typography variant="body1" >
                            Description <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            {/* <TextField
                                label="Description"
                                name="description"
                                value={author.description}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                multiline
                                rows={5}
                            /> */}
                            <Editor
                                apiKey='16xkbsggpf5mf009anlqhpejh1xhoh0hjv6itfy16u948tin'
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                initialValue={author.description}
                                init={{
                                    plugins: [
                                    // Core editing features
                                    'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                    // Your account includes a free trial of TinyMCE premium features
                                    // Try the most popular premium features until Feb 19, 2025:
                                    'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown','importword', 'exportword', 'exportpdf'
                                    ],
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                    tinycomments_mode: 'embedded',
                                    tinycomments_author: 'Author name',
                                    mergetags_list: [
                                    { value: 'First.Name', title: 'First Name' },
                                    { value: 'Email', title: 'Email' },
                                    ],
                                    ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                                }}
                            />
                        </Box>

                        {error && (
                            <Typography color="error" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}

                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 4 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                style={{ color: 'white'}}
                                startIcon={<SaveOutlinedIcon />}
                                disabled={saving}
                            >
                                {saving ? 'Saving...' : 'Save Changes'}
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                startIcon={<CancelIcon />}
                                onClick={() => navigate('/authors')}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </form>
        </Container>
    );
};

export default AuthorDetailPage;