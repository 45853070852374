import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Select, MenuItem, Button, Box, TextField} from '@mui/material';
import AuthService from '../../services/auth-service.js';

const AdminDashboard = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [memberships, setMemberships] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState("");
  const [generatedLink, setGeneratedLink] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchMemberships = async () => {
      const token = AuthService.getAuthToken();
      if (!token) {
        console.error("No token found. User might not be logged in.");
        return;
      }
    
      try {
          const response = await axios.get(`${API_BASE_URL}/api/memberships`,
          {
            headers: { Authorization: `Bearer ${token}`},
          });
          setMemberships(response.data);
      } catch (error) {
          console.error("Error purchasing membership:", error);
      }
    };

    fetchMemberships();
  }, []);

  const handleGenerateLink = async () => {
    const token = AuthService.getAuthToken();
    if (!token) {
      console.error("No token found. User might not be logged in.");
      return;
    }

    if (!selectedMembership) {
      alert("Please select a membership");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/purchase/generate-payment-link`,
        { membershipId: selectedMembership },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setGeneratedLink(response.data.link);
    } catch (error) {
      console.error("Error generating link:", error);
      alert("Failed to generate link.");
    }
  };

  const handleSendEmail = async () => {
    const token = AuthService.getAuthToken();
    if (!token) {
      console.error("No token found. User might not be logged in.");
      return;
    }

    if (!generatedLink || !email) {
      alert("Please generate a link and provide an email address.");
      return;
    }

    try {
      await axios.post(
        `${API_BASE_URL}/api/purchase/send-payment-link-email`,
        { email, link: generatedLink },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email.");
    }
  };

  return (
    <>
      <Box sx={{ maxWidth: 600, margin: "auto", textAlign: "center", padding: 4 }}>
        <Typography variant="h4" mb={2}>
          Generate Payment Link
        </Typography>
        <Select
          value={selectedMembership}
          onChange={(e) => setSelectedMembership(e.target.value)}
          displayEmpty
          fullWidth
          sx={{ mb: 2, height: "40px" }}
        >
          <MenuItem value="" disabled>
            Select Membership
          </MenuItem>
          {memberships.map((membership) => (
            <MenuItem key={membership.id} value={membership.id}>
              {membership.name} (€{membership.price})
            </MenuItem>
          ))}
        </Select>
        <Button variant="contained" onClick={handleGenerateLink} color="primary" sx={{ mb: 2, color: '#fff' }}>
          Generate Link
        </Button>
        {generatedLink && (
          <Box>
            <Typography variant="h6">Payment Link:</Typography>
            <TextField
              value={generatedLink}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              onClick={() => navigator.clipboard.writeText(generatedLink)}
              color="primary"
              sx={{ mt: 2, color: '#fff' }}
            >
              Copy to Clipboard
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={{ maxWidth: 600, margin: "auto", textAlign: "center", padding: 4 }}>
        {generatedLink && (
          <Box>
            <TextField
              label="Send Link to Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button variant="contained" onClick={handleSendEmail} color="primary" sx={{ color: '#fff'}}>
              Send Email
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AdminDashboard;