import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardMedia, CardContent, Stack, Pagination, useMediaQuery  } from "@mui/material";
import Slider from "react-slick";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";

import Footer from "../../components/Footer";

import blogBackground from "../../assets/images/blog/computer-background.png"; 
import blogMobileBackground from "../../assets/images/blog/computer-background-mobile.png";

import blogThumbnail1 from '../../assets/images/thumbnails/blogs/blog1.png';
import blogThumbnail2 from '../../assets/images/thumbnails/blogs/blog2.png';
import blogThumbnail3 from '../../assets/images/thumbnails/blogs/blog3.png';
import blogThumbnail4 from '../../assets/images/thumbnails/blogs/blog4.png';

const BlogsPage = () => {
    
    const CustomAuthorDots = ({ dots }) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            marginTop: "10px", // Add spacing from the slider
          }}
        >
          {dots.map((dot, index) => (
            <Box
              key={index}
              onClick={dot.props.onClick}
              sx={{
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                backgroundColor: dot.props.className.includes("slick-active")
                  ? "#189AB4" // Active dot color
                  : "#D9D9D9", // Inactive dot color
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            />
          ))}
        </Box>
    );
      
    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        arrows: false,
        appendDots: (dots) => <CustomAuthorDots dots={dots} />,
    };
      

    const blogPosts = [
        {
          id: 1,
          title: "Design That Pays: Adding Value Through Innovation",
          date: "2 NOV 2024",
          author: "Andreea Birsan",
          excerpt:
            "Small design changes can make a big impact on your property’s value. This blog explores the easiest and most effective updates to create a space that looks amazing and sells even better.",
          image: blogThumbnail1, 
        },
        {
          id: 2,
          title: "2025, the Year of Financial Mastery",
          date: "30 OCT 2024",
          author: "Marian Papară",
          excerpt:
            "Step into the future with confidence. Discover key strategies to master your finances, achieve your goals, and turn 2025 into your most successful year yet.",
          image: blogThumbnail2, 
        },
        {
          id: 3,
          title: "Boosting Financial Growth with Tech and Tokens",
          date: "22 OCT 2024",
          author: "Diana Chirtop",
          excerpt:
            "In today's fast-paced digital world, leveraging innovative technology can be key to achieving financial growth. Portable SSDs, like the one pictured above, symbolize the efficiency and security needed to store digital assets safely.",
          image: blogThumbnail3, 
        },
        {
          id: 4,
          title: "Adventures with a Purpose: Driving Towards Wealth",
          date: "16 OCT 2024",
          author: "Sabina Bodea",
          excerpt:
            "Embark on a journey where financial freedom meets the thrill of exploration. Learn how to align your financial goals with your passions, creating a roadmap for wealth and adventure that fuels both your wallet and your soul.",
          image: blogThumbnail4, 
        },
    ];

    const allBlogPosts = [
        {
          id: 1,
          title: "Design That Pays: Adding Value Through Innovation",
          date: "2 NOV 2024",
          author: "Andreea Birsan",
          excerpt:
            "Small design changes can make a big impact on your property’s value. This blog explores the easiest and most effective updates to create a space that looks amazing and sells even better.",
          image: blogThumbnail1, 
        },
        {
          id: 2,
          title: "2025, the Year of Financial Mastery",
          date: "30 OCT 2024",
          author: "Marian Papară",
          excerpt:
            "Step into the future with confidence. Discover key strategies to master your finances, achieve your goals, and turn 2025 into your most successful year yet.",
          image: blogThumbnail2, 
        },
        {
          id: 3,
          title: "Boosting Financial Growth with Tech and Tokens",
          date: "22 OCT 2024",
          author: "Diana Chirtop",
          excerpt:
            "In today's fast-paced digital world, leveraging innovative technology can be key to achieving financial growth. Portable SSDs, like the one pictured above, symbolize the efficiency and security needed to store digital assets safely.",
          image: blogThumbnail3, 
        },
        {
          id: 4,
          title: "Adventures with a Purpose: Driving Towards Wealth",
          date: "16 OCT 2024",
          author: "Sabina Bodea",
          excerpt:
            "Embark on a journey where financial freedom meets the thrill of exploration. Learn how to align your financial goals with your passions, creating a roadmap for wealth and adventure that fuels both your wallet and your soul.",
          image: blogThumbnail4, 
        },
        {
          id: 5,
          title: "Design That Pays: Adding Value Through Innovation",
          date: "2 NOV 2024",
          author: "Andreea Birsan",
          excerpt:
            "Small design changes can make a big impact on your property’s value. This blog explores the easiest and most effective updates to create a space that looks amazing and sells even better.",
          image: blogThumbnail1, 
        },
        {
          id: 6,
          title: "2025, the Year of Financial Mastery",
          date: "30 OCT 2024",
          author: "Marian Papară",
          excerpt:
            "Step into the future with confidence. Discover key strategies to master your finances, achieve your goals, and turn 2025 into your most successful year yet.",
          image: blogThumbnail2, 
        },
        {
          id: 7,
          title: "Boosting Financial Growth with Tech and Tokens",
          date: "22 OCT 2024",
          author: "Diana Chirtop",
          excerpt:
            "In today's fast-paced digital world, leveraging innovative technology can be key to achieving financial growth. Portable SSDs, like the one pictured above, symbolize the efficiency and security needed to store digital assets safely.",
          image: blogThumbnail3, 
        },
        {
          id: 8,
          title: "Adventures with a Purpose: Driving Towards Wealth",
          date: "16 OCT 2024",
          author: "Sabina Bodea",
          excerpt:
            "Embark on a journey where financial freedom meets the thrill of exploration. Learn how to align your financial goals with your passions, creating a roadmap for wealth and adventure that fuels both your wallet and your soul.",
          image: blogThumbnail4, 
        },
        {
          id: 9,
          title: "Design That Pays: Adding Value Through Innovation",
          date: "2 NOV 2024",
          author: "Andreea Birsan",
          excerpt:
            "Small design changes can make a big impact on your property’s value. This blog explores the easiest and most effective updates to create a space that looks amazing and sells even better.",
          image: blogThumbnail1, 
        },
        {
          id: 10,
          title: "2025, the Year of Financial Mastery",
          date: "30 OCT 2024",
          author: "Marian Papară",
          excerpt:
            "Step into the future with confidence. Discover key strategies to master your finances, achieve your goals, and turn 2025 into your most successful year yet.",
          image: blogThumbnail2, 
        },
        {
          id: 11,
          title: "Boosting Financial Growth with Tech and Tokens",
          date: "22 OCT 2024",
          author: "Diana Chirtop",
          excerpt:
            "In today's fast-paced digital world, leveraging innovative technology can be key to achieving financial growth. Portable SSDs, like the one pictured above, symbolize the efficiency and security needed to store digital assets safely.",
          image: blogThumbnail3, 
        },
        {
          id: 12,
          title: "Adventures with a Purpose: Driving Towards Wealth",
          date: "16 OCT 2024",
          author: "Sabina Bodea",
          excerpt:
            "Embark on a journey where financial freedom meets the thrill of exploration. Learn how to align your financial goals with your passions, creating a roadmap for wealth and adventure that fuels both your wallet and your soul.",
          image: blogThumbnail4, 
        },
        {
          id: 13,
          title: "Design That Pays: Adding Value Through Innovation",
          date: "2 NOV 2024",
          author: "Andreea Birsan",
          excerpt:
            "Small design changes can make a big impact on your property’s value. This blog explores the easiest and most effective updates to create a space that looks amazing and sells even better.",
          image: blogThumbnail1, 
        },
        {
          id: 14,
          title: "2025, the Year of Financial Mastery",
          date: "30 OCT 2024",
          author: "Marian Papară",
          excerpt:
            "Step into the future with confidence. Discover key strategies to master your finances, achieve your goals, and turn 2025 into your most successful year yet.",
          image: blogThumbnail2, 
        },
        {
          id: 15,
          title: "Boosting Financial Growth with Tech and Tokens",
          date: "22 OCT 2024",
          author: "Diana Chirtop",
          excerpt:
            "In today's fast-paced digital world, leveraging innovative technology can be key to achieving financial growth. Portable SSDs, like the one pictured above, symbolize the efficiency and security needed to store digital assets safely.",
          image: blogThumbnail3, 
        },
        {
          id: 16,
          title: "Adventures with a Purpose: Driving Towards Wealth",
          date: "16 OCT 2024",
          author: "Sabina Bodea",
          excerpt:
            "Embark on a journey where financial freedom meets the thrill of exploration. Learn how to align your financial goals with your passions, creating a roadmap for wealth and adventure that fuels both your wallet and your soul.",
          image: blogThumbnail4, 
        },{
          id: 17,
          title: "Design That Pays: Adding Value Through Innovation",
          date: "2 NOV 2024",
          author: "Andreea Birsan",
          excerpt:
            "Small design changes can make a big impact on your property’s value. This blog explores the easiest and most effective updates to create a space that looks amazing and sells even better.",
          image: blogThumbnail1, 
        },
        {
          id: 18,
          title: "2025, the Year of Financial Mastery",
          date: "30 OCT 2024",
          author: "Marian Papară",
          excerpt:
            "Step into the future with confidence. Discover key strategies to master your finances, achieve your goals, and turn 2025 into your most successful year yet.",
          image: blogThumbnail2, 
        },
        {
          id: 19,
          title: "Boosting Financial Growth with Tech and Tokens",
          date: "22 OCT 2024",
          author: "Diana Chirtop",
          excerpt:
            "In today's fast-paced digital world, leveraging innovative technology can be key to achieving financial growth. Portable SSDs, like the one pictured above, symbolize the efficiency and security needed to store digital assets safely.",
          image: blogThumbnail3, 
        },
        {
          id: 20,
          title: "Adventures with a Purpose: Driving Towards Wealth",
          date: "16 OCT 2024",
          author: "Sabina Bodea",
          excerpt:
            "Embark on a journey where financial freedom meets the thrill of exploration. Learn how to align your financial goals with your passions, creating a roadmap for wealth and adventure that fuels both your wallet and your soul.",
          image: blogThumbnail4, 
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(16);

    const isMobile = useMediaQuery("(max-width:768px)");

    // Calculate the total number of pages
    const totalPages = Math.ceil(allBlogPosts.length / postsPerPage);
    useEffect(() => {
        if (isMobile) {
          setPostsPerPage(10); // Display 10 posts for mobile
        } else {
          setPostsPerPage(16); // Display 16 posts for larger screens
        }
      }, [isMobile]);

    // Get the posts for the current page
    const currentPosts = allBlogPosts.slice(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage
    );

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        if (isMobile) {
          setPostsPerPage(10); // Display 10 posts for mobile
        } else {
          setPostsPerPage(16); // Display 16 posts for larger screens
        }
      }, [isMobile]);

return (
    <Box>
        <Box
            sx={{
                position: "relative",
                backgroundImage: { xs: `url(${blogMobileBackground})`, xxl: `url(${blogBackground})` }, 
                backgroundSize: { xs: "contain", xxl: "cover" },
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                color: "#fff",
                textAlign: "left", 
                py: { xs: 0, xxl: 14 },
                height: { xs: "263px", xxl: "315px" }
            }}
        >
            {/* Content */}
            <Box
                sx={{
                position: "relative",
                zIndex: 2, 
                px: { xs: 3, xxl: 2 },
                maxWidth: "60%",
                //mx: "auto", 
                mx: { xs: 0, xxl: "auto" },
                pt: { xs: "60px", xxl: "0" },
                }}
            >
                <Typography
                variant="h2"
                sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    mb: 2,
                }}
                >
                Blog
                </Typography>
                <Typography
                variant="subtitle2"
                sx={{
                    lineHeight: 1.8,
                    width: { xs: "380px", xxl: "419px" }
                }}
                >
                Wise words, tips, tricks and useful info from field experts and world-class
                trainers.
                </Typography>
            </Box>
        </Box>


        <Box
            sx={{
                py: { xs: 4, xxl: 6 },
                px: { xs: 2, xxl: 6 },
                textAlign: "center",
                backgroundColor: "#fff",
                display: { xs: "none", xxl: "block" }
            }}
            >
            {/* Section Title */}
            <Typography
                variant="h4"
                sx={{
                mb: { xs: 4, xxl: 4 },
                fontWeight: "bold",
                color: "#189AB4",
                }}
            >
                Latest Blog Posts
            </Typography>

            {/* Blog Cards */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row", 
                    gap: 5,
                    justifyContent: "center",
                    alignItems: "stretch",
                }}
            >
                {blogPosts.slice(0, 3).map((post, index) => (
                <Card
                    key={index}
                    sx={{
                    flex: index === 0 ? "0 0 520px" : "0 0 260px", // Width for the first and other cards
                    borderRadius: "16px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    border: "1px solid #189AB4",
                    display: "flex",
                    flexDirection: "column",
                    }}
                >
                    {/* Blog Image */}
                    <CardMedia
                        component="img"
                        height="139"
                        image={post.image}
                        alt={post.title}
                        sx={{
                            objectFit: "cover",
                            margin: "0",
                            borderRadius: "0"
                        }}
                    />
                    {/* Blog Content */}
                    <CardContent sx={{ textAlign: "left", p: 2, flex: 1 }}>
                    {/* Blog Metadata */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: 1 }}
                    >
                        {/* Date */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <CalendarTodayIcon sx={{ fontSize: "12px", color: "#B7B7B7" }} />
                        <Typography variant="body2" sx={{ color: "#B7B7B7" }}>
                            {post.date}
                        </Typography>
                        </Box>
                        {/* Author */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <PersonIcon sx={{ fontSize: "12px", color: "#B7B7B7" }} />
                        <Typography variant="body2" sx={{ color: "#B7B7B7" }}>
                            {post.author}
                        </Typography>
                        </Box>
                    </Stack>

                    {/* Blog Title */}
                    <Typography
                        variant="h6"
                        sx={{
                        color: "#189AB4",
                        fontWeight: "bold",
                        textTransform: "none",
                        mb: 1,
                        }}
                    >
                        {post.title}
                    </Typography>

                    {/* Blog Excerpt */}
                    <Typography
                        variant="body2"
                        sx={{
                        color: "#000",
                        fontWeight: "300",
                        lineHeight: 1.5,
                        }}
                    >
                        {post.excerpt}
                    </Typography>
                    </CardContent>
                </Card>
                ))}
            </Box>
        </Box>


        {/* Blogs Slider */}
        <Box
            sx={{
                py: { xs: 3, xxl: 6 },
                px: { xs: "20px", xxl: "0" },
                textAlign: "center",
                width: "100%",
                backgroundColor: "#fff",
                display: { xs: "block", xxl: "none" },
            }}
        >
            <Box
                sx={{
                    width: { xs: "100%", md: "60%" },
                    margin: "0 auto",
                    overflow: "hidden",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        mb: { xs: 2, xxl: 4 },
                        fontWeight: "bold",
                        color: "#189AB4",
                    }}
                >
                    Latest Blog Posts
                </Typography>

                <Slider {...carouselSettings}>
                {blogPosts.map((post) => (
                    <Box
                    key={post.id}
                    sx={{
                        borderRadius: "16px",
                        overflow: "hidden",
                        margin: "0 auto",
                    }}
                    >
                    {/* Blog Card */}
                    <Card
                        sx={{
                            borderRadius: "16px",
                            overflow: "hidden",
                            border: "1px solid #189AB4",
                            width: "242px", 
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            height: "420px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between", 
                        }}
                    >
                        {/* Blog Image */}
                        <CardMedia
                            component="img"
                            image={post.image}
                            alt={post.title}
                            sx={{
                                borderRadius: "0px",
                                margin: 0,
                                height: "139px",
                                objectFit: "cover", 
                            }}
                        />
                        {/* Blog Content */}
                        <CardContent
                            sx={{
                                textAlign: "left",
                                p: 2,
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between", 
                            }}
                        >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ mb: 1 }}
                        >
                            {/* Date */}
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <CalendarTodayIcon sx={{ fontSize: "12px", color: "#B7B7B7" }} />
                                <Typography
                                    variant="body3"
                                    sx={{ color: "#B7B7B7" }}
                                >
                                    {post.date}
                                </Typography>
                            </Box>
                            {/* Author */}
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <PersonIcon sx={{ color: "#B7B7B7" }} />
                                <Typography
                                    variant="body3"
                                    sx={{ fontSize: "12px", color: "#B7B7B7" }}
                                >
                                    {post.author}
                                </Typography>
                            </Box>
                        </Stack>
                        {/* Blog Title */}
                        <Typography
                            variant="h5"
                            sx={{
                            mt: 1,
                            color: "#189AB4",
                            textTransform: "none",
                            fontWeight: "bold",
                            letterSpacing: "normal",
                            }}
                        >
                            {post.title}
                        </Typography>
                        {/* Blog Excerpt */}
                        <Typography
                            variant="body2"
                            sx={{
                            mt: 1,
                            color: "#000",
                            textTransform: "none",
                            fontWeight: "300",
                            letterSpacing: "normal",
                            }}
                        >
                            {post.excerpt}
                        </Typography>
                        </CardContent>
                    </Card>
                    </Box>
                ))}
                </Slider>
            </Box>
        </Box>


        { /* All blog posts */ }
        <Box
            sx={{
                py: { xs: 4, xxl: 6 },
                px: { xs: 2, xxl: 0 },
                textAlign: "center",
                backgroundColor: "#fff",
            }}
        >
            {/* Title */}
            <Typography
                variant="h4"
                sx={{
                    mb: { xs: 1, xxl: 2 },
                    fontWeight: "bold",
                    color: "#189AB4",
                }}
            >
                All Blog Posts
            </Typography>

            {/* Blog Posts Grid */}
            <Box
                sx={{
                    width: { xs: "100%", xxl: "60%" },
                    margin: "0 auto",
                    display: "grid",
                    gridTemplateColumns: { xs: "repeat(2, 1fr)", xxl: "repeat(4, 4fr)" },
                    justifyItems: "center", 
                }}
            >
                {currentPosts.map((post, index) => (
                <Card
                    key={index}
                    sx={{
                        width: "100%",
                        maxWidth: { xs: "190px", xxl: "260px" }, 
                        margin: "15px 5px",
                        borderRadius: "16px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        overflow: "hidden",
                        border: "1px solid #189AB4",
                    }}
                >
                    {/* Blog Image */}
                    <CardMedia
                        component="img"
                        height="139"
                        image={post.image}
                        alt={post.title}
                        sx={{
                            objectFit: "cover",
                            margin: "0",
                            borderRadius: "0"
                        }}
                    />
                    {/* Blog Content */}
                    <CardContent sx={{ textAlign: "left", p: 2 }}>
                        {/* Blog Title */}
                        <Typography
                            variant="h6"
                            sx={{
                                color: "#189AB4",
                                fontWeight: "bold",
                                textTransform: "none",
                                mb: 1,
                            }}
                        >
                            {post.title}
                        </Typography>
                        {/* Metadata */}
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ mb: 1 }}
                        >
                            {/* Date */}
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <CalendarTodayIcon sx={{ fontSize: "12px", color: "#B7B7B7" }} />
                            <Typography variant="body2" sx={{ color: "#B7B7B7" }}>
                                {post.date}
                            </Typography>
                            </Box>
                            {/* Author */}
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <PersonIcon sx={{ color: "#B7B7B7" }} />
                            <Typography variant="body2" sx={{ color: "#B7B7B7" }}>
                                {post.author}
                            </Typography>
                            </Box>
                        </Stack>

                        {/* Blog Excerpt */}
                        <Typography
                            variant="body2"
                            sx={{
                                color: "#000",
                                fontWeight: "300",
                                lineHeight: 1.5,
                            }}
                        >
                            {post.excerpt}
                        </Typography>
                    </CardContent>
                </Card>
                ))}
            </Box>

            {/* Pagination */}
            <Box
                sx={{
                mt: 4,
                display: "flex",
                justifyContent: "center",
                }}
            >
                <Pagination
                    count={totalPages} // Total pages
                    page={currentPage} // Current page
                    onChange={handlePageChange} // Page change handler
                    siblingCount={1} // Only show one sibling on each side
                    boundaryCount={0} // No boundary dots
                    hidePrevButton // Remove the left arrow
                    hideNextButton // Remove the right arrow
                    sx={{
                        "& .MuiPaginationItem-root": {
                        color: "#9F9F9F",
                        fontWeight: "bold",
                        },
                        "& .Mui-selected": {
                        backgroundColor: "#fff",
                        color: "#189AB4",
                        },
                    }}
                />
            </Box>
        </Box>
















        <Footer/>
    </Box>
  );
};

export default BlogsPage;