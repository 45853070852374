import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Snackbar, Alert, Box, TextField, Button, InputLabel, FormControl, 
    Select, MenuItem,  Dialog, DialogActions,  DialogContent, DialogTitle, FormGroup, FormControlLabel, Checkbox, 
    Tooltip, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
//import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import VideoPlayer from "../../../components/VideoPlayer";
import { Editor } from '@tinymce/tinymce-react';

import { BlockBlobClient } from "@azure/storage-blob";

const CourseDetailPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const { id } = useParams(); // Get the course ID from the URL
    const [course, setCourse] = useState({ memberships: [] });
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [availableMemberships, setAvailableMemberships] = useState([]);
    const [availableAuthors, setAvailableAuthors] = useState([]);
    const [videoUrl, setVideoUrl] = useState(null);
    const [fetchCourseRef, setFetchCourseRef] = useState(null);
    const [previewVideoName, setPreviewVideoName] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [thumbnailFile, setThumbnailFile] = useState(null); // Selected thumbnail file
    const [previewVideoFile, setPreviewVideoFile] = useState(null); // Selected video file
    const [uploadProgress, setUploadProgress] = useState(0); // Progress for file uploads
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [progressModalOpen, setProgressModalOpen] = useState(false); // Modal for showing progress

    const navigate = useNavigate();
    const editorRef = useRef(null);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/courses/course/${id}`);
                setCourse(response.data);
            } catch (err) {
                console.error('Error fetching course:', err);
                setError('Failed to load course details. Please refresh the page and try again.');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchMemberships = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/memberships`);
                setAvailableMemberships(response.data);
            } catch (err) {
                console.error('Error fetching memberships:', err);
                setError('Failed to load memberships.');
                setSnackbarOpen(true);
            }
        };
        const fetchAuthors = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/authors`);
                setAvailableAuthors(response.data);
            } catch (err) {
                console.error('Error fetching authors:', err);
                setError('Failed to load authors.');
                setSnackbarOpen(true);
            }
        };
        setFetchCourseRef(() => fetchCourse);
        fetchCourse();
        fetchMemberships();
        fetchAuthors();
    }, [id]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setCourse({ ...course, [name]: value });
    // };

    const handleInputChange = (value, fieldName) => {
        setCourse((prev) => ({ ...prev, [fieldName]: value || "" }));
    };
    

    // const handleInputChange = (e, fieldName) => {
    //     if (typeof e === 'string') {
    //         // ReactQuill provides the updated value directly
    //         setCourse((prev) => ({ ...prev, [fieldName]: e }));
    //     } else {
    //         // Handle regular inputs (events)
    //         const { name, value } = e.target;
    //         setCourse((prev) => ({ ...prev, [name]: value }));
    //     }
    // };
    

    const uploadFile = async (file, type) => {
        setUploadInProgress(true);
        
        const response = await fetch(`${API_BASE_URL}/api/upload/generate-sas-token?fileName=${file.name}`);
        const { sasUrl } = await response.json();
        const options = {
            method: "PUT",
            headers: {
                "x-ms-blob-type": "BlockBlob",
                "Content-Type": file.type,
            },
            body: file,
        };
    
        // await fetch(sasUrl, options);
        // return sasUrl.split("?")[0];

        const blobClient = new BlockBlobClient(sasUrl);

        try {
            await blobClient.uploadBrowserData(file, {
                blobHTTPHeaders: { blobContentType: file.type },
                onProgress: (progress) => {
                    const percentage = Math.round((progress.loadedBytes / file.size) * 100);
                    setUploadProgress(percentage); // Update progress
                },
            });
    
            return sasUrl.split("?")[0]; // Return uploaded URL
        } catch (error) {
            console.error(`Error uploading ${type}:`, error);
            throw new Error(`Failed to upload ${type}`);
        }
        finally {
            setUploadInProgress(false); // Always close the progress modal
        }
    };
    
    const handleFileChange = async (event, type) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const uploadedUrl = await uploadFile(file, type);
        if (type === "thumbnail") {
            setThumbnailUrl(uploadedUrl);
            setCourse((prev) => ({ ...prev, thumbnailUrl: uploadedUrl }));
        } else if (type === "previewVideo") {
            setVideoUrl(uploadedUrl);
            setCourse((prev) => ({ ...prev, previewVideoUrl: uploadedUrl }));
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;
    
        const previousLessons = [...course.lessons]; // Save the current state
    
        const reorderedLessons = [...course.lessons];
        const [movedLesson] = reorderedLessons.splice(result.source.index, 1);
        reorderedLessons.splice(result.destination.index, 0, movedLesson);
    
        const updatedLessons = reorderedLessons.map((lesson, index) => ({
            ...lesson,
            order: index,
        }));
    
        setCourse({ ...course, lessons: updatedLessons });
    
        try {
            await axios.put(
                `${API_BASE_URL}/api/courses/course/${id}/lessons/reorder`,
                updatedLessons.map((lesson) => ({ id: lesson.id, order: lesson.order })),
                { headers: { 'Content-Type': 'application/json' } }
            );
            console.log("Lesson order updated successfully");
        } catch (error) {
            console.error("Error saving lesson order:", error);
            // Rollback to the previous state
            setCourse({ ...course, lessons: previousLessons });
        }
    };  
    
    const handleSave = async () => {
        let thumbnailUrl = course.thumbnailUrl; // Keep existing URL if no new file
        let previewVideoUrl = course.previewVideoUrl; // Keep existing URL if no new file

        // Upload the thumbnail file if a new file is selected
        if (thumbnailFile) {
            thumbnailUrl = await uploadFile(thumbnailFile, "thumbnail");
        }

        // Upload the preview video file if a new file is selected
        if (previewVideoFile) {
            previewVideoUrl = await uploadFile(previewVideoFile, "previewVideo");
        }

        const updatedCourse = new FormData();
    
        updatedCourse.append('title', course.title);
        
        updatedCourse.append('description', editorRef.current.getContent());
        updatedCourse.append('category', course.category);
        
        if (course.price) {
            const parsedPrice = parseFloat(course.price);
            if (!isNaN(parsedPrice)) {
                updatedCourse.append('price', parsedPrice); // Append parsed price only if it's valid
            } else {
                setError('Please provide a valid price.');
                return; // Don't proceed if price is invalid
            }
        }

        updatedCourse.append('isTrending', course.isTrending);
        
        // Append file URLs or file data
        if (thumbnailUrl) {
            updatedCourse.append("thumbnailUrl", thumbnailUrl);
        }

        if (previewVideoUrl) {
            updatedCourse.append("previewVideoUrl", previewVideoUrl);
        }
    
        // MembershipIds (including empty array)
        updatedCourse.append('membershipIds', JSON.stringify(course.memberships.map((m) => m.id)));

        // AuthorIds (including empty array)
        updatedCourse.append('authorIds', JSON.stringify(course.authors.map((a) => a.id)));
    
        try {
            // Send the PUT request with the form data
            const response = await axios.put(`${API_BASE_URL}/api/courses/course/${id}`, updatedCourse, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            const refreshedCourse = await axios.get(`${API_BASE_URL}/api/courses/course/${id}`);
            setCourse({ ...refreshedCourse.data, lessons: course.lessons });
    
            if (response.status === 200 || response.status === 204) {
                navigate('/courses'); // Navigate on success
            } else {
                throw new Error('Unexpected response from server.');
            }
        } catch (err) {
            console.error('Error saving course:', err.response?.data || err.message);
            setError('Failed to save course. Please try again.');
            setSnackbarOpen(true);
        }
    };

    
    const handleDelete = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/api/courses/course/${id}`);
            navigate('/courses'); 
        } catch (error) {
            console.error('Error deleting course:', error);
        }
    };
    
    const handleCancel = () => {
        navigate('/courses');
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Edit course details
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    onClick={() => setConfirmDelete(true)}
                    style={{ color: 'white'}}
                >
                    Delete Course
                </Button>

                <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this course?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete} color="secondary" variant="outlined" style={{color: '#189AB4'}}>
                            Delete
                        </Button>
                        <Button onClick={() => setConfirmDelete(false)} color="error" variant="outlined" style={{color: '#05445E'}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            

            <Box
                sx={{
                    mt: 4,
                    p: 6,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                {loading ? (
                    <CircularProgress style={{ color: '#189AB4' }} />
                ) : (
                    <>
                        {course && (
                            <>
                                <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
                                    <TextField
                                        name="title"
                                        label="Title"
                                        value={course.title || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                        sx={{ marginBottom: '20px' }}
                                    />
                                </Box>

                                {/* <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
                                    <TextField
                                        name="description"
                                        label="Description"
                                        value={course.description || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                        multiline
                                        maxRows={5}
                                        minRows={5}
                                        sx={{ marginBottom: '20px' }}
                                    />
                                </Box> */}

                                {/* WYSIWYG Editor for Description */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '70px' }}>
                                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                        Description <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <Editor
                                        apiKey='16xkbsggpf5mf009anlqhpejh1xhoh0hjv6itfy16u948tin'
                                        onInit={(evt, editor) => (editorRef.current = editor)}
                                        initialValue={course.description}
                                        init={{
                                            plugins: [
                                            // Core editing features
                                            'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                            // Your account includes a free trial of TinyMCE premium features
                                            // Try the most popular premium features until Feb 19, 2025:
                                            'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown','importword', 'exportword', 'exportpdf'
                                            ],
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                            tinycomments_mode: 'embedded',
                                            tinycomments_author: 'Author name',
                                            mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                            ],
                                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                                        }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
                                    {/* Category Dropdown */}
                                    <FormControl fullWidth>
                                        <InputLabel id="category-label" required>Category</InputLabel>
                                        <Select
                                            name="category"
                                            value={course.category || ''}
                                            label="Category"
                                            onChange={handleInputChange}
                                            fullWidth
                                            style={{ height: '45px' }}
                                        >
                                            <MenuItem value="">
                                                <em>Please select a category</em>
                                            </MenuItem>
                                            <MenuItem value="money">Money</MenuItem>
                                            <MenuItem value="investments">Investments</MenuItem>
                                            <MenuItem value="coming-soon">Coming soon</MenuItem>
                                        </Select>
                                    </FormControl>
                                    
                                    <TextField
                                        name="price"
                                        label="Price"
                                        type="number"
                                        value={course.price || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        sx={{ marginBottom: '20px' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginBottom: '30px' }}>
                                    {/* isTrending Checkbox */}
                                    <FormGroup>
                                        <Typography 
                                            variant="h6" 
                                            sx={{ marginBottom: 2, color: 'primary.main' }}
                                        >
                                            Do you want this course to be in the Trending list?
                                        </Typography>
                                        <FormControlLabel
                                            key={course.isTrending}
                                            control={
                                                <Checkbox
                                                    checked={course.isTrending}
                                                    onChange={(e) => setCourse({ ...course, isTrending: e.target.checked })}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                    
                                    {/* Membership Selection */}
                                    <FormGroup>
                                        <Typography 
                                            variant="h6" 
                                            sx={{ marginBottom: 2, color: 'primary.main' }}
                                        >
                                            Memberships
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                            {availableMemberships.map((membership) => (
                                                <FormControlLabel
                                                    key={membership.id}
                                                    control={
                                                        <Checkbox
                                                            checked={course.memberships.some((m) => m.id === membership.id)}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                setCourse((prevCourse) => {
                                                                    const updatedMemberships = checked
                                                                        ? [...prevCourse.memberships, membership]  // Add if checked
                                                                        : prevCourse.memberships.filter((m) => m.id !== membership.id);  // Remove if unchecked
                                                                    return { ...prevCourse, memberships: updatedMemberships };
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label={membership.name}
                                                />
                                            ))}
                                        </Box>
                                    </FormGroup>

                                    {/* Author Selection */}
                                    <FormGroup>
                                        <Typography 
                                            variant="h6" 
                                            sx={{ marginBottom: 2, color: 'primary.main' }}
                                        >
                                                Authors
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                            {availableAuthors.map((author) => (
                                                <FormControlLabel
                                                    key={author.id}
                                                    control={
                                                        <Checkbox
                                                            checked={course.authors?.some((a) => a.id === author.id)}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                setCourse((prevCourse) => {
                                                                    const updatedAuthors = checked
                                                                        ? [...prevCourse.authors, author]  // Add if checked
                                                                        : prevCourse.authors.filter((a) => a.id !== author.id);  // Remove if unchecked
                                                                    return { ...prevCourse, authors: updatedAuthors };
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label={author.name}
                                                />
                                            ))}
                                        </Box>
                                    </FormGroup>
                                </Box>
                                <Typography 
                                    variant="h6" 
                                    sx={{ marginBottom: 2, color: 'primary.main' }}
                                >
                                        Thumbnail <span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '50px' }}>
                                    {thumbnailUrl ? (
                                        <>
                                        <InputLabel id="thumbnail-label" required>Select a thumbnail</InputLabel>
                                        <Box
                                            component="img"
                                            src={thumbnailUrl}
                                            alt={course.title}
                                            sx={{
                                                width: "50%",
                                                height: "auto",
                                                borderRadius: 2,
                                            }}
                                            style={{ border: '2px solid #189AB4' }}
                                        />
                                        </>
                                    ) : (
                                        <Box
                                            component="img"
                                            src={course.thumbnailUrl}
                                            alt={course.title}
                                            sx={{
                                                width: "50%",
                                                height: "auto",
                                                borderRadius: 2,
                                            }}
                                            style={{ border: '2px solid #189AB4' }}
                                        />
                                    )}

                                    <Tooltip title="Upload a thumbnail">
                                        <IconButton component="label" style={{ color: '#189AB4' }}>
                                            <EditIcon sx={{ fontSize: 30 }} />
                                            <input
                                                type="file"
                                                hidden
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(e, "thumbnail")}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                {/* Preview Video Upload */}
                                <Typography 
                                    variant="h6" 
                                    sx={{ marginBottom: 2, color: 'primary.main' }}
                                >
                                    Preview Video
                                </Typography>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginBottom: "30px" }}>
                                    {/* If there's a video URL, display the video and an EditIcon */}
                                    {course.previewVideoUrl ? (
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                            <VideoPlayer blobUrl={course.previewVideoUrl} />
                                            {/* <VideoPlayer blobUrl="https://www.w3schools.com/html/mov_bbb.mp4" /> */}

                                            <Tooltip title="Edit or Replace Video">
                                                <IconButton component="label" style={{ color: '#189AB4' }}>
                                                    <EditIcon sx={{ fontSize: 30 }} />
                                                    <input
                                                        type="file"
                                                        hidden
                                                        accept="video/*"
                                                        onChange={(e) => handleFileChange(e, "previewVideo")}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    ) : (
                                        // Upload video button if no video exists
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={(e) => handleFileChange(e, "previewVideo")}
                                                style={{ display: "none" }}
                                                id="preview-video-file"
                                            />
                                            <label htmlFor="preview-video-file">
                                                <Button variant="contained" color="primary" component="span" startIcon={<AddCircleOutlineOutlinedIcon/>} style={{ color: 'white' }}>
                                                    {previewVideoName ? "Change Video" : "Upload Video"}
                                                </Button>
                                            </label>
                                        </Box>
                                    )}

                                    {/* Display the selected video name if uploading */}
                                    {previewVideoName && (
                                        <Typography sx={{ marginLeft: 2, color: 'text.secondary' }}>
                                            Selected Video: {previewVideoName}
                                        </Typography>
                                    )}
                                </Box>
                                
                                <Box sx={{ marginBottom: '30px' }}>
                                    {course.lessons && course.lessons.length > 0 && ( 
                                        <Typography 
                                        variant="h6" 
                                        sx={{ marginBottom: 2, color: 'primary.main' }}
                                    >
                                        Lessons
                                    </Typography>
                                    )}

                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="lessons">
                                            {(provided) => (
                                                <List
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        background: "#f8f9fa",
                                                        padding: "16px",
                                                        borderRadius: "8px",
                                                    }}
                                                >
                                                    {course.lessons
                                                        .sort((a, b) => a.order - b.order)
                                                        .map((lesson, index) => (
                                                            <Draggable
                                                                key={lesson.id}
                                                                draggableId={lesson.id}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <ListItem
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...provided.draggableProps.style,
                                                                            marginBottom: "8px",
                                                                            background: "#ffffff",
                                                                            padding: "16px",
                                                                            borderRadius: "4px",
                                                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                                        }}
                                                                    >
                                                                        <ListItemText
                                                                            primary={`${index + 1}. ${lesson.title}`}
                                                                        />
                                                                    </ListItem>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    {provided.placeholder}
                                                </List>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Box>

                                {uploadInProgress && (
                                    <Dialog open={uploadInProgress}>
                                        <DialogTitle>Uploading Files</DialogTitle>
                                        <DialogContent>
                                            <Typography variant="body1" align="center" gutterBottom>
                                                Uploading... {uploadProgress}%
                                            </Typography>
                                            <Box sx={{ width: "100%" }}>
                                                <CircularProgress variant="determinate" value={uploadProgress} />
                                            </Box>
                                        </DialogContent>
                                    </Dialog>
                                )}


                            </>

                        )}
                    </>
                )}

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        style={{ color: 'white' }}
                        fullWidth
                    >
                        Save
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<CancelIcon />}
                        onClick={handleCancel}
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default CourseDetailPage;