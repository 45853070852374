import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, CircularProgress, Box, Link, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const MembersPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [searchTerm, setSearchTerm] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/users/members`); 
                setUsers(response.data);
            } catch (error) {
                console.error('Failed to fetch members', error);
            } finally {
                setLoading(false); // Stop loading once the data is fetched
            }
        };

        fetchMembers();
    }, []);

    // Define columns for the DataGrid
    const columns = [
        
        { field: 'firstName', headerName: 'First name', width: 150 },
        { field: 'lastName', headerName: 'Last name', width: 150 },
        { field: 'userName', headerName: 'Username', width: 150 },
        { field: 'membership', headerName: 'Membership', width: 120 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phoneNumber', headerName: 'Phone number', width: 150 },
        { field: 'address', headerName: 'Address', width: 200 },
        { field: 'city', headerName: 'City', width: 150 },
        { field: 'county', headerName: 'State', width: 150 },
        { field: 'country', headerName: 'Country', width: 150 },
        { field: 'zipCode', headerName: 'Postal code', width: 150 },
    ];

    // Filtered users based on the search term
    const filteredUsers = users.filter(user => {
        const [firstNameSearch, lastNameSearch] = searchTerm.toLowerCase().split(" ");
    
        return (
            // Match both first and last names when search term includes both
            (firstNameSearch && lastNameSearch
                ? user.firstName.toLowerCase().includes(firstNameSearch) &&
                  user.lastName.toLowerCase().includes(lastNameSearch)
                : // Match individually if only first or last name is provided
                (user.firstName && user.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (user.lastName && user.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
            ) ||
            (user.userName && user.userName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.city && user.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.county && user.county.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.country && user.country.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });
    

    const handleRowClick = (user) => {
        const userName = user.row.userName;
        navigate(`/member/${userName}`);  // Navigate to the user details page
    };


    return (
        <Container maxWidth='XXL' sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Members
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '35%' }}>
                    {/* Search input */}
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}  // Update search term
                    />

                    <Button variant="contained" color="secondary" 
                            style={{ color: 'white', marginLeft: '20px' }} 
                            onClick={() => navigate('/member/register')}>
                        <AddCircleOutlineOutlinedIcon sx={{ marginRight: '5px' }}/>
                        Create
                    </Button>
                </Box>
            </Box>
            <Box my={4}>
                {loading ? (
                    <CircularProgress style={{ color: '#189AB4'}} />
                ) : (
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={filteredUsers}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowClick={handleRowClick}
                        />
                    </div>
                )}
            </Box>
        </Container>
    );
};

export default MembersPage;
