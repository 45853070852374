import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, TextField, Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

const LessonsPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchLessons = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/lessons`);
                
                const lessonsWithCourseTitles = response.data.map((lesson) => ({
                    ...lesson,
                    courseTitle: lesson.course ? lesson.course.title : 'No Course', 
                }));
                setLessons(lessonsWithCourseTitles);
            } catch (err) {
                setError('Error fetching lessons. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchLessons();
    }, []);

    const columns = [
        { field: 'title', headerName: 'Title', width: 300 },
        { field: 'courseTitle', headerName: 'Course', width: 400 },
        // { field: 'description', headerName: 'Description', width: 600 },
        {
            field: 'description',
            headerName: 'Description',
            width: 600,
            renderCell: (params) => (
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(params.value || ''),
                    }}
                />
            ),
        },
    ];

    const filteredLessons = lessons.filter(lesson => 
        (lesson.title && lesson.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (lesson.description && lesson.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (lesson.courseTitle && lesson.courseTitle.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleRowClick = (lesson) => {
        const lessonId = lesson.row.id;
        navigate(`/lesson/${lessonId}`); 
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Lessons
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '35%' }}>
                    {/* Search input */}
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}  // Update search term
                    />

                    <Button variant="contained" color="secondary" 
                            style={{ color: 'white', marginLeft: '20px' }} 
                            onClick={() => navigate('/lesson/register')}>
                        <AddCircleOutlineOutlinedIcon sx={{ marginRight: '5px' }}/>
                        Create
                    </Button>
                </Box>
            </Box>

            <Box my={4}>
                {loading ? (
                        <CircularProgress style={{ color: '#189AB4'}} />
                    ) : (

                    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
                        <DataGrid
                            rows={filteredLessons}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowClick={handleRowClick}
                        />
                    </div>
                )}
            </Box>
        </Container>
    );


};

export default LessonsPage;