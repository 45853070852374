import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, CircularProgress, Box, TextField, InputAdornment, Modal, Checkbox, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import TermsAndConditionsPage from './members/TermsAndConditionsPage'; 
import GdprPage from './members/GdprPage';

import CreditCardIcon from '@mui/icons-material/CreditCard';

const PurchasePage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [membership, setMembership] = useState(null); // Holds membership details
    const [loading, setLoading] = useState(true); // Indicates loading state
    const [error, setError] = useState(null); // Holds error messages
    const [termsModalOpen, setTermsModalOpen] = useState(false); // Modal visibility
    const [gdprModalOpen, setGdprModalOpen] = useState(false); // Modal visibility
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false); // Tracks if user scrolled
    const [isAgreed, setIsAgreed] = useState(false); // Tracks agreement
    const [isGdprScrolledToEnd, setIsGdprScrolledToEnd] = useState(false); // Tracks if user scrolled
    const [isGdprAgreed, setIsGdprAgreed] = useState(false); // Tracks agreement

    const location = useLocation();
    const navigate = useNavigate();

    // Helper to parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const membershipId = queryParams.get('membershipId');
    const token = queryParams.get('token');

    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      cardNumber: "",
      expirationDate: "",
      cvc: "",
      cardholderName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    });
  

    const [snackbar, setSnackbar] = useState({
      open: false,
      message: "",
      severity: "info", // Can be "success", "error", or "warning"
    });
    
    const showSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };
  
    const validateForm = () => {
      if (!isAgreed || !isGdprAgreed) {
          showSnackbar("You must agree to the GDPR Policy and Terms & Conditions.", "error");
          return false;
      }
  
      const requiredFields = [
          "firstName",
          "lastName",
          "email",
          "phone",
          "cardNumber",
          "expirationDate",
          "cvc",
          "addressLine1",
          "city",
          "state",
          "postalCode",
          "country",
      ];
  
      for (const field of requiredFields) {
          if (!formData[field]) {
              showSnackbar(`Please complete the ${field.replace(/([A-Z])/g, " $1")} field.`, "error");
              return false;
          }
      }
  
      return true;
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
  
    // Scroll tracking function
    const handleScroll = (e) => {
      const target = e.target;
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        setIsScrolledToEnd(true);
        setIsGdprScrolledToEnd(true);
      }
    };

    const handlePurchase = async () => {
      if (!validateForm()) return;
  
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/purchase/purchase-membership`,
          { ...formData, membershipId: membership.id, token }, // Include token in the body
        );
  
        if (response.data.paymentUrl) {
            showSnackbar("Redirecting to payment...", "success");
            window.location.href = response.data.paymentUrl;
        } else {
            showSnackbar("Failed to retrieve the payment URL.", "error");
        }
      } catch (err) {
          console.error("Error completing purchase:", err);
          showSnackbar(err.response?.data?.error || "Failed to complete the purchase. Please try again.", "error");
      }
    };

    useEffect(() => {
      const fetchMembership = async () => {
        if (!membershipId || !token) {
          setError("Invalid or missing membership details.");
          setLoading(false);
          return;
        }

        try {
          const response = await axios.get(`${API_BASE_URL}/api/memberships/${membershipId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setMembership(response.data);
        } catch (error) {
          console.error("Error fetching membership:", error);
          setError("Failed to fetch membership details. The link might have expired.");
        }
        finally {
          setLoading(false);
        }
      };
    
      fetchMembership();
    }, [membershipId, token]);
    
    if (loading) {
      return (
        <Box sx={{ textAlign: 'center', mt: 10 }}>
          <CircularProgress style={{ color: '#189AB4' }} />
          <Typography variant="h6" mt={2}>Loading membership details...</Typography>
        </Box>
      );
    }
    if (error) {
      return (
        <Box sx={{ textAlign: 'center', mt: 10 }}>
          <Typography variant="h6" color="error">{error}</Typography>
          <Button variant="contained" onClick={() => navigate("/")}>Go Back</Button>
        </Box>
      );
    }

    return (
        <>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Container  sx={{ py: 4, maxWidth: '1000px !important' }}>
          {/* Header Section */}
          <Box textAlign="center" mb={4}>
            <Typography variant="h6" fontWeight="bold">
              S.C. ACADEMILLION S.R.L.
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {membership.name} Membership
            </Typography>
            <Typography
              variant="h4"
              color="prinary"
              fontWeight="bold"
              sx={{ mt: 1 }}
            >
              €{membership.price}
            </Typography>
          </Box>

          {/* Form Section */}
          <Box
            component="form"
            sx={{
              backgroundColor: '#fff',
              p: 4,
              borderRadius: 2,
              boxShadow: 2,
            }}
          >

          {/* Contact Information */}
          <Typography variant="h6" gutterBottom>
            Contact information
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              type="text"
              placeholder="Josh"
              margin="normal"
              required
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              type="text"
              placeholder="Smith"
              required
              margin="normal"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              placeholder="email@example.com"
              margin="normal"
              required
              value={formData.email}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phone"
              placeholder="0712 034 567"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    🇷🇴
                  </InputAdornment>
                ),
              }}
              margin="normal"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </Box>

          {/* Card Information */}
          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
            Card information
          </Typography>
          <TextField
            fullWidth
            label="Card Number"
            name="cardNumber"
            placeholder="1234 1234 1234 1234"
            required
            margin="normal"
            sx={{ mt: 3 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src="https://media.plationline.ro/images/logo-po-visa-mastercard.png"
                    alt="Logo PlatiOnline Visa & MasterCard"
                    style={{ height: '24px' }}
                  />
                </InputAdornment>
              ),
            }}
            value={formData.cardNumber}
            onChange={handleInputChange}
          />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mt: 3,
            }}
          >
            <TextField 
              fullWidth 
              label="Expiration date (MM / YY)" 
              name="expirationDate"
              placeholder="MM / YY" 
              required 
              inputProps={{
                maxLength: 5, // Limit input length
                pattern: "(0[1-9]|1[0-2])/(\\d{2})", // Regex for MM / YY
              }}
              value={formData.expirationDate}
              onChange={handleInputChange}
            />
            <TextField 
              fullWidth 
              label="CVC" 
              name="cvc"
              placeholder="CVC" 
              required 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CreditCardIcon />
                  </InputAdornment>
                ),
                maxLength: 3, // Limit input to 3 characters
              }}
              value={formData.cvc}
              onChange={handleInputChange}
            />
          </Box>
          <TextField
            fullWidth
            label="Cardholder Name"
            name="cardholderName"
            placeholder="Full name on card"
            margin="normal"
            sx={{ mt: 4 }}
            required
            value={formData.cardholderName}
            onChange={handleInputChange}
          />

          {/* Billing Address */}
          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
            Billing address
          </Typography>
          <TextField 
            fullWidth 
            label="Address Line 1" 
            name="addressLine1"
            margin="normal" 
            sx={{ mt: 3 }} 
            required
            value={formData.addressLine1}
            onChange={handleInputChange} 
          />
          <TextField fullWidth label="Address Line 2" margin="normal" />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label="City"
              name="city"
              type="text"
              placeholder="Sibiu"
              margin="normal"
              required
              value={formData.city}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="State/Province"
              name="state"
              type="text"
              placeholder="Sibiu"
              required
              margin="normal"
              value={formData.state}
              onChange={handleInputChange} 
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mt: 2,
            }}
          >
            <TextField 
              fullWidth 
              label="Postal Code"
              name="postalCode"
              margin="normal" 
              required
              value={formData.postalCode}
              onChange={handleInputChange} 
            />
            <TextField
              fullWidth
              label="Country"
              name="country"
              placeholder="Romania"
              margin="normal"
              required
              value={formData.country}
              onChange={handleInputChange} 
            />
          </Box>

          {/* Terms Link */}
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Before purchasing check our{" "}
            <Box
              component="span"
              sx={{
                color: "primary.main",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setTermsModalOpen(true)}
            >
              Terms & Conditions
            </Box>{" "} and {" "}
            <Box
              component="span"
              sx={{
                color: "primary.main",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setGdprModalOpen(true)}
            >
              GDPR Policy
            </Box>
          </Typography>

          {/* Modal for Terms & Conditions */}
          <Modal
            open={termsModalOpen}
            onClose={() => setTermsModalOpen(false)}
            aria-labelledby="terms-modal-title"
            aria-describedby="terms-modal-description"
          >
            <Box
              sx={{
                width: "80%",
                bgcolor: "background.paper",
                margin: "auto",
                mt: "5%",
                p: 2,
                borderRadius: 2,
                boxShadow: 24,
                maxHeight: "100vh",
                overflowY: "auto",
              }}
            >
              {/* Embed TermsAndConditionsPage with Scroll Event */}
              <Box
                id="terms-modal-content"
                sx={{ maxHeight: "70vh", overflowY: "scroll", pr: 1 }}
                onScroll={handleScroll}
              >
                <TermsAndConditionsPage />
              </Box>

              {/* Agreement Section */}
              <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                <Checkbox
                  disabled={!isScrolledToEnd} // Enable only after scrolling
                  checked={isAgreed}
                  onChange={(e) => setIsAgreed(e.target.checked)}
                />
                <Typography
                  component="span"
                  sx={{ verticalAlign: "middle", ml: 1 }}
                  variant="body2"
                >
                  I agree to the Terms & Conditions
                </Typography>
              </Box>

              {/* Close Modal Button */}
              <Button
                onClick={() => setTermsModalOpen(false)}
                disabled={!isAgreed} // Enable only if agreed
                variant="contained"
                color="primary"
                sx={{ mt: 2, color: '#fff' }}
              >
                Close
              </Button>
            </Box>
          </Modal>

          {/* Modal for GDPR Policy */}
          <Modal
            open={gdprModalOpen}
            onClose={() => setGdprModalOpen(false)}
            aria-labelledby="terms-modal-title"
            aria-describedby="terms-modal-description"
          >
            <Box
              sx={{
                width: "80%",
                bgcolor: "background.paper",
                margin: "auto",
                mt: "5%",
                p: 2,
                borderRadius: 2,
                boxShadow: 24,
                maxHeight: "100vh",
                overflowY: "auto",
              }}
            >
              {/* Embed GdprPage with Scroll Event */}
              <Box
                id="terms-modal-content"
                sx={{ maxHeight: "70vh", overflowY: "scroll", pr: 1 }}
                onScroll={handleScroll}
              >
                <GdprPage />
              </Box>

              {/* Agreement Section */}
              <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                <Checkbox
                  disabled={!isGdprScrolledToEnd} // Enable only after scrolling
                  checked={isGdprAgreed}
                  onChange={(e) => setIsGdprAgreed(e.target.checked)}
                />
                <Typography
                  component="span"
                  sx={{ verticalAlign: "middle", ml: 1 }}
                  variant="body2"
                >
                  I agree to GDPR Policy
                </Typography>
              </Box>

              {/* Close Modal Button */}
              <Button
                onClick={() => setGdprModalOpen(false)}
                disabled={!isGdprAgreed} // Enable only if agreed
                variant="contained"
                color="primary"
                sx={{ mt: 2, color: '#fff' }}
              >
                Close
              </Button>
            </Box>
          </Modal>
          
            {/* Submit Button */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 4, py: 1.5, color: '#fff' }}
              onClick={handlePurchase}
            >
              Pay €{membership.price}
            </Button>
        </Box>
      </Container>
      </>
    );
};

export default PurchasePage;