import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, CircularProgress, Box, FormControl, FormGroup, FormControlLabel, 
    Checkbox, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const MembershipDetailPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const { id } = useParams();  // Get membership ID from the URL
    const navigate = useNavigate();
    const [membership, setMembership] = useState({ name: '', description: '', price: '', courses: [] });
    const [availableCourses, setAvailableCourses] = useState([]);  // To store all available courses
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        // Fetch membership details and available courses
        const fetchData = async () => {
            try {
                const [membershipResponse, coursesResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/api/memberships/${id}`),
                    axios.get(`${API_BASE_URL}/api/courses/courses`)  
                ]);

                setMembership({
                    ...membershipResponse.data,
                    courses: membershipResponse.data.courses.map(course => course.id),
                });
                setAvailableCourses(coursesResponse.data); 
            } catch (err) {
                setError('Error fetching membership details or courses.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMembership((prevMembership) => ({
            ...prevMembership,
            [name]: value,
        }));
    };

    const handleCourseChange = (courseId) => {
        setMembership((prevMembership) => {
            const isCourseSelected = prevMembership.courses.includes(courseId);
            const newCourses = isCourseSelected
                ? prevMembership.courses.filter(id => id !== courseId) // Remove course if it was selected
                : [...prevMembership.courses, courseId]; // Add course if it was not selected

            return { ...prevMembership, courses: newCourses };
        });
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/api/memberships/${id}`);
            navigate('/memberships'); // Redirect back to the memberships list after deletion
        } catch (error) {
            console.error('Error deleting membership:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        console.log(membership);
        try {
            await axios.put(`${API_BASE_URL}/api/memberships/edit/${id}`, membership);
            navigate(`/memberships`); 
        } catch (err) {
            setError('Error updating membership. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><CircularProgress style={{ color: '#189AB4' }} /></Container>;
    }

    if (error) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><Typography color="error">{error}</Typography></Container>;
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Edit membership details
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    onClick={() => setConfirmDelete(true)}
                    style={{ color: 'white'}}
                >
                    Delete Membership
                </Button>

                <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this membership?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete} color="secondary" variant="outlined" style={{color: '#189AB4'}}>
                            Delete
                        </Button>
                        <Button onClick={() => setConfirmDelete(false)} color="error" variant="outlined" style={{color: '#05445E'}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <form onSubmit={handleSubmit}>
                <Box 
                    sx={{
                        mt: 4,
                        p: 6,
                        boxShadow: 3,
                        borderRadius: 2,
                    }}
                >
                    <TextField
                        label="Name"
                        name="name"
                        value={membership.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        style={{marginBottom: '50px'}}
                    />
                    <TextField
                        label="Description"
                        name="description"
                        value={membership.description}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                        rows={5}
                        style={{marginBottom: '50px'}}
                    />
                    <TextField
                        label="Price"
                        name="price"
                        type="number"
                        value={membership.price}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />

                    {/* Checkboxes for courses */}
                    <FormControl component="fieldset" sx={{ mt: 4 }}>
                        <FormGroup>
                            <Typography variant="h6">Select Courses</Typography>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between'
                            }}>
                                {availableCourses.map((course) => (
                                    <FormControlLabel
                                        key={course.id}
                                        control={
                                            <Checkbox
                                                checked={membership.courses.includes(course.id)}
                                                onChange={() => handleCourseChange(course.id)}
                                            />
                                        }
                                        label={course.title}
                                    />
                                ))}
                            </Box>
                        </FormGroup>
                    </FormControl>

                    {error && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 4 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            style={{ color: 'white'}}
                            startIcon={<SaveOutlinedIcon />}
                            disabled={saving}
                        >
                            {saving ? 'Saving...' : 'Save Changes'}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<CancelIcon />}
                            onClick={() => navigate('/memberships')}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </form>
        </Container>
    );
};

export default MembershipDetailPage;