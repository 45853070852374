/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Base Styles
import colors from "../../base/colors";
import typography from "../../base/typography";

const { text, secondary, error } = colors;
const { size } = typography;

const inputLabel = {

  defaultProps: {
    shrink: false, // Disable shrink globally
  },

  styleOverrides: {

    asterisk: {
      color: error.main,
    },

    root: {
      fontSize: size.sm,
      color: text.main,
      lineHeight: 0.9,

      shrink: false,
      // transform: 'translate(10px, -20px) scale(1)', // Ensure label is at the top by default
      // transition: 'transform 0.2s ease-in-out',    // Add smooth transition for label

      top: '-35px',
      left: '-5px',
      transformOrigin: 'top left',

      "&.Mui-focused": {
        color: secondary.focus,
        color: text.main,
      },

      "&.MuiInputLabel-shrink": {
        lineHeight: 1.5,
        fontSize: size.md,
        top: '0px',
        left: '0px',
        transformOrigin: 'top left',
        //transform: 'translate(15px, -10px) scale(0.75)',

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: "0.85em",
        },
      },
    },

    sizeSmall: {
      fontSize: size.xs,
      lineHeight: 1.625,

      "&.MuiInputLabel-shrink": {
        lineHeight: 1.6,
        fontSize: size.sm,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: "0.72em",
        },
      },
    },
  },
};

export default inputLabel;
