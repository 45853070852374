import { useEffect, useRef } from "react";
import shaka from "shaka-player";
import "mux.js";

const ShakaPlayer = ({ blobUrl }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    async function loadVideo() {
      if (!shaka.Player.isBrowserSupported()) {
        console.error("Browser not supported!");
        return;
      }

      const video = videoRef.current;
      const player = new shaka.Player(video);

      player.addEventListener("error", (event) => {
        console.error("Shaka Player Error:", event.detail);
      });

      try {
        console.log("Loading video:", blobUrl);
        await player.load(blobUrl);
        console.log("HLS video loaded successfully!");
      } catch (error) {
        console.error("Error loading video:", error);
      }
    }

    loadVideo();

    return () => {};
  }, [blobUrl]);

  return (
      <video ref={videoRef} controls />
  );
};

export default ShakaPlayer;