import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Snackbar, Alert, Box, IconButton, AppBar, Toolbar, Tabs, Tab, Card, CardMedia, CardContent, LinearProgress, Stack, Link } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import 'swiper/css';
import 'swiper/css/navigation'; 
import 'swiper/css/pagination'; 
import DOMPurify from 'dompurify';
import logo from '../../assets/images/logos/Logo-01.png';
import CopyrightIcon from '@mui/icons-material/Copyright';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';


const MembersCoursesPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const currentUserId = localStorage.getItem('userId') || sessionStorage.getItem('userId');

    const [courses, setCourses] = useState([]);
    const [userJourneyCourses, setUserJourneyCourses] = useState([]);
    const [trendingCourses, setTrendingCourses] = useState([]);
    const [advancedCourses, setAdvancedCourses] = useState([]);
    const [comingSoonCourses, setComingSoonCourses] = useState([]);
    const [authorsAM, setAuthors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [activeTab, setActiveTab] = useState(0);

    const [isBeginning, setIsBeginning] = useState({});
    const [isEnd, setIsEnd] = useState({});
    const swiperRef = useRef(null);

    const navigate = useNavigate();
    const theme = useTheme();

    // Custom Left Arrow Component
    const CustomAuthorLeftArrow = ({ onClick }) => (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          left: "-30px",
          transform: "translateY(-110%)",
          zIndex: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "100",
            color: "#0A435E",
          }}
        >
          &#8249;
        </Typography>
      </Box>
    );

    const categoryIcons = {
        Money: <AttachMoneyIcon sx={{ color: "green", fontSize: 36, mr: 1 }} />,
        Investments: <TrendingUpRoundedIcon sx={{ color: "blue", fontSize: 36, mr: 1 }} />
    };
      
    // Custom Right Arrow Component
    const CustomAuthorRightArrow = ({ onClick }) => (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          right: "-30px",
          transform: "translateY(-110%)",
          zIndex: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
        }}
      >
        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "100",
            color: "#0A435E",
          }}
        >
          &#8250;
        </Typography>
      </Box>
    );
      
    const CustomAuthorDots = ({ dots }) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          marginTop: "10px", 
        }}
      >
        {dots.map((dot, index) => (
          <Box
            key={index}
            onClick={dot.props.onClick}
            sx={{
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              backgroundColor: dot.props.className.includes("slick-active")
                ? "#189AB4" 
                : "#D9D9D9", 
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          />
        ))}
      </Box>
    );
      
    {/* Custom Pagination Dots */}
    <div className="custom-pagination" style={{ textAlign: 'center' }}>
      <CustomAuthorDots/>
    </div>

    const courseSliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600, // Small screens (phones)
                settings: { slidesToShow: 1.25, slidesToScroll: 1 }
            },
            {
                breakpoint: 1024, // Tablets
                settings: { slidesToShow: 2, slidesToScroll: 1 }
            },
            {
                breakpoint: 1440, // Large screens
                settings: { slidesToShow: 3, slidesToScroll: 1 }
            }
        ],
        appendDots: (dots) => <CustomAuthorDots dots={dots} />,
    };

    const authorsCarouselSettings = {
        dots: true, 
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1, 
        responsive: [
          {
            breakpoint: 600, 
            settings: {
              slidesToShow: 2.1, 
              slidesToScroll: 1,
              arrows: false, 
              dots: true, 
            },
          },
          {
            breakpoint: 1024, 
            settings: {
              slidesToShow: 2, 
              slidesToScroll: 1,
              arrows: true, 
              dots: true, 
            },
          },
        ],
        arrows: true, 
        prevArrow: <CustomAuthorLeftArrow />, 
        nextArrow: <CustomAuthorRightArrow />,
        appendDots: (dots) => <CustomAuthorDots dots={dots} />,
    };

    // useEffect(() => {
    //     const loadAllData = async () => {
    //         if (!currentUserId) {
    //             navigate('/login');
    //             return;
    //         }

    //         setLoading(true);
    //         try {
    //             const [coursesRes, journeyRes, trendingRes, advancedRes, comingSoonRes, authorsRes] = await Promise.all([
    //                 axios.get(`${API_BASE_URL}/api/courses/courses`),
    //                 axios.get(`${API_BASE_URL}/api/videoprogress`, {
    //                     params: { memberId: currentUserId },
    //                 }),
    //                 axios.get(`${API_BASE_URL}/api/courses/trending`),
    //                 axios.get(`${API_BASE_URL}/api/courses/without-memberships`),
    //                 axios.get(`${API_BASE_URL}/api/courses/without-videos`),
    //                 axios.get(`${API_BASE_URL}/api/authors`),
    //             ]);
    
    //             setCourses(coursesRes.data);
    //             setUserJourneyCourses(journeyRes.data);
    //             setTrendingCourses(trendingRes.data);
    //             setAdvancedCourses(advancedRes.data);
    //             setComingSoonCourses(comingSoonRes.data);
    //             setAuthors(authorsRes.data);
    //         } catch (err) {
    //             console.error('Error fetching data:', err);
    //             setError('Failed to load data. Please try again later.');
    //             setSnackbarOpen(true);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };
    
    //     loadAllData();
    // }, []);

    useEffect(() => {
        const loadAllData = async () => {
            if (!currentUserId) {
                navigate('/login');
                return;
            }
    
            setLoading(true);
            setError(null);
    
            const fetchData = async (url, setState) => {
                try {
                    const response = await axios.get(url);
                    if (response.data) {
                        setState(response.data);
                    }
                } catch (err) {
                    console.error(`Error fetching ${url}:`, err);
                }
            };
    
            await Promise.all([
                fetchData(`${API_BASE_URL}/api/courses/courses`, setCourses),
                fetchData(`${API_BASE_URL}/api/videoprogress?memberId=${currentUserId}`, setUserJourneyCourses),
                fetchData(`${API_BASE_URL}/api/courses/trending`, setTrendingCourses),
                fetchData(`${API_BASE_URL}/api/courses/without-memberships`, setAdvancedCourses),
                fetchData(`${API_BASE_URL}/api/courses/without-videos`, setComingSoonCourses),
                fetchData(`${API_BASE_URL}/api/authors`, setAuthors),
            ]);
    
            setLoading(false);
        };
    
        loadAllData();
    }, []);
    
    

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSwiperUpdate = (swiper, category) => {
        swiperRef[category] = swiper;
        
        setIsBeginning((prev) => ({
            ...prev,
            [`isBeginning_${category}`]: swiper.isBeginning,
        }));
        setIsEnd((prev) => ({
            ...prev,
            [`isEnd_${category}`]: swiper.isEnd,
        }));
    };
    
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const categories = {
        Money: courses.filter(course => course.category === 'money'),
        Investments: courses.filter(course => course.category === 'investments'),
    };

    return (
        <Container maxWidth="md" sx={{ mt: { xs: 2, xxl: 6 } }}>
            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}

            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '80vh',
                    }}
                >
                    <CircularProgress style={{ color: '#189AB4' }} />
                </Box>
            ) : (
                <>
                    {/* AppBar with category links */}
                    <AppBar position="sticky" color="default"
                        sx={{
                            display: { xs: "none", xxl: "block" }
                        }}
                    >
                        <Toolbar sx={{ borderBottom: '1px solid #ddd' }}>
                            <Tabs 
                                value={activeTab} 
                                onChange={handleTabChange} 
                                sx={{ width: '100%', justifyContent: 'space-between' }}
                            >
                                {userJourneyCourses?.length > 0 && (
                                    <Tab label="Your Journey" component={ScrollLink} to="your-journey" offset={-80} smooth duration={500} spy activeClass="active-tab" /> 
                                )}
                                <Tab label="Trending" component={ScrollLink} to="trending" offset={-80} smooth duration={500} spy activeClass="active-tab" />
                                
                                {Object.keys(categories).map((category, index) => (
                                    <Tab
                                        key={index}
                                        label={category}
                                        component={ScrollLink}
                                        to={category.toLowerCase()}
                                        offset={-80}
                                        smooth
                                        duration={500}
                                        spy
                                        activeClass="active-tab"
                                    />
                                ))}

                                {advancedCourses?.length > 0 && (
                                    <Tab label="Advanced" component={ScrollLink} to="advanced" offset={-80} smooth duration={500} spy activeClass="active-tab" />
                                )}
                                {comingSoonCourses?.length > 0 && (
                                    <Tab label="Coming Soon" component={ScrollLink} to="coming-soon" offset={-80} smooth duration={500} spy activeClass="active-tab" />
                                )}
                                <Tab label="Authors" component={ScrollLink} to="authors" offset={-200} smooth duration={500} spy activeClass="active-tab" />
                            </Tabs>
                        </Toolbar>
                    </AppBar>

                    {/* Category Sections */}
                    <Box sx={{ mt: { xs: 2, xxl: 4 } }}>
                        <Box id="your-journey" sx={{ mt: { xs: 3, xxl: 6 } }}>
                            <Typography variant="h5" gutterBottom>Your Journey</Typography>

                            {userJourneyCourses.length > 0 && (
                                <Box sx={{ display: { xs: "block", xxl: "none" }, width: '100%', mt: 2, overflow: "hidden" }}>
                                    <Slider {...courseSliderSettings}>
                                        {userJourneyCourses.map((progress) => {
                                            const { 
                                                courseId, 
                                                courseTitle, 
                                                courseThumbnail, 
                                                totalLessons, 
                                                completedLessons, 
                                                currentLesson 
                                            } = progress;

                                            return (
                                                <Box key={courseId} sx={{ display: "flex", justifyContent: "center", px: 0 }}>
                                                    <Card 
                                                        key={courseId} 
                                                        sx={{ 
                                                            minWidth: "unset",
                                                            minHeight: "420px", 
                                                            width: "300px",
                                                            borderRadius: 3, 
                                                            boxShadow: 3, 
                                                            mb: 2,
                                                        }}
                                                        onClick={() => navigate(`/mycourses/course/${courseId}`)}
                                                    >
                                                        {/* Course Header Section */}
                                                        <Box sx={{ display: 'flex', alignItems: 'center', padding: { xs: 1, xxl: 2 } }}>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ width: 80, height: "auto", borderRadius: 2, margin: 0, objectFit: 'cover' }}
                                                                image={courseThumbnail}
                                                                alt={courseTitle}
                                                            />
                                                            <Box sx={{ px: { xs: 1, xxl: 2 } }}>
                                                                <Typography variant="body1" fontWeight="bold">
                                                                    {courseTitle}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {completedLessons} of {totalLessons} lessons completed
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        {/* Lesson Thumbnail Section */}
                                                        {currentLesson && (
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ width: "100%", height: "auto", borderRadius: 0, margin: 0, objectFit: 'cover' }}
                                                                image={currentLesson.lessonThumbnail}
                                                                alt={currentLesson.lessonTitle}
                                                            />
                                                        )}

                                                        {/* Lesson Details Section */}
                                                        <CardContent sx={{ padding: { xs: 1, xxl: 2 } }}>
                                                            {currentLesson && (
                                                                <>
                                                                    <Typography variant="subtitle2" color="textSecondary">
                                                                        Lesson {currentLesson.order}
                                                                    </Typography>
                                                                    <Typography variant="h6" fontWeight="bold">
                                                                        {currentLesson.lessonTitle}
                                                                    </Typography>
                                                                </>
                                                            )}

                                                            {/* Progress Indicator */}
                                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 1, xxl: 2 } }}>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={(completedLessons / totalLessons) * 100}
                                                                    sx={{ flexGrow: 1, height: 6, borderRadius: 5 }}
                                                                />
                                                            </Box>

                                                            {/* Current Lesson Status */}
                                                            <Typography variant="body2" sx={{ mt: 1, color: '#6c757d', fontWeight: 'bold', paddingBottom: 0 }}>
                                                                {currentLesson 
                                                                    ? `Your class is currently at Lesson ${currentLesson.order}`
                                                                    : "No lessons started yet"}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            );
                                        })}
                                    </Slider>
                                </Box>
                            )}
                            
                            {userJourneyCourses.length > 0 ? (
                                <Box sx={{ display: { xs: 'none', xxl: 'flex' }, flexWrap: 'wrap', gap: 2 }}>
                                    {userJourneyCourses.map((progress) => {
                                        const { 
                                            courseId, 
                                            courseTitle, 
                                            courseThumbnail, 
                                            totalLessons, 
                                            completedLessons, 
                                            currentLesson 
                                        } = progress;

                                        return (
                                            <Card 
                                                key={courseId} 
                                                sx={{ maxWidth: { xs: 250, xxl: 384 }, borderRadius: 3, boxShadow: 3 }}
                                                onClick={() => navigate(`/mycourses/course/${courseId}`)}
                                            >
                                                {/* Course Header Section */}
                                                <Box sx={{ display: 'flex', alignItems: 'center', padding: { xs: 1, xxl: 2 } }}>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ width: 80, height: "auto", borderRadius: 2, margin: 0, objectFit: 'cover' }}
                                                        image={courseThumbnail}
                                                        alt={courseTitle}
                                                    />
                                                    <Box sx={{ px: { xs: 1, xxl: 2 } }}>
                                                        <Typography variant="body1" fontWeight="bold">
                                                            {courseTitle}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {completedLessons} of {totalLessons} lessons completed
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                {/* Lesson Thumbnail Section */}
                                                {currentLesson && (
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ width: "100%", height: "auto", borderRadius: 0, margin: 0, objectFit: 'cover' }}
                                                        image={currentLesson.lessonThumbnail}
                                                        alt={currentLesson.lessonTitle}
                                                    />
                                                )}

                                                {/* Lesson Details Section */}
                                                <CardContent sx={{ padding: { xs: 1, xxl: 2 } }}>
                                                    {currentLesson && (
                                                        <>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                Lesson {currentLesson.order}
                                                            </Typography>
                                                            <Typography variant="h6" fontWeight="bold">
                                                                {currentLesson.lessonTitle}
                                                            </Typography>
                                                        </>
                                                    )}

                                                    {/* Progress Indicator */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 1, xxl: 2 } }}>
                                                        <LinearProgress
                                                            variant="determinate"
                                                            value={(completedLessons / totalLessons) * 100}
                                                            sx={{ flexGrow: 1, height: 6, borderRadius: 5 }}
                                                        />
                                                    </Box>

                                                    {/* Current Lesson Status */}
                                                    <Typography variant="body2" sx={{ mt: 1, color: '#6c757d', fontWeight: 'bold', paddingBottom: 0 }}>
                                                        {currentLesson 
                                                            ? `Your class is currently at Lesson ${currentLesson.order}`
                                                            : "No lessons started yet"}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        );
                                    })}
                                </Box>
                            ) : (
                                <Typography>No courses in your journey yet. Start learning today!</Typography>
                            )}
                        </Box>


                        {trendingCourses?.length > 0 && (
                            <Box id="trending" sx={{ mt: { xs: 3, xxl: 6 } }}>
                                <Typography variant="h5" gutterBottom>
                                    Trending
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'flex-start', // Align items to the left
                                        width: '100%',
                                        mt: 2 
                                    }}
                                >
                                {trendingCourses.map((course, index) => (
                                    <Box
                                        key={course.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 2, // Add spacing between items
                                            marginBottom: 2,
                                            width: '100%',
                                            [theme.breakpoints.up('640')]: {
                                            width: '100%',
                                            },
                                            [theme.breakpoints.up('768')]: {
                                            width: '32%',
                                            },
                                            [theme.breakpoints.up('1024')]: {
                                            width: '32%',
                                            },
                                        }}
                                    >
                                        {/* Rank Number */}
                                        <Typography
                                            variant="h5"
                                            fontWeight="bold"
                                            sx={{ width: 30, color: '#189AB4' }}
                                        >
                                            {index + 1}
                                        </Typography>

                                        {/* Course Image */}
                                        <Box
                                            component="img"
                                            src={course.thumbnailUrl}
                                            alt={course.title}
                                            sx={{
                                                width: { xs: 120, xxl: 200 },
                                                height: "auto",
                                                borderRadius: 1,
                                                mx: 2,
                                                objectFit: 'cover',
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                            }}
                                            onClick={() => navigate(`/mycourses/course/${course.id}`)}
                                        />

                                        {/* Course Details */}
                                        <Box sx={{ flex: 1, minWidth: 0 }}>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    WebkitLineClamp: 3, 
                                                    lineHeight: 1.2,
                                                }}
                                                onClick={() => navigate(`/mycourses/course/${course.id}`)}
                                            >
                                                {course.title}
                                            </Typography>
                                            {course.authors && course.authors.length > 0 ? (
                                                course.authors.map((author) => (
                                                    <Typography
                                                        key={author.id}
                                                        variant="body2"
                                                        sx={{ color: '#189AB4' }}
                                                        onClick={() => navigate(`/authordetails/${author.id}`)}
                                                    >
                                                    {author.name}
                                                    </Typography>
                                                ))
                                                ) : (
                                                <Typography variant="body2" sx={{ color: '#189AB4' }}>No authors available</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                ))}
                                </Box>
                            </Box>
                        )}


                        <Box sx={{ mt: { xs: 3, xxl: 6 }, width: '100%' }}>
                            <Typography variant="h4" gutterBottom sx={{ mb: 0, color: '#189AB4'}}>Courses by Categories</Typography>
                            Embark on expert-led transformation programs
                        </Box>
                        
                        {Object.keys(categories).map((category, index) => (
                            <Box id={category.toLowerCase()} key={category} sx={{ mt: 2, position: 'relative', width: '100%', maxWidth: '100%', overflow: { xs: 'hidden', xxl: 'visible' } }}>
                                <Typography variant="h5" gutterBottom>
                                    {category} {categoryIcons[category]}
                                </Typography>

                                {/* Left Arrow */}
                                {!isBeginning[`isBeginning_${category}`] && (
                                    <IconButton
                                        sx={{
                                            display: { xs: "none", xxl: "block" },
                                            position: 'absolute',
                                            top: '45%',
                                            left: { xs: 0, xxl: -20 },
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef[category]?.slidePrev()}
                                    >
                                        <ArrowBackIos sx={{ fontSize: 20, paddingLeft: '5px' }} />
                                    </IconButton>
                                )}

                                {/* Right Arrow */}
                                {!isEnd[`isEnd_${category}`] && (
                                    <IconButton
                                        sx={{
                                            display: { xs: "none", xxl: "block" },
                                            position: 'absolute',
                                            top: '45%',
                                            right: { xs: 0, xxl: -20 },
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef[category]?.slideNext()}
                                    >
                                        <ArrowForwardIos sx={{ fontSize: 20, padding: '2px' }} />
                                    </IconButton>
                                )}

                                <Swiper
                                    spaceBetween={20} 
                                    slidesPerView={4} 
                                    style={{ width: "100%", overflow: "hidden" }} 
                                    breakpoints={{
                                        320: { slidesPerView: 1.2 },
                                        640: { slidesPerView: 2 },
                                        768: { slidesPerView: 2 },
                                        1024: { slidesPerView: 4 },
                                    }}
                                    onSwiper={(swiper) => handleSwiperUpdate(swiper, category)}
                                    onSlideChange={(swiper) => handleSwiperUpdate(swiper, category)}
                                    onReachBeginning={() => setIsBeginning(prev => ({ ...prev, [`isBeginning_${category}`]: true }))}
                                    onReachEnd={() => setIsEnd(prev => ({ ...prev, [`isEnd_${category}`]: true }))}
                                    onFromEdge={() => {
                                        setIsBeginning(prev => ({ ...prev, [`isBeginning_${category}`]: false }));
                                        setIsEnd(prev => ({ ...prev, [`isEnd_${category}`]: false }));
                                    }}
                                    loop={false}
                                    modules={[Navigation]}
                                >
                                    {categories[category].map((course, index) => (
                                        <SwiperSlide 
                                            key={`${course.id}-${index}`} 
                                        >
                                            {/* Course Image */}
                                            <Box
                                                component="img"
                                                src={course.thumbnailUrl}
                                                alt={course.title}
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: '100%', 
                                                    height: { xs: 200, xxl: 250 },
                                                    objectFit: 'cover',
                                                    borderRadius: 2,
                                                }}
                                                onClick={() => navigate(`/mycourses/course/${course.id}`)}
                                            />

                                            {/* Course Title */}
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                // sx={{ 
                                                //     whiteSpace: 'nowrap', 
                                                //     overflow: 'hidden', 
                                                //     textOverflow: 'ellipsis' 
                                                // }}
                                                onClick={() => navigate(`/mycourses/course/${course.id}`)}
                                            >
                                                {course.title}
                                            </Typography>

                                            {/* Course Author */}
                                            {course.authors && course.authors.length > 0 ? (
                                                course.authors.map((author) => (
                                                    <Typography
                                                        key={author.id}
                                                        variant="body2"
                                                        sx={{ color: '#189AB4' }}
                                                        onClick={() => navigate(`/authordetails/${author.id}`)}
                                                        >
                                                    {author.name}
                                                    </Typography>
                                                ))
                                                ) : (
                                                <Typography variant="body2" sx={{ color: '#189AB4' }}>No authors available</Typography>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        ))}

                        {/* Advanced Courses Carousel */}
                        {advancedCourses?.length > 0 && (
                            <Box id="advanced" sx={{ mt: { xs: 3, xxl: 6 }, position: 'relative', width: '100%' }}>
                                <Typography variant="h5" gutterBottom>Advanced courses</Typography>

                                {/* Left Arrow for Advanced */}
                                {!isBeginning['isBeginning_advanced'] && (
                                    <IconButton
                                        sx={{
                                            display: { xs: "none", xxl: "block" },
                                            position: 'absolute',
                                            top: '45%',
                                            left: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['advanced']?.slidePrev()}
                                    >
                                        <ArrowBackIos sx={{ fontSize: 20, paddingLeft: '5px' }} />
                                    </IconButton>
                                )}

                                {/* Right Arrow for Advanced */}
                                {!isEnd['isEnd_advanced'] && (
                                    <IconButton
                                        sx={{
                                            display: { xs: "none", xxl: "block" },
                                            position: 'absolute',
                                            top: '45%',
                                            right: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['advanced']?.slideNext()}
                                    >
                                        <ArrowForwardIos sx={{ fontSize: 20, padding: '2px' }} />
                                    </IconButton>
                                )}

                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={4}
                                    style={{ width: "100%", overflow: "hidden" }} 
                                    breakpoints={{
                                        320: { slidesPerView: 1.2 },
                                        640: { slidesPerView: 2 },
                                        768: { slidesPerView: 2 },
                                        1024: { slidesPerView: 4 },
                                    }}
                                    onSwiper={(swiper) => handleSwiperUpdate(swiper, 'advanced')}
                                    onSlideChange={(swiper) => handleSwiperUpdate(swiper, 'advanced')}
                                    onReachBeginning={() => setIsBeginning(prev => ({ ...prev, 'isBeginning_advanced': true }))}
                                    onReachEnd={() => setIsEnd(prev => ({ ...prev, 'isEnd_advanced': true }))}
                                    onFromEdge={() => {
                                        setIsBeginning(prev => ({ ...prev, 'isBeginning_advanced': false }));
                                        setIsEnd(prev => ({ ...prev, 'isEnd_advanced': false }));
                                    }}
                                    loop={false}
                                    modules={[Navigation]}
                                    ref={(swiper) => swiperRef['advanced'] = swiper}
                                >
                                    {advancedCourses.map((course, index) => (
                                        <SwiperSlide
                                            key={course.id}
                                        >
                                            {/* Course Content */}
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                {/* Course Image */}
                                                <Box
                                                    component="img"
                                                    src={course.thumbnailUrl}
                                                    alt={course.title}
                                                    sx={{
                                                        width: '100%',
                                                        height: { xs: 200, xxl: 250 },
                                                        objectFit: 'cover',
                                                        borderRadius: 2,
                                                        position: 'relative', // Ensure text can overlay properly
                                                    }}
                                                    onClick={() => navigate(`/mycourses/course/${course.id}`)}
                                                />
                                                {/* LockOpenIcon in the center of the image */}
                                                <LockIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '47%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        fontSize: '100px !important',   // Adjust icon size
                                                        color: 'rgba(0, 0, 0, 0.5)',  // Adjust color
                                                        zIndex: 5,  // Make sure it sits on top of the image
                                                    }}
                                                />
                                                {/* Course Title */}
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontWeight="bold"
                                                        sx={{
                                                            whiteSpace: 'normal', // Allow text to wrap to next line if necessary
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis', // Apply ellipsis if text is too long
                                                            lineHeight: '1.5', // Adjust line height for better readability
                                                            display: 'block', // Ensure it is a block element for correct sizing
                                                            padding: '0 8px', // Add some padding for better presentation
                                                        }}
                                                        onClick={() => navigate(`/mycourses/course/${course.id}`)}
                                                    >
                                                        {course.title}
                                                    </Typography>
                                                </Box>
                                                {/* Course Author */}
                                                {course.authors && course.authors.length > 0 ? (
                                                    course.authors.map((author) => (
                                                        <Typography
                                                            key={author.id}
                                                            variant="body2"
                                                            sx={{ color: '#189AB4' }}
                                                            onClick={() => navigate(`/authordetails/${author.id}`)}
                                                        >
                                                        {author.name}
                                                        </Typography>
                                                    ))
                                                    ) : (
                                                    <Typography variant="body2" sx={{ color: '#189AB4' }}>No authors available</Typography>
                                                )}
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        )}

                        {/* Coming Soon Courses Carousel */}
                        {comingSoonCourses?.length > 0 && (
                            <Box id="coming-soon" sx={{ mt: { xs: 3, xxl: 6 }, position: 'relative' }}>
                                <Typography variant="h5" gutterBottom>
                                    Coming Soon
                                </Typography>

                                {!isBeginning['isBeginning_comingsoon'] && (
                                    <IconButton
                                        sx={{
                                            display: { xs: "none", xxl: "block" },
                                            position: 'absolute',
                                            top: '38%',
                                            left: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['coming-soon']?.slidePrev()}
                                    >
                                        <ArrowBackIos sx={{ fontSize: 20, paddingLeft: '5px' }} />
                                    </IconButton>
                                )}

                                {!isEnd['isEnd_comingsoon'] && (
                                    <IconButton
                                        sx={{
                                            display: { xs: "none", xxl: "block" },
                                            position: 'absolute',
                                            top: '38%',
                                            right: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['coming-soon']?.slideNext()}
                                    >
                                        <ArrowForwardIos sx={{ fontSize: 20, padding: '2px' }} />
                                    </IconButton>
                                )}

                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={4}
                                    style={{ width: "100%", overflow: "hidden" }} 
                                    breakpoints={{
                                        320: { slidesPerView: 1.2 },
                                        640: { slidesPerView: 2 },
                                        768: { slidesPerView: 2 },
                                        1024: { slidesPerView: 4 },
                                    }}
                                    onSwiper={(swiper) => handleSwiperUpdate(swiper, 'coming-soon')}
                                    onSlideChange={(swiper) => handleSwiperUpdate(swiper, 'coming-soon')}
                                    onReachBeginning={() => setIsBeginning(prev => ({ ...prev, 'isBeginning_comingsoon': true }))}
                                    onReachEnd={() => setIsEnd(prev => ({ ...prev, 'isEnd_comingsoon': true }))}
                                    onFromEdge={() => {
                                        setIsBeginning(prev => ({ ...prev, 'isBeginning_comingsoon': false }));
                                        setIsEnd(prev => ({ ...prev, 'isEnd_comingsoon': false }));
                                    }}
                                    loop={false}
                                    modules={[Navigation]}
                                    ref={(swiper) => (swiperRef['coming-soon'] = swiper)}
                                >
                                    {comingSoonCourses.map((course, index) => (
                                        <SwiperSlide key={course.id}>
                                            {/* Course Content */}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    gap: 2,
                                                }}
                                            >
                                                {/* Course Image */}
                                                <Box
                                                    component="img"
                                                    src={course.thumbnailUrl}
                                                    alt={course.title}
                                                    sx={{
                                                        width: '100%',
                                                        height: { xs: 200, xxl: 250 },
                                                        objectFit: 'cover',
                                                        borderRadius: 2,
                                                    }}
                                                />

                                                {/* Course Details */}
                                                <Box sx={{ width: '100%', textAlign: 'left' }}>
                                                    {/* Course Title */}
                                                    <Typography
                                                        variant="h6"
                                                        fontWeight="bold"
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            //whiteSpace: 'nowrap',
                                                            marginBottom: 1,
                                                        }}
                                                    >
                                                        {course.title}
                                                    </Typography>

                                                    {/* Course Authors */}
                                                    {course.authors && course.authors.length > 0 ? (
                                                        course.authors.map((author) => (
                                                            <Typography
                                                                key={author.id}
                                                                variant="body2"
                                                                sx={{
                                                                    color: '#189AB4',
                                                                    marginBottom: 0.5,
                                                                }}
                                                                onClick={() => navigate(`/authordetails/${author.id}`)}
                                                            >
                                                                {author.name}
                                                            </Typography>
                                                        ))
                                                    ) : (
                                                        <Typography variant="body2" sx={{ color: '#189AB4', marginBottom: 0.5 }}>
                                                            No authors available
                                                        </Typography>
                                                    )}

                                                    {/* Course Description */}
                                                    <Typography
                                                        variant="body2"
                                                        gutterBottom
                                                        sx={{
                                                            color: '#6c757d',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'normal',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 3,
                                                            WebkitBoxOrient: 'vertical',
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(course.description || ''),
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        )}


                        <Box id="authors" sx={{ mt: { xs: 3, xxl: 6 }, mb: 3 }}>
                            <Typography variant="h5" gutterBottom>Authors</Typography>
                            <Box width="100%" 
                                sx={{  
                                    paddingLeft: { xs: "0px", xxl: "0px" }, 
                                    margin: '0 auto', 
                                }}
                                >
                                <Slider key={authorsAM.length} {...authorsCarouselSettings}>
                                    {authorsAM.map((author) => (
                                    <Box
                                        key={author.id}
                                        onClick={() => navigate(`/authordetails/${author.id}`)}
                                        sx={{
                                            width: "156px",
                                            borderRadius: 2,
                                            textAlign: 'center',
                                            position: 'relative',
                                            px: "10px",
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={author.pictureUrl}
                                            alt={author.name}
                                            sx={{
                                                width: "100%",
                                                height: {xs: 269, xxl: 294},
                                                objectFit: 'cover',
                                                borderRadius: 2
                                            }}
                                        />
                                        <Typography variant="body1"
                                            sx={{
                                                mt: 2,
                                                color: "#0A435E",
                                                textTransform: "none",
                                                fontWeight: "700", 
                                            }}
                                        >
                                            {author.name}
                                        </Typography>
                                        <Typography variant="body2"
                                            sx={{
                                                mt: 1,
                                                mb: 1,
                                                color: "#000",
                                                textTransform: "none",
                                                fontWeight: "100", 
                                            }}
                                        >
                                            {author.expertise}
                                        </Typography>
                                    </Box>
                                    ))}
                                </Slider>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", xxl: "row" },
                            justifyContent: { xs: "center", xxl: "space-between" },
                            alignItems: "center",
                            width: "100%",
                            mt: { xs: 3, xxl: 6 },
                            mb: { xs: 2, xxl: 4 }
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: { xs: "center", xxl: "flex-start" },
                                width: "100%",
                                mb: { xs: 2, xxl: 0 }
                            }}
                        >
                            {/* Logo */}
                            <Box 
                                component="img" 
                                src={logo} 
                                alt="Logo" 
                                sx={{ 
                                    width: { xs: "177px", xxl: "242px" },
                                    margin: 0 
                                }} 
                            />

                            {/* Copyright */}
                            <Typography
                                variant="body3"
                                sx={{
                                    mb: { xs: 0, xxl: 0 },
                                    color: "#000",
                                    textTransform: "none",
                                    fontWeight: "300", 
                                    letterSpacing: "normal", 
                                }}
                            >
                            <CopyrightIcon /> Copyright Academillion 2024. All Rights Reserved.
                            </Typography>
                        </Box>

                        {/* Social Media Links Section */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: { xs: "center", xxl: "flex-end" },
                                width: "100%",
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                                Follow Us
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Link href="https://www.facebook.com/academillion" target="_blank">
                                    <FacebookIcon sx={{ color: "#05445E", fontSize: "20px !important" }} />
                                </Link>
                                <Link href="https://www.instagram.com/academillion.club" target="_blank">
                                    <InstagramIcon sx={{ color: "#05445E", fontSize: "20px !important" }} />
                                </Link>
                                <Link href="https://www.tiktok.com/@academillion" target="_blank">
                                    <MusicNoteIcon sx={{ color: "#05445E", fontSize: "20px !important" }} />
                                </Link>
                                <Link href="https://www.youtube.com/@Academillion" target="_blank">
                                    <YouTubeIcon sx={{ color: "#05445E", fontSize: "20px !important" }} />
                                </Link>
                                <Link href="https://www.linkedin.com/company/academillion/" target="_blank">
                                    <LinkedInIcon sx={{ color: "#05445E", fontSize: "20px !important" }} />
                                </Link>
                            </Stack>
                        </Box>
                    </Box>
                </>
            )}
        </Container>
    );
};

export default MembersCoursesPage;