import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  Alert,
} from '@mui/material';

const PaymentPage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const [name, setName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handlePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_BASE_URL}/api/payment/initiate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          cardNumber,
          expiryDate,
          cvv,
          amount,
          currency,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to initiate payment.');
      }

      const data = await response.json();

      if (data.paymentUrl) {
        // Redirect to PlatOnline payment page
        window.location.href = data.paymentUrl;
      } else {
        throw new Error('Invalid payment response.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: 'auto',
        p: 4,
        boxShadow: 3,
        borderRadius: 2,
        bgcolor: 'background.paper',
      }}
    >
      <Typography variant="h5" align="center" gutterBottom>
        Payment Form
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handlePayment} noValidate>
        <TextField
          label="Name on Card"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Card Number"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
        />
        <TextField
          label="Expiry Date (MM/YY)"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
        />
        <TextField
          label="CVV"
          variant="outlined"
          type="password"
          fullWidth
          required
          margin="normal"
          value={cvv}
          onChange={(e) => setCvv(e.target.value)}
        />
        <TextField
          label="Amount"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <TextField
          label="Currency"
          variant="outlined"
          fullWidth
          select
          required
          margin="normal"
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
        >
          <MenuItem value="USD">USD</MenuItem>
          <MenuItem value="EUR">EUR</MenuItem>
          <MenuItem value="GBP">GBP</MenuItem>
          {/* Add other currencies as needed */}
        </TextField>
        <Box mt={3} textAlign="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Processing...' : 'Pay Now'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default PaymentPage;
