import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Snackbar, Alert, Box, TextField, Button, InputLabel, FormControl, 
    Select, MenuItem,  Dialog, DialogActions,  DialogContent, DialogTitle } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const SubLessonDetailPage = () => {
    const { id } = useParams();
    const [lesson, setLesson] = useState({ resources: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [newLesson, setNewLesson] = useState({ title: '', videoUrl: '', courseId: id, subLessons: [], resources: [] });
    const [openModal, setOpenModal] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [resource, setResource] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const response = await axios.get(`https://localhost:7095/api/lessons/${id}`);
                setLesson(response.data);
            } catch (err) {
                console.error('Error fetching lesson:', err);
                setError('Failed to load lesson details. Please refresh the page and try again.');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchResources = async () => {
            try {
                const response = await axios.get('https://localhost:7095/api/resources');
            } catch (err) {
                console.error('Error fetching resources:', err);
                setError('Failed to load resources.');
                setSnackbarOpen(true);
            }
        };
        fetchLesson();
        fetchResources();
    }, [id]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLesson({ ...lesson, [name]: value });
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name); // Make sure to set the fileName state for display
        }
    };

    const handleResourceChange = (e) => {
        const { name, value } = e.target;
        setNewLesson({ ...newLesson, [name]: value });
    };

    const handleUploadResource = async () => {
        
        const resurceData = {
            title: newLesson.title,
        };
    
        try {
            // Save the lesson to your API
            await fetch("https://localhost:7095/api/resources/create", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set content type for JSON
                },
                body: JSON.stringify(resurceData), // Send JSON data
            });

            // Reset state
            handleCloseModal();
            setNewLesson({ title: "", videoFileUrl: "" });
            setFile(null); // Clear selected file
        } catch (error) {
            console.error("Error saving lesson:", error);
            alert("Failed to save the lesson. Please try again.");
        }
    };

    const handleSave = () => {
        navigate('/lessons');
    };

    const handleCancel = () => {
        navigate('/lessons');
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}

            <Typography variant="h2" gutterBottom>
                Edit lesson details
            </Typography>

            <Box
                sx={{
                    mt: 4,
                    p: 6,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                {loading ? (
                    <CircularProgress style={{ color: '#189AB4' }} />
                ) : (
                    <>
                        {lesson && (
                            <>
                                <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
                                    <TextField
                                        name="title"
                                        label="Lesson Title"
                                        value={lesson.title || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        sx={{ marginBottom: '20px' }}
                                    />
                                </Box>

                               

                                <Button variant="contained" color="primary" onClick={handleOpenModal} startIcon={<AddCircleOutlineOutlinedIcon/>}>
                                    Add Resources
                                </Button>

                                {/* Modal for Adding Resources */}
                                <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                                    <DialogTitle align='center' margin={'30px'}>Add new resource</DialogTitle>
                                    <DialogContent margin={'30px'}>
                                        <TextField
                                            label="Resource Title"
                                            variant="outlined"
                                            fullWidth
                                            name="title"
                                            value={newLesson.title}
                                            onChange={handleResourceChange}
                                            sx={{ marginBottom: '20px', marginTop: '20px' }}
                                        />
                                        
                                        <input
                                            type="file"
                                            accept="video/*"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            id="file-upload"
                                        />
                                        <label htmlFor="file-upload">
                                            <Button
                                                variant="contained"
                                                component="span"
                                                color="error"
                                                style={{ width: '100%', marginBottom: '30px' }}
                                            >
                                                Upload Video
                                            </Button>
                                        </label>


                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleUploadResource} color="secondary" startIcon={<AddCircleOutlineOutlinedIcon />}>
                                            Add Resource
                                        </Button>
                                        <Button onClick={handleCloseModal} color="error" startIcon={<CancelIcon />}>
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                
                            </>
                        )}
                    </>
                )}

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        style={{ color: 'white' }}
                        fullWidth
                    >
                        Save
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<CancelIcon />}
                        onClick={handleCancel}
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default SubLessonDetailPage;
