import React, { useEffect, useState, useRef } from 'react';
import Slider from "react-slick";
import { Container, Typography, Box, Button, Card, CardMedia, CardContent, Stack, TextField, Divider, Accordion, AccordionSummary, AccordionDetails, Avatar } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";

import introVideo from '../../assets/videos/intro-HD-EN-sub.mp4';

import customDivider from '../../assets/images/ourclub/divider.png';

import iconCommunityLearn from '../../assets/images/ourclub/icons/community-industry-leaders.png';
import iconCommunityAccess from '../../assets/images/ourclub/icons/community-exclusive-opportunities.png';
import iconCommunityGrow from '../../assets/images/ourclub/icons/community-grow.png';
import iconCommunityBuild from '../../assets/images/ourclub/icons/community-financial-freedom.png';

import businessHolidays from '../../assets/images/ourclub/a1.png';
import communityEvents from '../../assets/images/ourclub/a2.png';
import investmentsHackathons from '../../assets/images/ourclub/a3.png';
import wellnessAndMindset from '../../assets/images/ourclub/a4.png';

import iconPromiseHiddenFees from '../../assets/images/ourclub/icons/promise-no-hidden-fees.png';
import iconPromiseMonayBack from '../../assets/images/ourclub/icons/promise-money-back.png';
import iconPromiseTailoredExperience from '../../assets/images/ourclub/icons/promise-tailored-experience.png';
import iconPromisePriorityAccess from '../../assets/images/ourclub/icons/promise-events-priority.png';
import iconPromiseExclusiveContent from '../../assets/images/ourclub/icons/promise-exclusive-content.png';
import iconPromiseCommunityLeaders from '../../assets/images/ourclub/icons/promise-community-ambassadors.png';

import recommendedBadge from '../../assets/images/ourclub/badge-recommended.png';

import vehiclesBackground from '../../assets/images/ourclub/vehicles-background.png';
import vehicleArt from '../../assets/images/ourclub/vehicle-art.png';
import vehicleJointVenture from '../../assets/images/ourclub/vehicle-joint-venture.png';
import vehicleRealEstate from '../../assets/images/ourclub/vehicle-real-estate.png';
import vehicleCrypto from '../../assets/images/ourclub/vehicle-crypto.png';
import vehicleStock from '../../assets/images/ourclub/vehicle-stock.png';

import testimonialAlexTarcea from '../../assets/videos/testimonials/testimonial-alexandru-tarcea.mp4';
import testimonialThumbnailAlexTarcea from  '../../assets/images/thumbnails/testimonials/thumbnail-alexandru-tarcea.png';
import testimonialAndreeaBirsan from '../../assets/videos/testimonials/testimonial-andreea-birsan.mp4';
import testimonialThumbnailAndreeaBirsan from '../../assets/images/thumbnails/testimonials/thumbnail-andreea-birsan.png';
import testimonialIulianMotoc from '../../assets/videos/testimonials/testimonial-iulian-motoc.mov';
import testimonialThumbnailIulianMotoc from '../../assets/images/thumbnails/testimonials/thumbnail-iulian-motoc.png';
import testimonialMichaelaPetian from '../../assets/videos/testimonials/testimonial-michaela-petian.mov';
import testimonialThumbnailMichaelaPetian from '../../assets/images/thumbnails/testimonials/thumbnail-michaela-petian.png';
import testimonialOanaManase from '../../assets/videos/testimonials/testimonial-oana-manase.mp4';
import testimonialThumbnailOanaManase from '../../assets/images/thumbnails/testimonials/thumbnail-oana-manase.png';
import testimonialOliviaSabau from '../../assets/videos/testimonials/testimonial-olivia-sabau.mp4';
import testimonialThumbnailOliviaSabau from '../../assets/images/thumbnails/testimonials/thumbnail-olivia-sabau.png';
import testimonialBogdanMoldovicean from '../../assets/videos/testimonials/testimonial-bogdan-moldovicean.mov';
import testimonialThumbnailBogdanMoldovicean from '../../assets/images/thumbnails/testimonials/thumbnail-bogdan-moldovicean.png';
import testimonialFabianIt from '../../assets/videos/testimonials/testimonial-fabian-it.mov';
import testimonialThumbnailFabianIt from '../../assets/images/thumbnails/testimonials/thumbnail-fabian-it.png';

import testimonialAvatarAndrei from '../../assets/images/thumbnails/testimonials/testimonial-avatar-andrei.jpeg';
import testimonialAvatarCristina from '../../assets/images/thumbnails/testimonials/testimonial-avatar-cristina.jpeg';
import testimonialAvatarElena from '../../assets/images/thumbnails/testimonials/testimonial-avatar-elena.jpeg';
import testimonialAvatarMihai from '../../assets/images/thumbnails/testimonials/testimonial-avatar-mihai.jpeg';

import blogThumbnail1 from '../../assets/images/thumbnails/blogs/blog1.png';
import blogThumbnail2 from '../../assets/images/thumbnails/blogs/blog2.png';
import blogThumbnail3 from '../../assets/images/thumbnails/blogs/blog3.png';
import blogThumbnail4 from '../../assets/images/thumbnails/blogs/blog4.png';

import Footer from "../../components/Footer";

const OurClub = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const [authors, setAuthors] = useState([]);

  const scrollContainerRef = useRef(null);

  // Custom Left Arrow Component
  const CustomAuthorLeftArrow = ({ onClick }) => (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        left: "-50px",
        transform: "translateY(-110%)",
        zIndex: 1,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "50px",
          fontWeight: "100",
          color: "#0A435E",
        }}
      >
        &#8249;
      </Typography>
    </Box>
  );

  // Custom Right Arrow Component
  const CustomAuthorRightArrow = ({ onClick }) => (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        right: "-50px",
        transform: "translateY(-110%)",
        zIndex: 1,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
      }}
    >
      <Typography
        sx={{
          fontSize: "50px",
          fontWeight: "100",
          color: "#0A435E",
        }}
      >
        &#8250;
      </Typography>
    </Box>
  );

  const CustomAuthorDots = ({ dots }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        marginTop: "10px", // Add spacing from the slider
      }}
    >
      {dots.map((dot, index) => (
        <Box
          key={index}
          onClick={dot.props.onClick}
          sx={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: dot.props.className.includes("slick-active")
              ? "#189AB4" // Active dot color
              : "#D9D9D9", // Inactive dot color
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
        />
      ))}
    </Box>
  );

  <div className="custom-pagination" style={{ textAlign: 'center' }}>
    <CustomAuthorDots/>
  </div>

  const authorsCarouselSettings = {
    dots: true, 
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1, 
    responsive: [
      {
        breakpoint: 600, 
        settings: {
          slidesToShow: 2.5, 
          slidesToScroll: 1,
          arrows: false, 
          dots: true, 
        },
      },
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 1,
          arrows: true, 
          dots: true, 
        },
      },
    ],
    arrows: true, 
    prevArrow: <CustomAuthorLeftArrow />, 
    nextArrow: <CustomAuthorRightArrow />,
    appendDots: (dots) => <CustomAuthorDots dots={dots} />,
  };

  const mobileCarouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Hide arrows for mobile
    appendDots: (dots) => <CustomAuthorDots dots={dots} />,
  };

  const vehicles = [
    { title: "ART", image: vehicleArt },
    { title: "JOINT VENTURES", image: vehicleJointVenture },
    { title: "REAL ESTATE", image: vehicleRealEstate },
    { title: "CRYPTO", image: vehicleCrypto },
    { title: "STOCK", image: vehicleStock },
    
  ];

  const activities = [
    {
      title: "Business Holidays",
      description:
        "Turn your business holidays into exclusive experiences where you learn, connect, and unwind, growing while exploring scenic destinations.",
      image: businessHolidays, 
    },
    {
      title: "Community Events",
      description:
        "Your community events become moments of authentic connection, from networking galas and industry talks to local meetups, all designed to inspire you, connect you with valuable people, and grow together.",
      image: communityEvents,
    },
    {
      title: "Investments Hackathons",
      description:
        "Investment hackathons are intense and engaging weekends where you tackle real challenges, generate innovative ideas, and present them in a dynamic and fun environment.",
      image: investmentsHackathons, 
    },
    {
      title: "Wellness & Mindset Escapes",
      description:
        "Your wellness and mindset escapes become transformational retreats where you reconnect with yourself through mindfulness, yoga, and meditation, while building valuable connections and cultivating both inner and outer growth.",
      image: wellnessAndMindset,
    },
  ];

  const stats = [
    {
      number: "20",
      description: "Self-paced online courses to build essential skills",
    },
    {
      number: "15+",
      description: "Top-tier trainers and experts guiding your financial journey",
    },
    {
      number: "822.000 €",
      description: "Collectively invested by our community in under 3 months",
    },
    {
      number: "100+",
      description: "Members creating their path towards financial freedom",
    },
  ];
  
  const testimonials = [
    {
      video: testimonialAlexTarcea,
      alt: "Testimonial Alexandru Tarcea",
      thumbnail: testimonialThumbnailAlexTarcea,
    },
    {
      video: testimonialAndreeaBirsan,
      alt: "Testimonial Andreea Bîrsan",
      thumbnail: testimonialThumbnailAndreeaBirsan,
    },
    {
      video: testimonialIulianMotoc, 
      alt: "Testimonial Iulian Motoc",
      thumbnail: testimonialThumbnailIulianMotoc,
    },
    {
      video: testimonialMichaelaPetian,
      alt: "Testimonial Michaela Petian",
      thumbnail: testimonialThumbnailMichaelaPetian,
    },
    {
      video: testimonialOanaManase, 
      alt: "Testimonial Oana Mănase",
      thumbnail: testimonialThumbnailOanaManase,
    },
    {
      video: testimonialOliviaSabau,
      alt: "Testimonial Olivia Sabău",
      thumbnail: testimonialThumbnailOliviaSabau,
    },
    {
      video: testimonialBogdanMoldovicean,
      alt: "Testimonial Bogdan Moldovicean",
      thumbnail: testimonialThumbnailBogdanMoldovicean,
    },
    {
      video: testimonialFabianIt,
      alt: "Testimonial Fabian",
      thumbnail: testimonialThumbnailFabianIt,
    },
  ];

  const textTestimonials = [
    {
      name: "Andrei Iliuț, Entrepreneur & Senior software engineer",
      text: "I joined this club to network and learn more about the real estate field. Here, I found an amazing community of wonderful people and inspiring entrepreneurs with whom you can share valuable ideas and experiences. Moreover, I’ve managed to collaborate with some of the club members and develop exciting projects. It’s a fantastic environment for both personal and professional growth!",
      avatar: testimonialAvatarAndrei,
    },
    {
      name: "Cristina Irimie, Entrepreneur & Investor",
      text: "In AM community, you find the courage to learn and grow, knowing you're supported in reaching your fullest potential. With Academillion, we evolve beautifully, together.",
      avatar: testimonialAvatarCristina,
    },
    {
      name: "Elena Deacu, Therapist & Investor",
      text: "I love new projects initiated by passionate people, full of positive energy and laughter! In the Academillion community, I’ve found people like me, simple, cheerful, and ready to get involved and support others. The people at Academillion bring a wealth of expertise and openness, always willing to help, contribute, and join my mission of creating a generation for whom abundance is the norm. Being a member of Academillion means education, professionally assessed investment opportunities, balance, and relaxation.",
      avatar: testimonialAvatarElena,
    },
    {
      name: "Mihai Udrescu, Entreprenor",
      text: "Academillion provides great investment guidance through its talented team, offering strategies and a supportive community. It’s inspiring to see their dedication to supporting the expat community as well.",
      avatar: testimonialAvatarMihai,
    },
  ];
  

  const memberships = [
    {
      title: "SILVER",
      features: [
        "Access to a <strong> Finance and Investments platform</strong>",
        "<strong> 24 Workshops </strong> with <strong> Experts </strong>",
        "<strong> 24 Community Mastermind </strong> sessions",
        "<strong> Events </strong>, Activities, and Exclusive Getaways",
        "<strong> Private Financial Goal-Setting </strong> Session",
        "<strong> Community Leader </strong> Support",
        "<strong> Access </strong> to Exclusive <strong> Investments </strong>",
      ],
      color: "#5FD6DA",
      backgroundColor: "#5FD6DA",
    },
    {
      title: "GOLD",
      features: [
        "Access to a <strong> Finance and Investments platform</strong>",
        "<strong> 24 Workshops </strong> with <strong> Experts </strong>",
        "<strong> 24 Community Mastermind </strong> sessions",
        "<strong> Events </strong>, Activities, and Exclusive Getaways",
        "<strong> Private Financial Goal-Setting </strong> Session",
        "<strong> Community Leader </strong> Support",
        "<strong> Access </strong> to Exclusive <strong> Investments </strong>",
        "<strong> Personalized Financial Plan </strong>",
        "<strong> 5 Financial Coaching </strong> Sessions",
      ],
      color: "#189AB4",
      backgroundColor: "#189AB4",
      recommended: true,
    },
    {
      title: "TITANIUM",
      features: [
        "Access to a <strong> Finance and Investments platform</strong>",
        "<strong> 24 Workshops </strong> with <strong> Experts </strong>",
        "<strong> 24 Community Mastermind </strong> sessions",
        "<strong> Events </strong>, Activities, and Exclusive Getaways",
      ],
      color: "#0A435E",
      backgroundColor: "#0A435E",
    },
  ];

  const promises = [
    {
      icon: iconPromiseHiddenFees, 
      title: "No Hidden Fees",
    },
    {
      icon: iconPromiseMonayBack,
      title: "15 Days Money Back",
    },
    {
      icon: iconPromiseTailoredExperience, 
      title: "Tailored Experience",
    },
    {
      icon: iconPromisePriorityAccess,
      title: "Events Priority Access",
    },
    {
      icon: iconPromiseExclusiveContent,
      title: "Exclusive Content",
    },
    {
      icon: iconPromiseCommunityLeaders,
      title: "Regular Community Leaders 1:1",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
      question: "What is Academillion?",
      answer: [ 
        "Academillion is your exclusive club where you learn smarter, invest with confidence, and connect with inspiring people. Here, you build your future step by step, alongside a dynamic community that supports you in achieving your biggest goals.",
      ],
    },
    {
      question: "Which package is right for me?",
      answer: [
        "• <strong> TITANIUM: </strong> Every great financial journey starts with a strong foundation. This membership is for you if you’re ready to gain <strong> clarity, confidence, and essential financial education </strong> to take control of your future. You’ll have access to <strong> expert insights, community-driven activities, and valuable learning experiences</strong>—because the right knowledge is the key to smart investing and long-term success.",
        "• <strong> SILVER: </strong> You’ve built a solid financial base, and now it’s time to take action. This membership is for investors who want to <strong> refine their strategies, connect with a powerful network, and access exclusive investment opportunities </strong> that can accelerate their growth. If you’re ready to step into the next level of investing with confidence, this is where bold financial decisions start.",
        "• <strong> GOLD: </strong> You’re not just looking for opportunities—you’re looking for the <strong> right opportunities, backed by strategy and expert guidance. </strong> This membership is for investors who want <strong> exclusive access to high-level deals and personalized financial coaching </strong> to create a clear, custom roadmap for their wealth journey. Here, you move with purpose, strategy, and the confidence to build lasting financial success.",
      ],
    },
    {
      question: "Who creates the Academillion courses?",
      answer: [ 
        "Academillion courses are crafted by industry experts and seasoned professionals who share practical knowledge, insights, and strategies from their real-world experience.",
      ],
    },
    {
      question: "How does the guarantee work?",
      answer: [ 
        "You have 15 days to test the platform risk-free. If it doesn’t meet your expectations, simply contact support office@academillion.com for a full refund.",
      ],
    },
    {
      question: "How can I pay?",
      answer: [ 
        "Payments are accepted exclusively via bank transfer.",
      ],
    },
    {
      question: "Can I pay on behalf of a business?",
      answer: [
        "We issue tax invoices for every member. You can use your company’s card to pay for membership and receive a tax invoice deductible for your business. For a complete invoice, please ensure your company’s billing details are provided when issuing the invoice.",
      ],
    },
  ];

  const handleAccordionChange = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const blogPosts = [
    {
      id: 1,
      title: "Design That Pays: Adding Value Through Innovation",
      date: "2 NOV 2024",
      author: "Andreea Birsan",
      excerpt:
        "Small design changes can make a big impact on your property’s value. This blog explores the easiest and most effective updates to create a space that looks amazing and sells even better.",
      image: blogThumbnail1, 
    },
    {
      id: 2,
      title: "2025, the Year of Financial Mastery",
      date: "30 OCT 2024",
      author: "Marian Papară",
      excerpt:
        "Step into the future with confidence. Discover key strategies to master your finances, achieve your goals, and turn 2025 into your most successful year yet.",
      image: blogThumbnail2, 
    },
    {
      id: 3,
      title: "Boosting Financial Growth with Tech and Tokens",
      date: "22 OCT 2024",
      author: "Diana Chirtop",
      excerpt:
        "In today's fast-paced digital world, leveraging innovative technology can be key to achieving financial growth. Portable SSDs, like the one pictured above, symbolize the efficiency and security needed to store digital assets safely.",
      image: blogThumbnail3, 
    },
    {
      id: 4,
      title: "Adventures with a Purpose: Driving Towards Wealth",
      date: "16 OCT 2024",
      author: "Sabina Bodea",
      excerpt:
        "Embark on a journey where financial freedom meets the thrill of exploration. Learn how to align your financial goals with your passions, creating a roadmap for wealth and adventure that fuels both your wallet and your soul.",
      image: blogThumbnail4, 
    },
  ];

  useEffect(() => {
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const scrollWidth = scrollContainerRef.current.scrollWidth;
      scrollContainerRef.current.scrollLeft = (scrollWidth - containerWidth) / 2;
    }


    const fetchAuthors = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/authors`);
        setAuthors(response.data);
      } catch (error) {
        console.error('Error fetching authors:', error);
      }
    };

    const loadTypeformScript = () => {
      const script = document.createElement('script');
      script.src = "//embed.typeform.com/next/embed.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    const playIntroVideoWithSound = () => {
      const video = document.getElementById('intro-video');
      if (video) {
        const playVideo = async () => {
          try {
            await video.play();
          } catch (error) {
            console.error('Autoplay with sound blocked by the browser:', error);
          }
        };
        playVideo();
      }
    };

    const loadUsercentricsScripts = () => {
      // Autoblocker script
      const autoBlockerScript = document.createElement("script");
      autoBlockerScript.src = "https://web.cmp.usercentrics.eu/modules/autoblocker.js";
      autoBlockerScript.async = true;
      document.body.appendChild(autoBlockerScript);
  
      // Usercentrics CMP script
      const userCentricsScript = document.createElement("script");
      userCentricsScript.src = "https://web.cmp.usercentrics.eu/ui/loader.js";
      userCentricsScript.id = "usercentrics-cmp";
      userCentricsScript.setAttribute("data-settings-id", "ZwWPmUEFN-Bu_y");
      userCentricsScript.async = true;
      document.body.appendChild(userCentricsScript);
  
      return () => {
        document.body.removeChild(autoBlockerScript);
        document.body.removeChild(userCentricsScript);
      };
    };

    fetchAuthors();
    const cleanupTypeformScript = loadTypeformScript();
    playIntroVideoWithSound();
    const cleanupUsercentricsScripts = loadUsercentricsScripts();

    return () => {
      cleanupTypeformScript();
      cleanupUsercentricsScripts();
    };
  });

  return (
    <>
    <Box
      sx={{
        backgroundColor: '#fff',
        color: 'white',
        textAlign: 'center',
        position: 'relative',
        paddingTop: { xs: 0, xxl: 6}
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          height: { xs: '580px', lg: '770px' },
          maxHeight: '770px',
          inset: 0,
          background: 'url("/rectangle.png") no-repeat center/cover',
          zIndex: 0,
          backgroundPosition: { xs: 'left', lg: ''}
        }}
      />

      <Container sx={{ zIndex: 1, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: { xs: "20px" , xxl: "60px"} }}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: "40px", xxl: "48px" },
            lineHeight: 1.5,
            color: '#fff',
          }}
        >
          From learning to earning
        </Typography>

        <Typography
          variant="h5"
          sx={{
            mt: 2,
            color: '#fff',
            fontWeight: 500, 
            //fontSize: "21px", 
            lineHeight: "28px", 
            letterSpacing: "normal", 
            px: { xs: 2, xxl: 0 }
          }}
        >
          Your gateway to smarter investments, stronger connections, 
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: '#fff',
            fontWeight: 500, 
            fontSize: "21px", 
            lineHeight: "28px", 
            letterSpacing: "normal", 
          }}
        >
          and a brighter future
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: "column", xxl: "row" }, gap: 2, mb: { xs: 0, xxl: 6 }, mt: { xs: 3, xxl: 4 } }}>
          <Button
            href="/contact"
            variant="contained"
            sx={{
              width: { xs: "100%", sm: "100%", md: "245px" },
              fontSize: '18px',
              fontWeight: 'bold',
              backgroundColor: '#fff',
              borderRadius: '16px',
              textTransform: "none",
              color: '#1a2b4c',
              '&:hover': { backgroundColor: '#f0f0f0' },
            }}
          >
            Let’s talk growth
          </Button>
          <Button 
            href="/ourculture"
            variant="outlined"
            sx={{
              width: { xs: "100%", sm: "50%", md: "245px" },
              fontSize: '18px',
              fontWeight: 'bold',
              borderColor: '#fff',
              borderRadius: '16px',
              textTransform: "none",
              color: '#fff',
              '&:hover': { borderColor: '#fff', color: '#f0f0f0' },
            }}
          >
            Find out more
          </Button>
        </Box>

        <Box
          sx={{
            mb: 4,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: 686,
            aspectRatio: '16 / 9',
            borderRadius: 4,
            overflow: 'hidden',
            transform: 'translateY(30px)',
            boxShadow: "0px 8px 24px -2px rgba(0, 0, 0, 0.5)",
          }}
        >
            <video
              id="intro-video"
              width="100%"
              height="100%"
              controls
              autoPlay
              muted={true}
              playsInline
            >
              <source src={introVideo} type="video/mp4" />
            </video>
        </Box>
      </Container>

      <Box sx={{ backgroundColor: '#fff', py: 4 }} >
        <Box width={{ xs: "100%", xxxl: "80%"}} margin="0 auto">
          <Typography
            variant="h4"
            sx={{
              mb: {xs: 2, xl: 4 },
              fontWeight: "bold", 
              color: '#189AB4',
            }}
          >
            Meet the Academillionaires: your guides to growth
          </Typography>

            <Box width={{ xs: "100%", md: "100%", lg: "90%", xl: "70%", xxxl: "70%"}} 
              sx={{  
                paddingLeft: { xs: "10px", xxl: "0px" }, 
                margin: '0 auto', 
              }}
            >
              <Slider key={authors.length} {...authorsCarouselSettings}>
                {authors.map((author) => (
                  <Box
                    key={author.id}
                    sx={{
                      width: "156px",
                      borderRadius: 2,
                      textAlign: 'center',
                      position: 'relative',
                      px: "10px",
                    }}
                  >
                    <Box
                      component="img"
                      src={author.pictureUrl}
                      alt={author.name}
                      sx={{
                        width: "100%",
                        height: {xs: 269, xxl: 294},
                        objectFit: 'cover',
                        borderRadius: 5
                      }}
                    />
                    <Typography variant="body1"
                       sx={{
                        mt: 2,
                        color: "#0A435E",
                        textTransform: "none",
                        fontWeight: "700", 
                      }}
                    >
                      {author.name}
                    </Typography>
                    <Typography variant="body2"
                      sx={{
                        mt: 1,
                        color: "#0A435E",
                        textTransform: "none",
                        fontWeight: "100", 
                      }}
                    >
                      {author.expertise}
                    </Typography>
                  </Box>
                ))}
              </Slider>
            </Box>
            
            <Box
              sx={{
                width: { xs: "100%", xl: "70%"},
                margin: "0 auto",
                py: {xs: 2, xl: 6},
                px: 3,
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                gap: 4,
              }}
            >
              <Box sx={{ flex: 1, textAlign: "left" }}>
                <Typography
                  variant="h4"
                  sx={{
                    mb: 4,
                    color: "#189AB4",
                    textTransform: "none",
                    fontWeight: "bold", 
                    maxWidth: { xl: "400px" },
                  }}
                >
                  Why you’ll love being part of our community
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 4,
                    color: "#000",
                    textTransform: "none",
                    fontWeight: "300", 
                    maxWidth: { xl: "400px" },
                    margin: { xs: "0 auto", xl: "0 0" }
                  }}
                >
                  Step into a world of ambition, growth, and meaningful connections designed
                  for people like you who aspire to live, learn, and invest smarter.
                </Typography>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)", 
                  gap: { xs: 3, xl: 6 },
                  marginLeft: { xs: "20px" },
                  marginRight: { xs: "20px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      padding: 1.3,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.18)"
                    }}
                  >
                    <img
                      src={iconCommunityLearn}
                      alt="Learn from Industry Leaders"
                      style={{ width: 45, height: 45 }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      color: "#000",
                      textTransform: "none",
                      textAlign: "left",
                      fontWeight: "700", 
                      fontSize: "12px", 
                      lineHeight: "14px",
                      letterSpacing: "normal", 
                    }}
                  >
                    Learn from Industry Leaders
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      padding: 1.3,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.18)"
                    }}
                  >
                    <img
                      src={iconCommunityAccess}
                      alt="Access Exclusive Opportunities"
                      style={{ width: 45, height: 45 }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      color: "#000",
                      textTransform: "none",
                      textAlign: "left",
                      fontWeight: "700", 
                      fontSize: "12px", 
                      lineHeight: "14px",
                      letterSpacing: "normal", 
                    }}
                  >
                    Access Exclusive Opportunities
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      padding: 1.3,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.18)"
                    }}
                  >
                    <img
                      src={iconCommunityGrow}
                      alt="Grow with a Like-Minded Community"
                      style={{ width: 45, height: 45 }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      color: "#000",
                      textTransform: "none",
                      textAlign: "left",
                      fontWeight: "700", 
                      fontSize: "12px", 
                      lineHeight: "14px",
                      letterSpacing: "normal", 
                    }}
                  >
                    Grow with a Like-Minded Community
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      padding: 1.3,
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.18)"
                    }}
                  >
                    <img
                      src={iconCommunityBuild}
                      alt="Build Your Financial Freedom"
                      style={{ width: 45, height: 45 }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      color: "#000",
                      textTransform: "none",
                      textAlign: "left",
                      fontWeight: "700", 
                      fontSize: "12px", 
                      lineHeight: "14px",
                      letterSpacing: "normal", 
                    }}
                  >
                    Build Your Financial Freedom
                  </Typography>
                </Box>
              </Box>
            </Box>
        </Box>
      </Box>
    </Box>
    
    <Box>
      <Box
        className="oportunities-slider-container"
        sx={{
          position: "relative",
          height: { xs: "264px", xxl: "470px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${vehiclesBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 1,
          }}
        />
        <Box sx={{ position: "relative", zIndex: 2, width: "100%" }}>
          <Typography
            variant="h4"
            textAlign="center"
            fontWeight="bold"
            sx={{
              mb: 4,
              fontWeight: "bold", 
              color: '#0A435E',
            }}
          >
            Vehicles for building wealth
          </Typography>

          <Box sx={{ maxWidth: { xs: "100%", xxl: "87%" }, margin: "0 auto" }}>
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              spaceBetween={20}
              loop={true}
              slidesPerView={5} 
              breakpoints={{
                640: {
                  slidesPerView: 5, 
                },
                1024: {
                  slidesPerView: 5,
                },
                1440: {
                  slidesPerView: 5,
                },
              }}
              navigation={{
                prevEl: ".custom-prev",
                nextEl: ".custom-next",
              }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 180,
                modifier: 1.5,
                slideShadows: false,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
            >
              {vehicles.map((item, index) => (
                <SwiperSlide
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "16px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      marginLeft: { xs: "-40px", xxl: "0" },
                      width: { xs: "148px", xxl: "331px" },
                      height: { xs: "148px", xxl: "331px" },
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <Typography
                      variant="h4"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        color: "#fff",
                        textAlign: "center",
                        px: { xs: "5px", xxl: "8px" },
                        py: "10px",
                        fontSize: { xs: "12px", xxl: "28px" }, 
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>

            <Box
              sx={{
                display: { xs: "none", xxl: "block"}
              }}
            >
              <Box
                className="custom-prev"
                sx={{
                  fontSize: "55px",
                  position: "absolute",
                  top: "45%",
                  left: { xs: "25px", xxl: "300px" },
                  zIndex: 10,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                &#8249; 
              </Box>
              <Box
                className="custom-next"
                sx={{
                  fontSize: "55px",
                  position: "absolute",
                  top: "45%",
                  right: { xs: "25px", xxl: "300px" },
                  zIndex: 10,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                &#8250; 
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ py: { xs: 2, xxl: 6 }, px: 2, backgroundColor: "#fff" }}>
        <Typography variant="h4" align="center"
          sx={{
            mb: { xs: 0, xxl: 4 },
            fontWeight: "bold", 
            color: '#189AB4',
          }}
        >
          More than a club.. it's a way of life!
        </Typography>
        <Stack spacing={{ xs: 0, xl: 2, xxl: 4 }} width={{ xs: "100%", md: "100%", xl: "100%", xxl: "70%" }} style={{ margin: "0 auto"}}>
          {activities.reduce((rows, activity, index) => {
            if (index % 2 === 0) rows.push([]);
            rows[rows.length - 1].push(activity);
            return rows;
          }, []).map((row, rowIndex) => (
            <Box
              key={rowIndex}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "column", lg: "column", xl: "row" },
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0px"
              }}
            >
              {row.map((activity, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "column", xl: "row" },
                    alignItems: "center",
                    textAlign: { xs: "center", xl: "left" },
                    width: { xs: "100%", md: "60%" },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={activity.image}
                    alt={activity.title}
                    sx={{
                      borderRadius: 2,
                      objectFit: "cover",
                      width: { xs: "100%", xl: "80%" }
                    }}
                  />
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#189AB4",
                        fontWeight: "bold",
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      {activity.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {activity.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Stack>
      </Box>

      <div
        data-tf-live="01JE6Z43SEDZ7SHE5CSQF9HDG9?typeform-welcome=0" 
        data-tf-inline-on-mobile="true"
        style={{ width: '100%', height: '500px', marginBottom: '80px' }}
      ></div>

      <Box
        sx={{
          display: "flex", 
          justifyContent: "center",
          alignItems: "center", 
          backgroundColor: "#ffffff",
          py: { xs: 5, xxl: 6 },
          px: { xs: 2, xxl: 2 },
          textAlign: "center",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 0, xxl: 4 }}
          sx={{
            width: { xs: "100%", xxl: "70%" },
            flexWrap: "wrap", 
          }}
          gap={4}
        >
          {stats.map((stat, index) => (
            <Box
              key={index}
              sx={{
                textAlign: "center",
                flex: { xs: "1 1 50%", xxl: "1 1 20%" },
                maxWidth: {xs: "40%", xxl: "20%"},
                mb: { xs: 0, xxl: 3 },
                direction: { xs: "column", xxl: "row" }
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold", 
                  letterSpacing: "normal", 
                  color: '#189AB4',
                  mb: { xs: 2, xxl: 2 },
                }}
              >
                {stat.number}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#000",
                  fontWeight: "300", 
                  letterSpacing: "normal", 
                }}
              >
                {stat.description}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={customDivider}
          alt="Custom Divider"
          style={{
            width: "100%",
            maxHeight: "2px",
            objectFit: "cover",
          }}
        />
      </Box>

      <Box sx={{ py: { xs: 2, xxl: 6 }, textAlign: "center", backgroundColor: "#fff" }}>
        <Typography
          variant="h4"
          sx={{
            mb: 4,
            fontWeight: "bold", 
            letterSpacing: "normal", 
            color: '#189AB4',
          }}
        >
          Hear from our members
        </Typography>

        <Box
          sx={{
            display: "flex", 
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", xxl: "70%" },
              margin: "0 auto",
              paddingLeft: 2
            }}
          >
            <Swiper
              direction="horizontal"
              spaceBetween={20}
              slidesPerView="auto"
              loop={true}
              style={{
                width: "100%", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center",
              }}
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    width: "156px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    transition: "transform 0.3s ease-in-out",
                  }}
                >
                  <video
                    src={testimonial.video}
                    poster={testimonial.thumbnail}
                    controls
                    style={{
                      width: "100%",
                      height: "326px",
                      objectFit: "cover",
                      borderRadius: "16px",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
        
        <Box
          sx={{
            position: "relative",
            overflow: "hidden", 
            width: "100%", 
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", xxl: "70%" }, 
              margin: "0 auto", 
              paddingTop: "40px", 
              paddingLeft: { xs: "5px", xxl: "15px" },
              paddingRight: { xs: "0px", xxl: "15px" },
            }}
          >
          <Swiper
            pagination={{
              clickable: true,
              bulletClass: "swiper-pagination-bullet", 
              bulletActiveClass: "custom-swiper-pagination-bullet-active", 
            }}
            modules={[Pagination]}
            slidesPerView={3}
            spaceBetween={20}
            breakpoints={{
              0: { slidesPerView: 1.5, spaceBetween: 20 },
              500: { slidesPerView: 3, spaceBetween: 30 },
            }}
            style={{
              paddingBottom: "40px"
            }}
          >
            {textTestimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <Card
                  sx={{
                    p: { xs: 2, xxl: 3 }, 
                    borderRadius: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "#F2F2F2",
                    textAlign: "left", 
                    width: "80%",
                    maxWidth: { xs: "80%", xxl: "100%" }, 
                    margin: "0 auto", 
                    position: "relative", 
                  }}
                >
                  <Avatar
                    src={testimonial.avatar}
                    alt={testimonial.name}
                    sx={{
                      width: 80, 
                      height: 80,
                      borderRadius: "16px", 
                      position: "absolute",
                      left: "-20px", 
                      zIndex: 1, 
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
                    }}
                  />
                  <Box sx={{ ml: 8 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#000",
                      }}
                    >
                      {testimonial.text}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "#189AB4",
                        mt: 4,
                      }}
                    >
                      {testimonial.name}
                    </Typography>
                  </Box>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
          </Box>
        </Box>
      </Box>

    <Box sx={{ py: { xs: 2, xxl: 6 }, backgroundColor: "#F4F4F4", textAlign: "center" }}>
      <Typography variant="h4"
        sx={{
          mb: { xs: 2, xxl: 3 },
          fontWeight: "bold", 
          color: '#189AB4',
          px: 2
        }}
      >
        Choose your growth plan
      </Typography>
      <Typography variant="body1" 
        sx={{
          px: 2, 
          mb: { xs: 2, xxl: 3 },
          color: "#000",
          textTransform: "none",
          fontWeight: "300", 
        }}
      >
        With each package, gain access to curated opportunities for personal development, financial growth, and lasting connections in a vibrant community.
      </Typography>

      {/* Membership Plans */}
      <Box 
        ref={scrollContainerRef}
        sx={{ 
          width: "100%",  
          overflowX: "auto", 
          display: "flex", 
          justifyContent: { xs: "flex-start", xxl: "center" }, 
          alignItems: "center",
          scrollbarWidth: "none", 
          "&::-webkit-scrollbar": { display: "none" }, 
          scrollSnapType: "x mandatory", 
          padding: "0 16px", 
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          spacing={{ xs: 1, xxl: 3 }}
          sx={{
            display: "inline-flex",
            flexWrap: "nowrap",
            minWidth: "max-content", 
            margin: "0 auto", 
            paddingLeft: { xs: "calc(30vw - 150px)", xxl: "0px" }, 
          }}
        >
          {memberships.map((membership, index) => (
            <Box
              key={index}
              sx={{
                marginTop: membership.recommended ? "0px" : "60px"
              }}
            >
              <Card
                key={`${membership.id}-${index}`}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "16px",
                  width: `${membership.recommended ? 300 : 265}px`,
                  border: `${membership.recommended ? 3 : 1}px solid`,
                  borderColor: membership.color,
                  mt: `${membership.recommended ? 0 : 60}px`,
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  overflow: "hidden",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: membership.backgroundColor,
                    color: membership.color,
                    padding: "24px 16px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      border: "0px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      color: "#fff",
                    }}
                  >
                    {membership.title}
                  </Typography>
                </Box>
                {membership.recommended && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-1px",
                      right: "-5px",
                      width: "130px",
                      height: "80px",
                      backgroundImage: `url(${recommendedBadge})`,
                      backgroundSize: "cover",
                      color: "#ffffff",
                      transform: "rotate(0deg)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "20px",
                        right: "15px",
                        transform: "rotate(30deg)",
                        height: "40px",
                        fontSize: "11px",
                        fontWeight: "bold",
                      }}
                    >
                      RECOMMENDED
                    </Typography>
                  </Box>
                )}
                <CardContent>
                  <Stack spacing={2} sx={{ mt: 2}}>
                    {membership.features.map((feature, idx) => (
                      <React.Fragment key={idx}>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
                          <CheckCircleOutlineIcon
                            sx={{ color: membership.backgroundColor, fontSize: "18px" }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#5A5A5A",
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "1.5",
                            }}
                            dangerouslySetInnerHTML={{ __html: feature }}
                          >
                          </Typography>
                        </Box>
                        {idx < membership.features.length - 1 && (
                          <Divider sx={{ my: 1, borderColor: "#E0E0E0" }} />
                        )}
                      </React.Fragment>
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Stack>
      </Box>

      <Button
        variant="contained"
        fullWidth={false}
        href="/contact"
        sx={{
          mt: 4,
          px: { xs: 2, sm: 4 },
          py: { xs: 0, sm: 2 },
          backgroundColor: "#0A435E",
          color: "#74E6DC",
          borderRadius: "24px",
          textTransform: "none",
          fontWeight: "700", 
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, 
          lineHeight: "14px",
          letterSpacing: "normal",
          "&:hover": {
            backgroundColor: "#189AB4",
            color: "#fff",
          },
        }}
      >
        Get in Touch
      </Button>
    </Box>

    <Box sx={{ py: { xs: 2, xxl: 6 }, px: 1, backgroundColor: "#fff", textAlign: "center"}}>
      <Typography variant="h4"
        sx={{
          mb: { xs: 2, xxl: 4 },
          fontWeight: "bold", 
          color: '#189AB4',
        }}
      >
        Our Promise to You
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        gap={{ xs: 0, xxl: 4 }}
        sx={{  width: { xs: "100%", xxl: "70%" }, margin: "0 auto" }}
      >
        {promises.map((promise, index) => (
          <Box
            key={`${promise.id}-${index}`}
            sx={{
              display: "flex",
              flexDirection: {xs: "row", lg: "row"},
              alignItems: "center",
              textAlign: "left",
              gap: { xs: 1, xxl: 2 },
              width:{
                xs: "50%",
                xxl: "25%"
              },
              p: 2,
              borderRadius: "12px",
              margin: "0 auto"
            }}
          >
            <Box
              sx={{
                width: "65px",
                height: "65px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F6F6F6",
                borderRadius: "12px",
                boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.18)"
              }}
            >
              <img
                src={promise.icon}
                alt={promise.title}
                style={{
                  width: "45px",
                  height: "45px",
                  objectFit: "contain",
                  padding: "3px 3px"
                }}
              />
            </Box>

            <Typography
              variant="body2"
              sx={{
                mt: 2,
                color: "#000",
                textAlign: "left",
                fontWeight: "700", 
              }}
            >
              {promise.title}
            </Typography>
          </Box>
        ))}
      </Stack>

      <Button
        variant="contained"
        fullWidth={false} 
        href="/contact"
        sx={{
          mt: 4,
          px: { xs: 2, sm: 4 }, 
          py: { xs: 1.5, sm: 2 },
          backgroundColor: "#0A435E",
          color: "#74E6DC",
          borderRadius: "24px",
          textTransform: "none",
          fontWeight: "700", 
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, 
          lineHeight: "14px",
          letterSpacing: "normal",
          "&:hover": {
            backgroundColor: "#189AB4",
            color: "#fff",
          },
        }}
      >
        Take the next step
      </Button>
    </Box>

    <Box sx={{ py: { xs: 2, xxl: 6 }, px: 2, backgroundColor: "#F4F4F4", textAlign: "center"}}>
      <Typography
        variant="h4"
        sx={{
          mb: { xs: 2, xxl: 4 },
          fontWeight: "bold", 
          color: '#189AB4',
        }}
      >
        Got Questions? We’ve Got Answers
      </Typography>

      <Box 
        sx={{ 
          width: { xs: "100%", xxl: "68%" }, 
          margin: "0 auto", 
          borderRadius: "20px",
          overflow: "hidden", 
        }}
      >
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={activeIndex === index}
            onChange={() => handleAccordionChange(index)}
            sx={{
              borderRadius: index === 0 ? "20px 20px 20px 20px" : index === faqs.length - 1 ? "20px 20px 20px 20px" : "20px",
              mb: 2,
              overflow: "hidden",
              margin: "5px 0",
              fontSize: "16px", 
                lineHeight: "24px",
            }}
          >
            <AccordionSummary
              sx={{
                backgroundColor: activeIndex === index ? "#189AB4" : "#D9D9D9",
                color: activeIndex === index ? "#FFFFFF" : "#000",
                fontSize: "16px", 
                lineHeight: "24px",
                fontWeight: "bold",
                borderRadius: "inherit",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                padding: 0,
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  padding: 0,
                  justifyContent: "center",
                },
              }}
            >
              {faq.question}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#5A5A5A",
                py: 2,
                px: { xs: 2, xxl: 2 },
              }}
            >
              {faq.answer.map((line, idx) => (
                <Typography 
                  key={idx}
                  sx={{
                    color: "#000",
                    textTransform: "none",
                    fontWeight: "300", 
                    fontSize: "12px", 
                    lineHeight: "16px",
                    letterSpacing: "normal", 
                  }}
                  dangerouslySetInnerHTML={{ __html: line }}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>

    <Box
      sx={{
        py: { xs: 2, xxl: 4 },
        px: 6,
        backgroundColor: "#0A435E", 
        color: "#FFFFFF",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", xxl: "70%" }, 
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", xxl: "row" },
          gap: { xs: 2, xxl: 4 },
        }}
      >
        {/* Left Section */}
        <Box sx={{ flex: 1.3, minWidth: "200px", textAlign: { xs: "center", md: "center", lg: "left", xl: "left" } }}>
          <Typography
            variant="h5"
            sx={{ 
              fontWeight: "bold", 
              mb: 1, 
              color: "#74E6DC" 
            }}
          >
            Newsletter Sign Up
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
          Stay inspired and informed! Subscribe to our newsletter for exclusive tips, insights, and updates to fuel your growth journey.
          </Typography>
        </Box>

        <Stack
          direction={{ xs: "column", xxl: "column" }}
          spacing={2}
          sx={{
            flex: { xs: 6, xxl: 1.8 }, 
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "100%"}
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Name"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "50px",
              borderColor: "#74E6DC",
              borderWidth: 2,
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
            }}
          />
          <TextField
            variant="outlined"
            placeholder="E-mail"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "50px",
              borderColor: "#74E6DC",
              borderWidth: 2,
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
            }}
          />
        </Stack>

        <Button
          variant="contained"
          sx={{
            borderRadius: "50px",
            backgroundColor: "#74E6DC",
            color: "#0A435E",
            textTransform: "none",
            fontWeight: "bold",
            px: 6,
            "&:hover": {
              backgroundColor: "#5DC4B4",
            },
          }}
        >
          Sign Up
        </Button>

        {/* Right Section */}
        <Box
          sx={{
            flex: 1,
            minWidth: "200px",
            textAlign: "center",
          }}
        >
          <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
            You will receive our <strong style={{ color: "#FFFFFF" }}>FREE</strong>{" "}
            <br />
            <strong style={{ color: "#74E6DC" }}>BUDGETING TEMPLATE</strong> <br />
            for you and your business.
          </Typography>
        </Box>
      </Box>
    </Box>

    <Box sx={{ py: { xs: 3, xxl: 6 }, px: { xs: "10px", xxl: "0" },  textAlign: "center", width: "100%", backgroundColor: "#fff" }}>
      <Typography
        variant="h4"
        sx={{
          mb: { xs: 2, xxl: 4 },
          fontWeight: "bold", 
          color: '#189AB4',
        }}
      >
        Coming Soon Blog Posts
      </Typography>

      {/* Blog Posts */}
      <Box
        sx={{
          display: { xs: "none", xxl: "flex" },
          flexWrap: "wrap",
          gap: 4,
          justifyContent: "center",
          maxWidth: "67%",
          margin: "0 auto",
        }}
      >
        {blogPosts.map((post) => (
          <Box
            key={post.id}
            sx={{
              flex: "1 1 calc(20% - 16px)", 
              width: "242px",
              borderRadius: "16px",
              overflow: "hidden",
              border: "1px solid #189AB4",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Card
              sx={{
                borderRadius: "16px",
                overflow: "hidden",
                height: "100%",
              }}
            >
              <CardMedia
                component="img"
                height="150"
                image={post.image}
                alt={post.title}
                sx={{
                  borderRadius: "0px",
                  margin: 0
                }}
              />
              <CardContent sx={{ textAlign: "left", p: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <CalendarTodayIcon sx={{ fontSize: "16px", color: "#B7B7B7" }} />
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "#B7B7B7" }}
                    >
                      {post.date}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <PersonIcon sx={{ fontSize: "16px", color: "#B7B7B7" }} />
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "#B7B7B7" }}
                    >
                      {post.author}
                    </Typography>
                  </Box>
                </Stack>
                <Typography
                  variant="h4"
                  sx={{
                    mt: 1,
                    color: "#189AB4",
                    textTransform: "none",
                    fontWeight: "bold", 
                    fontSize: "20px", 
                    lineHeight: "20px",
                    letterSpacing: "normal", 
                  }}

                >
                  {post.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    color: "#000",
                    textTransform: "none",
                    fontWeight: "300", 
                    fontSize: "14px", 
                    lineHeight: "14px",
                    letterSpacing: "normal", 
                  }}
                >
                  {post.excerpt}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>

      {/* Mobile Slider */}
      <Box sx={{ display: { xs: "block", xxl: "none" } }}>
        <Slider {...mobileCarouselSettings}>
          {blogPosts.map((post) => (
            <Box
              key={post.id}
              sx={{
                width: "50%",
                borderRadius: "16px",
                overflow: "hidden",
                border: "1px solid #189AB4",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                margin: "10px 0px",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  borderRadius: "16px",
                  overflow: "hidden",
                }}
              >
                <CardMedia
                  component="img"
                  height="139"
                  image={post.image}
                  alt={post.title}
                  sx={{
                    borderRadius: "0px",
                    margin: 0,
                  }}
                />
                <CardContent sx={{ textAlign: "left", p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CalendarTodayIcon sx={{ fontSize: "12px", color: "#B7B7B7" }} />
                      <Typography
                        variant="body3"
                        sx={{ color: "#B7B7B7" }}
                      >
                        {post.date}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <PersonIcon sx={{ color: "#B7B7B7" }} />
                      <Typography
                        variant="body3"
                        sx={{ fontSize: "12px", color: "#B7B7B7" }}
                      >
                        {post.author}
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 1,
                      color: "#189AB4",
                      textTransform: "none",
                      fontWeight: "bold",
                      letterSpacing: "normal",
                    }}
                  >
                    {post.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      color: "#000",
                      textTransform: "none",
                      fontWeight: "300",
                      letterSpacing: "normal",
                    }}
                  >
                    {post.excerpt}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>

      <Button
        variant="contained"
        fullWidth={false}
        href="/blogs"
        sx={{
          mt: 4,
          px: { xs: 2, sm: 4 }, 
          py: { xs: 1.5, sm: 2 },
          backgroundColor: "#0A435E",
          color: "#74E6DC",
          borderRadius: "24px",
          textTransform: "none",
          fontWeight: "700", 
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, 
          lineHeight: "14px",
          letterSpacing: "normal",
          "&:hover": {
            backgroundColor: "#189AB4",
            color: "#fff",
          },
        }}
      >
        See All
      </Button>
    </Box>

    <Footer/>
    </Box>
    </>
  );
};

export default OurClub;