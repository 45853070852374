import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Paper, List, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';

const MarketingPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        const fetchSubmissions = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/contact/submissions`);
                setSubmissions(response.data);
            } catch (error) {
                console.error('Error fetching submissions:', error);
            }
        };

        fetchSubmissions();
    }, []);

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Typography variant="h4" gutterBottom>
                Contact Form Submissions
            </Typography>
            <Paper sx={{ p: 3 }}>
                <List>
                    {submissions.map((submission) => (
                        <ListItem key={submission.id} divider>
                            <ListItemText
                                primary={`${submission.firstName} ${submission.lastName}`}
                                secondary={`Email: ${submission.email}, Phone: ${submission.phone}, Location: ${submission.state}, ${submission.country}, Submitted At: ${new Date(submission.submittedAt).toLocaleString()}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Container>
    );
};

export default MarketingPage;
