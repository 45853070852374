import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, List, ListItem, ListItemText, Button, CircularProgress, AppBar, Toolbar, Tabs, Tab } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import VideoPlayer from "../../components/VideoPlayer";
import { getVideoDuration, formatDuration, calculateTotalDuration } from "../../components/VideoUtils";
import DOMPurify from 'dompurify';

import PersonIcon from '@mui/icons-material/Person';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CoursePage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const { id } = useParams();
  const currentUserId = localStorage.getItem('userId') || sessionStorage.getItem('userId');

  const [course, setCourse] = useState({
    memberships: [],
    lessons: [],
    authors: [],
    resources: [],
  });
  const [playingPreview, setPlayingPreview] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoProgress, setVideoProgress] = useState({});
  const [completedLessons, setCompletedLessons] = useState(new Set());
  const [totalDuration, setTotalDuration] = useState("");

  const [activeTab, setActiveTab] = useState(1);
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
  };

  const navigate = useNavigate();

  const handleLessonClick = (lessonId) => {
    navigate(`/mycourses/course/${id}/lesson/${lessonId}`);
  };

  // Function to handle video progress
  const handleVideoProgress = (progress) => {
    if (selectedLesson) {
      setVideoProgress((prev) => ({
        ...prev,
        [selectedLesson.id]: progress.playedSeconds,
      }));
    }
  };

  const handleDownloadLessonResources = async () => {
    if (!selectedLesson?.resources || selectedLesson.resources.length === 0) return;
    
    for (const resource of selectedLesson.resources) {
      try {
        const response = await axios.get(resource.url, { responseType: "blob" });
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = resource.name || "resource";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading resource:", error);
      }
    }
  };

  const handleDownloadCourseResources = async () => {
    if (!course.lessons) return;

    // Collect all resources across all lessons
    const allResources = course.lessons.flatMap(lesson => lesson.resources || []);
    if (allResources.length === 0) return;

    for (const resource of allResources) {
      try {
        const response = await axios.get(resource.url, { responseType: "blob" });
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = resource.name || "resource";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading course resource:", error);
      }
    }
  };

  // Mark lesson as completed (manual button click)
  const handleMarkAsComplete = async () => {
    if (!currentUserId || !selectedLesson.id) return;

    try {
      await axios.post(`${API_BASE_URL}/api/videoprogress/complete`, {
        memberId: currentUserId,
        lessonId: selectedLesson.id,
        completed: true,
      });

      setCompletedLessons(prev => new Set([...prev, selectedLesson.id]));
    } catch (error) {
      console.error("Error marking lesson as complete:", error);
    }
  };

  // Fetch course details and calculate durations
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/api/courses/course/${id}`);
        const lessonsWithDuration = await Promise.all(
          response.data.lessons.map(async (lesson) => {
            try {
              const duration = await getVideoDuration(lesson.videoUrl);
              return { ...lesson, duration: formatDuration(duration) };
            } catch {
              return { ...lesson, duration: "Unknown" };
            }
          })
        );

        setCourse({ ...response.data, lessons: lessonsWithDuration });
        setTotalDuration(calculateTotalDuration(lessonsWithDuration));
      } catch (err) {
        console.error("Error fetching course:", err);
        setError("Failed to load course details. Please refresh the page.");
      } finally {
        setLoading(false);
      }
    };

    const fetchCompletedLessons = async () => {
      if (!currentUserId) return;
  
      try {
          const response = await axios.get(`${API_BASE_URL}/api/videoprogress/completed-lessons?memberId=${currentUserId}`);
          const data = response.data;

          if (data.completedLessons) {
              setCompletedLessons(new Set(data.completedLessons));
          }
      } catch (error) {
          console.error("Error fetching completed lessons:", error);
      }
    };
  
    
    fetchCourse();
    fetchCompletedLessons();
  }, [id]);

  const totalLessons = course.lessons.length;
  const viewedLessons = completedLessons?.size ?? 0;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        color: "#000",
        minHeight: "100vh",
        display: "flex",
        flexDirection: { xs: "column", xxl: "row" },
        justifyContent: "space-between",
        padding: 2,
        gap: 2,
        py: { xs: 0, xxl: 2 },
        px: { xs: 0, xxl: 11 }
      }}
    >
      {loading ? (
        <Box
          sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
          }}
        >
          <CircularProgress style={{ color: "#189AB4" }} />
        </Box>
      ) : (
        <>
          {/* Left Section: Video and Details */}
          <Box sx={{ flex: 4, minWidth: { xs: "100%", xxl: "70%" } }}>
            {selectedLesson ? (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  px: 2,
                }}
              >
                <VideoPlayer
                  blobUrl={selectedLesson.videoUrl}
                  trackProgress={true}
                  userId={currentUserId}
                  videoId={selectedLesson.id}
                  //onProgress={handleVideoProgress}
                  onComplete={() => handleMarkAsComplete()}
                />

                <Typography variant="h4" gutterBottom 
                  sx={{ 
                    fontSize: { xs: "20px", xxl: "28px" },
                    color: "#0A435E", 
                    textAlign: { xs: "left", xll: "center" },
                    marginTop: { xs: 2, xxl: 4 }
                  }}
                >
                  {selectedLesson.title}
                </Typography>

                {/* Render WYSIWYG Description */}
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{marginTop: { xs: 2, xxl: 4 }}}
                  dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(selectedLesson.description || ''),
                  }}
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  {selectedLesson.resources?.length > 0 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ 
                        display: { xs: "none", xxl: "block" },
                        marginTop: 2, 
                        color: "#fff" 
                      }}
                      onClick={handleDownloadLessonResources}
                    >
                      Download Resources
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2, color: "#fff" }}
                    onClick={handleMarkAsComplete}
                    disabled={completedLessons?.has(selectedLesson?.id) || false}
                    //onClick={() => setCompletedLessons(prev => new Set(prev.add(selectedLesson.id)))}
                  >
                    {completedLessons?.has(selectedLesson?.id) ? "Completed" : "Mark as Complete"}
                  </Button>
                </Box>
                
                <AppBar position="sticky" color="default"
                    sx={{
                    display: { xs: "block", xxl: "none" }
                    }}
                >
                <Toolbar sx={{ borderBottom: '1px solid #ddd' }}>
                    <Tabs 
                        value={activeTab} 
                        onChange={handleTabChange} 
                        sx={{ width: '100%', justifyContent: 'space-between' }}
                    >
                        <Tab label="Overview" to="overview" offset={-80} smooth duration={500} spy activeClass="active-tab" /> 
                        <Tab label="Lessons" to="lessons" offset={-80} smooth duration={500} spy activeClass="active-tab" /> 
                        <Tab label="Resources" to="resources" offset={-80} smooth duration={500} spy activeClass="active-tab" /> 
                    </Tabs>
                </Toolbar>
                </AppBar>

                {/* Tab Content */}
                {activeTab === 0 && (
                <Typography
                    variant="body1"
                    mt={4}
                    gutterBottom
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(course.description || ''),
                    }}
                    sx={{
                    display: { xs: "block", xxl: "none" }
                    }}
                />
                )}
                {activeTab === 1 && (
                <Box sx={{ mt: 2, display: { xs: "block", xxl: "none" } }}>
                    {course.lessons.length > 0 ? (
                    <List sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto", padding: 0 }}>
                        {course.lessons.map((lesson, index) => (
                        <ListItem
                          key={`${lesson.id}-${index}`}
                          //onClick={() => setSelectedLesson(lesson)}
                          onClick={() => handleLessonClick(lesson.id)}
                          sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#f0f0f0",
                              borderRadius: 2,
                              py: 1.5,
                              px: 1.5,
                              marginBottom: 1.5,
                              cursor: "pointer",
                              "&:hover": {
                              backgroundColor: "#e0e0e0",
                              },
                          }}
                        >
                        <Box
                            component="img"
                            src={lesson.thumbnailUrl || course.thumbnailUrl}
                            alt={lesson.title}
                            sx={{
                            width: 150,
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: 2,
                            marginRight: 2,
                            }}
                        />
                        <ListItemText
                            primary={
                            <Typography
                                variant="body2"
                                sx={{ 
                                fontSize: "11px",
                                color: "#189AB4" 
                                }}
                            >
                                LESSON {lesson.order}
                            </Typography>
                            }
                            secondary={
                            <>
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: "#000",
                                }}
                            >
                                {lesson.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ 
                                fontSize: "11px", 
                                color: "#189AB4" 
                                }}
                            >
                                {lesson.duration || "Unknown"} minutes
                            </Typography>
                            </>
                            }
                        />
                        {completedLessons?.has(lesson.id) && <CheckCircleIcon sx={{ width: "16px", height: "16px", marginTop: "60px", color: "#0A435E" }} />}
                        </ListItem>
                        ))}
                    </List>
                    ) : (
                    <Typography variant="body1" color="#aaa" mt={2}>No lessons available for this course.</Typography>
                    )}
                </Box>
                )}

                {activeTab === 2 && (
                <Box sx={{ mt: 2, display: { xs: "block", xxl: "none" } }}>
                    {course.lessons.some(lesson => lesson.resources?.length > 0) ? (
                    <>
                        <Typography>
                        Ai găsit raftul cu unelte de creștere! Aici sunt resursele care te ajută să-ți clarifici planurile și să-ți accelerezi rezultatele. Descarcă, aplică, evoluează!
                        </Typography>
                        <Button
                        variant="contained"
                        color="secondary"
                        sx={{ marginTop: 2, color: "#fff" }}
                        onClick={handleDownloadCourseResources}
                        >
                        Download Course Resources
                        </Button>
                    </>
                    ) : (
                    <Typography variant="body1" color="#aaa">
                        Știi faza aia când cauți ceva și descoperi că era la tine tot timpul? Exact! Cele mai tari resurse sunt deja la tine. Inspirația, curajul și dorința de a acționa. Noi doar îți amintim să le folosești.
                    </Typography>
                    )}
                </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  px: 2,
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    aspectRatio: '16 / 9',
                    borderRadius: 4,
                    overflow: 'hidden',
                    boxShadow: "0px 8px 24px -2px rgba(0, 0, 0, 0.5)",
                    margin: "0 auto"
                  }}
                >
                  {playingPreview && course.previewVideoUrl ? (
                    <video
                      width="100%"
                      height="100%"
                      controls
                      autoPlay
                      onEnded={() => setPlayingPreview(false)} 
                    >
                      <source src={course.previewVideoUrl} type="video/mp4" />
                    </video>
                  ) : (
                    <>
                      {/* Thumbnail Image */}
                      <Box
                        component="img"
                        src={course.thumbnailUrl}
                        alt={course.title}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 4,
                          display: 'block',
                        }}
                      />

                      {/* Play Button (Only if preview video exists) */}
                      {course.previewVideoUrl && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: { xs: '50%', xxl: '90%' },
                            left: { xs: '50%', xxl: '6%' },
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 64,
                            height: 64,
                            borderRadius: '50%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            cursor: 'pointer',
                          }}
                          onClick={() => setPlayingPreview(true)} // Show the video player on click
                        >
                          <Box
                            component="span"
                            sx={{
                              width: 0,
                              height: 0,
                              borderLeft: '15px solid white',
                              borderTop: '10px solid transparent',
                              borderBottom: '10px solid transparent',
                            }}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Box>

                <Typography variant="h3" fontWeight="bold" gutterBottom 
                  sx={{ 
                    fontSize: { xs: "20px", xxl: "36px" },
                    color: "#0A435E", 
                    textAlign: "center",
                    marginTop: { xs: 2, xxl: 4 }
                  }}
                >
                  {course.title}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    marginTop: { xs: 2, xxl: 4 }
                  }}
                >
                  {course.authors.map((author) => (
                    <Typography
                      variant="h6"
                      sx={{ color: "#189AB4" }}
                      key={author.id}
                      onClick={() => navigate(`/authordetails/${author.id}`)}
                    >
                      <PersonIcon/> {author.name}
                    </Typography>
                  ))}
                  <Typography
                      variant="h6"
                      sx={{ color: "#189AB4" }}
                    >
                      <WatchLaterIcon/> {totalDuration} 
                  </Typography>
                </Box>

                <Typography
                  variant="body1"
                  mt={4}
                  gutterBottom
                  dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(course.description || ''),
                  }}
                  sx={{
                    display: { xs: "none", xxl: "block" }
                  }}
                />

                {course.lessons.some(lesson => lesson.resources?.length > 0) && (
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginTop: 2, color: "#fff", display: { xs: "none", xxl: "block" } }}
                    onClick={handleDownloadCourseResources}
                  >
                    Download Course Resources
                  </Button>
                )}

                <AppBar position="sticky" color="default"
                    sx={{
                    display: { xs: "block", xxl: "none" }
                    }}
                >
                <Toolbar sx={{ borderBottom: '1px solid #ddd' }}>
                    <Tabs 
                        value={activeTab} 
                        onChange={handleTabChange} 
                        sx={{ width: '100%', justifyContent: 'space-between' }}
                    >
                        <Tab label="Overview" to="overview" offset={-80} smooth duration={500} spy activeClass="active-tab" /> 
                        <Tab label="Lessons" to="lessons" offset={-80} smooth duration={500} spy activeClass="active-tab" /> 
                        <Tab label="Resources" to="resources" offset={-80} smooth duration={500} spy activeClass="active-tab" /> 
                    </Tabs>
                </Toolbar>
                </AppBar>

                {/* Tab Content */}
                {activeTab === 0 && (
                <Typography
                    variant="body1"
                    mt={4}
                    gutterBottom
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(course.description || ''),
                    }}
                    sx={{
                    display: { xs: "block", xxl: "none" }
                    }}
                />
                )}
                {activeTab === 1 && (
                <Box sx={{ mt: 2, display: { xs: "block", xxl: "none" } }}>
                    {course.lessons.length > 0 ? (
                    <List sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto", padding: 0 }}>
                        {course.lessons.map((lesson, index) => (
                        <ListItem
                          key={`${lesson.id}-${index}`}
                          //onClick={() => setSelectedLesson(lesson)}
                          onClick={() => handleLessonClick(lesson.id)}
                          sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#f0f0f0",
                              borderRadius: 2,
                              py: 1.5,
                              px: 1.5,
                              marginBottom: 1.5,
                              cursor: "pointer",
                              "&:hover": {
                              backgroundColor: "#e0e0e0",
                              },
                          }}
                        >
                        <Box
                            component="img"
                            src={lesson.thumbnailUrl || course.thumbnailUrl}
                            alt={lesson.title}
                            sx={{
                            width: 150,
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: 2,
                            marginRight: 2,
                            }}
                        />
                        <ListItemText
                            primary={
                            <Typography
                                variant="body2"
                                sx={{ 
                                fontSize: "11px",
                                color: "#189AB4" 
                                }}
                            >
                                LESSON {lesson.order}
                            </Typography>
                            }
                            secondary={
                            <>
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: "#000",
                                }}
                            >
                                {lesson.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ 
                                fontSize: "11px", 
                                color: "#189AB4" 
                                }}
                            >
                                {lesson.duration || "Unknown"} minutes
                            </Typography>
                            </>
                            }
                        />
                        {completedLessons?.has(lesson.id) && <CheckCircleIcon sx={{ width: "16px", height: "16px", marginTop: "60px", color: "#0A435E" }} />}
                        </ListItem>
                        ))}
                    </List>
                    ) : (
                    <Typography variant="body1" color="#aaa" mt={2}>No lessons available for this course.</Typography>
                    )}
                </Box>
                )}

                {activeTab === 2 && (
                <Box sx={{ mt: 2, display: { xs: "block", xxl: "none" } }}>
                    {course.lessons.some(lesson => lesson.resources?.length > 0) ? (
                    <>
                        <Typography>
                        Ai găsit raftul cu unelte de creștere! Aici sunt resursele care te ajută să-ți clarifici planurile și să-ți accelerezi rezultatele. Descarcă, aplică, evoluează!
                        </Typography>
                        <Button
                        variant="contained"
                        color="secondary"
                        sx={{ marginTop: 2, color: "#fff" }}
                        onClick={handleDownloadCourseResources}
                        >
                        Download Course Resources
                        </Button>
                    </>
                    ) : (
                    <Typography variant="body1" color="#aaa">
                        Știi faza aia când cauți ceva și descoperi că era la tine tot timpul? Exact! Cele mai tari resurse sunt deja la tine. Inspirația, curajul și dorința de a acționa. Noi doar îți amintim să le folosești.
                    </Typography>
                    )}
                </Box>
                )}
              </Box>
            )}
          </Box>
          

          {/* Right Section: Lessons */}
          <Box
            sx={{
              display: { xs: "none", xxl: "block" },
              flex: 1.5,
              minWidth: "30%",
              backgroundColor: "#f0f0f0",
              borderRadius: 2,
              padding: 2,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <Typography variant="h5" gutterBottom>
                Lessons
              </Typography>
              <Typography variant="h6" gutterBottom>
                {viewedLessons}/{totalLessons} completed
              </Typography>
            </Box>


            {course.lessons && course.lessons.length > 0 ? (
              <List
                sx={{
                  maxHeight: "calc(100vh - 200px)",
                  overflowY: "auto",
                  padding: 0,
                }}
              >
                {course.lessons.map((lesson, index) => (
                  
                  <ListItem
                    key={`${lesson.id}-${index}`}
                    //onClick={() => setSelectedLesson(lesson)}
                    onClick={() => handleLessonClick(lesson.id)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#f0f0f0",
                      borderRadius: 2,
                      py: 1.5,
                      paddingRight: 1.5,
                      marginBottom: 1.5,
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={lesson.thumbnailUrl || course.thumbnailUrl}
                      alt={lesson.title}
                      sx={{
                        width: 150,
                        height: "auto",
                        objectFit: "cover",
                        borderRadius: 2,
                        marginRight: 2,
                      }}
                    />
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          sx={{ 
                            fontSize: "11px",
                            color: "#189AB4" 
                          }}
                        >
                          LESSON {lesson.order}
                        </Typography>
                      }
                      secondary={
                        <>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: "#000",
                          }}
                        >
                          {lesson.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ 
                            fontSize: "11px", 
                            color: "#189AB4" 
                          }}
                        >
                          {lesson.duration || "Unknown"} minutes
                        </Typography>
                        </>
                      }
                    />
                    {completedLessons?.has(lesson.id) && <CheckCircleIcon sx={{ width: "16px", height: "16px", color: "#0A435E" }} />}
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body1" color="#aaa" mt={2}>
                No lessons available for this course.
              </Typography>
            )}
          </Box>
        </>
      )}

      {(error) && (
        <Box sx={{ textAlign: 'center', mt: 10 }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CoursePage;