import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Button, Box } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const EditMembershipPage = () => {
    const { id } = useParams(); // Get the membership ID from the URL
    const navigate = useNavigate();
    const [membership, setMembership] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMembershipDetails = async () => {
            try {
                const response = await axios.get(`https://localhost:7095/api/memberships/${id}`);
                setMembership(response.data);
            } catch (err) {
                setError('Error fetching membership details. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchMembershipDetails();
    }, [id]);

    const handleDelete = async () => {
        try {
            await axios.delete(`https://localhost:7095/api/memberships/${id}`);
            navigate('/memberships'); // Redirect back to the memberships list after deletion
        } catch (error) {
            console.error('Error deleting membership:', error);
        }
    };

    const handleEdit = () => {
        navigate(`/memberships/edit/${id}`); // Navigate to the edit page for the selected membership
    };

    if (loading) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><CircularProgress style={{ color: '#189AB4' }} /></Container>;
    }

    if (error) {
        return <Container maxWidth="md" sx={{ mt: 8 }}><Typography color="error">{error}</Typography></Container>;
    }

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Typography variant="h2" gutterBottom>
                Membership details
            </Typography>

            {membership ? (
                <Box 
                    sx={{
                        mt: 4,
                        p: 6,
                        boxShadow: 3,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6">Name:</Typography>
                    <Typography>{membership.name}</Typography>

                    <Typography variant="h6" sx={{ mt: 2 }}>Description:</Typography>
                    <Typography>{membership.description}</Typography>

                    <Typography variant="h6" sx={{ mt: 2 }}>Price:</Typography>
                    <Typography>${membership.price}</Typography>

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<EditOutlinedIcon />}
                            onClick={handleEdit}
                            style={{ color: 'white'}}
                        >
                            Edit Membership
                        </Button>

                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={handleDelete}
                        >
                            Delete Membership
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Typography>No membership found with ID: {id}</Typography>
            )}
        </Container>
    );
};

export default EditMembershipPage;