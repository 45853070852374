import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, FormControlLabel, Checkbox, Link, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');
};

export const getRole = () => {
  return localStorage.getItem('userRole');
};

const LoginPage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmailValid = emailRegex.test(email);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!isEmailValid) {
      setError("Invalid email format.");
      setLoading(false);
      return;
    }

    //   axios.post(`${API_BASE_URL}/api/account/login`, { email, password, rememberMe }) 
    //     .then(response => {
    //       const { token, role, tokenExpiration, userId } = response.data;
    
    //       const storage = rememberMe ? localStorage : sessionStorage;
    //       storage.setItem('authToken', token);
    //       storage.setItem('userRole', role);
    //       storage.setItem('userId', userId);

    //       // Convert the tokenExpiration to a Date object and store it as ISO string
    //       const expirationDate = new Date(tokenExpiration).toISOString();
    //       storage.setItem('tokenExpiration', expirationDate);
    
    //       // Check if the token is expired before proceeding
    //       const currentTime = new Date();
    //       const tokenExpirationDate = new Date(expirationDate);

    //       if (currentTime >= tokenExpirationDate) {
    //         handleLogout(); // If the token has expired, log the user out
    //         return;
    //       }

    //       if (role === 'Manager') {
    //         navigate('/admin');
    //       } else if(role === 'Member') {
    //         navigate('/mycourses');
    //       } else {
    //         navigate('/unauthorized');
    //       }
    //     })
    //     .catch(error => console.error('Login failed:', error.message));
    // };

    try {
        const response = await axios.post(`${API_BASE_URL}/api/account/login`, { email, password, rememberMe });
        const { token, role, tokenExpiration, userId } = response.data;

        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem('authToken', token);
        storage.setItem('userRole', role);
        storage.setItem('userId', userId);
        storage.setItem('tokenExpiration', new Date(tokenExpiration).toISOString());

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Set token for future requests

        navigate(role === 'Manager' ? '/admin' : role === 'Member' ? '/mycourses' : '/unauthorized');
    } catch (error) {
        if (error.response?.data?.message) {
          // If the API returns a specific error message, set it
          setError(error.response.data.message);
        } else {
          // If no specific error message from API, use a generic message
          setError("Login failed. Please check your credentials.");
        }
    } finally {
        setLoading(false);
    }
  };

  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration') || sessionStorage.getItem('tokenExpiration');
    if (tokenExpiration) {
      const isExpired = new Date(tokenExpiration) < new Date();
      if (isExpired) {
        handleLogout(); // Log the user out if the token has expired
      }
    }
  };

  const scheduleLogout = () => {
    const expiration = localStorage.getItem('tokenExpiration') || sessionStorage.getItem('tokenExpiration');
    if (expiration) {
      const expiresInMs = new Date(expiration) - new Date();
      if (expiresInMs > 0) {
        setTimeout(handleLogout, expiresInMs); // Auto-logout on expiration
      } else {
        handleLogout(); // Immediate logout
      }
    }
  };
  
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
  };
  
  useEffect(() => {
    checkTokenExpiration();
    scheduleLogout();
    const intervalId = setInterval(checkTokenExpiration, 5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);

  if (loading) {
      return <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}><CircularProgress style={{ color: '#189AB4' }} /></Container>;
  }

  return (
    <Container sx={{ mt: { xs: 3, xxl: 8 }, maxWidth: '600px !important' }}>
      <Box 
        sx={{
          mt: 4,
          p: { xs: 4, xxl: 6 },
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: "#fff"
        }}
      >
        <Typography variant="h2" align='center' gutterBottom sx={{ marginBottom: '30px', fontWeight: 'bold'}}>
          Login
        </Typography>

        {error && <Typography color="error" mb="30px">{error}</Typography>}

        <form onSubmit={handleLogin}>
          <Box sx={{ display: 'flex', gap: 2, marginBottom: '30px' }}>
            <TextField
              label="Email address"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ marginBottom: '20px' }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ marginBottom: '20px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: { xs: 0, xxl: 2 }, marginBottom: '20px', fontSize: { xs: "0.7rem" } }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  
                />
              }
              label="Keep me signed in"
            />
            <Link component={RouterLink} to="/request-password-reset" sx={{ fontSize: { xs: "0.8rem", xxl: "1rem" } }}>
              Forgot your password?
            </Link>
          </Box>
          <Button variant="contained" color="secondary" type="submit" fullWidth style={{ color: 'white' }}>
            Sign in
          </Button>
        </form>

        <Typography align="center" sx={{ marginTop: '20px', fontSize: '0.9rem' }}>
          Don't have an account?{' '}
        </Typography>
        <Box textAlign="center">
          <Link component={RouterLink} to="/contact">
            Contact us!
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;