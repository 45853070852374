import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Container, Typography, Card, CardMedia, CardContent, CircularProgress, Snackbar, Alert, Box, TextField, Button} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

const CoursesPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [courses, setCourses] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const loadCourses = async () => {
            setLoading(true); // Start loading
            try {
                const response = await axios.get(`${API_BASE_URL}/api/courses/courses`);
                setCourses(response.data);
            } catch (err) {
                console.error('Error fetching courses:', err);
                setError('Failed to load courses. Please try again later.');
                setSnackbarOpen(true);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        loadCourses();
    }, []);

    const handleRowClick = (course) => {
        const courseId = course.row.id;
        navigate(`/course/${courseId}`); 
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const columns = [
        { field: 'title', headerName: 'Title', width: 200 },
        {
            field: 'description',
            headerName: 'Description',
            width: 250,
            renderCell: (params) => (
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(params.value || ''),
                    }}
                />
            ),
        },
        { field: 'category', headerName: 'Category', width: 200 },
        { field: 'price', headerName: 'Price', width: 100 },
        { field: 'isTrending', headerName: 'Is Trending', width: 100 },
        { 
            field: 'authors', 
            headerName: 'Authors', 
            width: 200,
            renderCell: (params) => (
                <Typography variant="body2">
                    {params.row.authors && params.row.authors.length > 0
                        ? params.row.authors.map(author => author.name).join(', ')
                        : 'No authors available'}
                </Typography>
            ),
        },
        { 
            field: 'memberships', 
            headerName: 'Memberships', 
            width: 200,
            renderCell: (params) => (
                <Typography variant="body2">
                    {params.row.memberships && params.row.memberships.length > 0
                        ? params.row.memberships.map(membership => membership.name).join(', ')
                        : 'No memberships available'}
                </Typography>
            ),
        },
        { 
            field: 'lessons', 
            headerName: 'Lessons', 
            width: 300,
            renderCell: (params) => (
                <Typography variant="body2">
                    {params.row.lessons && params.row.lessons.length > 0
                        ? params.row.lessons.map(lesson => lesson.title).join(', ')
                        : 'No lessons available'}
                </Typography>
            ),
        },
        // { 
        //     field: 'thumbnail', 
        //     headerName: 'Thumbnail', 
        //     width: 100,
        //     renderCell: (params) => (
        //         params.row.thumbnailUrl ? (
        //             <CardMedia
        //                 component="img"
        //                 height="50"
        //                 image={params.row.thumbnailUrl}
        //                 alt={`${params.row.title} thumbnail`}
        //             />
        //         ) : (
        //             <Typography variant="body2">No Image</Typography>
        //         )
        //     ),
        // }
    ];

    const filteredCourses = courses.filter(course => 
        course.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
        course.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (course.authors && course.authors.some(author => author.name.toLowerCase().includes(searchTerm.toLowerCase()))) ||
        course.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h2" gutterBottom>
                    Courses
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '35%' }}>
                    {/* Search input */}
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}  // Update search term
                    />

                    <Button variant="contained" color="secondary" 
                            style={{ color: 'white', marginLeft: '20px' }} 
                            onClick={() => navigate('/course/register')}>
                        <AddCircleOutlineOutlinedIcon sx={{ marginRight: '5px' }}/>
                        Create
                    </Button>
                </Box>
            </Box>

            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}

            <Box my={4}>
                {loading ? (
                    <CircularProgress style={{ color: '#189AB4' }} />
                ) : (
                    <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
                        <DataGrid
                            rows={filteredCourses}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowClick={handleRowClick}
                        />
                    </div>
                )}
            </Box>
        </Container>
    );
};

export default CoursesPage;