import React, { useEffect, useState, useRef } from 'react';
import { TextField, Box, Button, Container, Typography, Avatar, Tooltip, IconButton } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Editor } from '@tinymce/tinymce-react';

const AuthorRegisterPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [author, setAuthor] = useState({
        name: '',
        expertise: '',
        description: '',
        pictureUrl: null,
    });

    const [selectedFile, setSelectedFile] = useState(null);
    const [pictureUrl, setPictureUrl] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const navigate = useNavigate();
    const editorRef = useRef(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAuthor({ ...author, [name]: value });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPictureUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setSelectedFile(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        const formData = new FormData();
        formData.append('name', author.name);
        formData.append('expertise', author.expertise);
        formData.append('description', editorRef.current.getContent());

        // Append the file only if it exists
        if (selectedFile) {
            formData.append('pictureFile', selectedFile);
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/api/authors/author/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                setSuccess('Author created successfully!');
                navigate('/authors');
            }
            console.log('Author created:', response.data);
        } catch (error) {
            console.error('Error creating author:', error.response?.data || error.message);
            setError('Creating author failed. Please refresh the page and try again.');
        }
    };

    const handleCancel = () => {
        navigate('/authors');
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Typography variant="h2" gutterBottom>
                Create a new author
            </Typography>
            
            <Box
                sx={{
                    mt: 4,
                    p: 6,
                    boxShadow: 3,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 4,
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 0.1 }}>
                    {/* Picture Section and Edit Icon */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '50px' }}>
                        {pictureUrl ? (
                            <Avatar
                                src={pictureUrl}
                                alt="Profile picture"
                                sx={{ width: 150, height: 150 }}
                                style={{ border: '2px solid #189AB4' }}
                            />
                        ) : (
                            <Avatar
                                sx={{ width: 150, height: 150 }}
                                alt="User icon"
                                style={{ border: '2px solid #189AB4' }}
                            />
                        )}

                        <Tooltip title="Upload a new profile picture">
                            <Typography color="error" sx={{ fontSize: 16 }}>
                                *
                            </Typography>
                            <IconButton component="label" style={{ color: '#189AB4' }}>
                                <EditIcon sx={{ fontSize: 30 }} />
                                <input
                                    key={pictureUrl}
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 0.9 }}>
                    <Box sx={{ display: 'flex', gap: 2, marginBottom: '15px' }}>
                        <TextField
                            name="name"
                            label="Name"
                            value={author.name}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                        <TextField
                            name="expertise"
                            label="Expertise"
                            value={author.expertise}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Box>
                    <Typography variant="body1" >
                        Description <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {/* <TextField
                            name="description"
                            label="Description"
                            value={author.description}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            multiline
                            maxRows={5}
                            minRows={5}
                        /> */}
                        <Editor
                            apiKey='16xkbsggpf5mf009anlqhpejh1xhoh0hjv6itfy16u948tin'
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            initialValue={author.description}
                            init={{
                                plugins: [
                                // Core editing features
                                'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                // Your account includes a free trial of TinyMCE premium features
                                // Try the most popular premium features until Feb 19, 2025:
                                'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown','importword', 'exportword', 'exportpdf'
                                ],
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                tinycomments_mode: 'embedded',
                                tinycomments_author: 'Author name',
                                mergetags_list: [
                                { value: 'First.Name', title: 'First Name' },
                                { value: 'Email', title: 'Email' },
                                ],
                                ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                            }}
                        />
                    </Box>

                    {/* Buttons */}
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 4 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<SaveIcon />}
                            onClick={handleSubmit}
                            style={{ color: 'white'}}
                            fullWidth
                        >
                            Create Author
                        </Button>

                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<CancelIcon />}
                            onClick={handleCancel}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
                

                {/* Error / Success Messages */}
                {error && <Typography color="error" marginBottom="20px">{error}</Typography>}
                {success && <Typography color="primary" marginBottom="20px">{success}</Typography>}

                
            </Box>
        </Container>
    );
};

export default AuthorRegisterPage;
