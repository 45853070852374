import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const TermsAndConditionsPage = () => {
  return (
    <Box sx={{ backgroundColor: '#f8f8f8', display: 'flex0', alignItems: 'center', justifyContent: 'center'}}>
      <Container sx={{ backgroundColor: '#fff', p: 4, borderRadius: 2, boxShadow: 2, maxWidth: '1450px !important' }}>
        {/* Header Section */}
        <Typography
          variant="h4"
          sx={{
            color: '#189AB4',
            fontWeight: 'bold',
            mb: 4,
            fontFamily: 'Instrument Sans, sans-serif',
          }}
        >
          Termeni și Condiții pentru Membru Gold – S.C. Academillion S.R.L.
        </Typography>

        {/* Content */}
        <Typography
          variant="body1"
          sx={{
            color: '#1a2b4c',
            lineHeight: 1.8,
          }}
        >
          Acești Termeni și Condiții reglează relația dintre S.C. ACADEMILLION S.R.L. ("Prestator") și persoanele care achiziționează Pachetul Membru Gold ("Beneficiar"). Prin acceptarea acestor Termeni și Condiții, Beneficiarul înțelege și este de acord să respecte toate prevederile de mai jos.
        </Typography>

        {/* Sections */}
        {[
          {
            title: '1. Părțile implicate',
            content: `1.1. Prestator: S.C. ACADEMILLION S.R.L., cu sediul în Sibiu, str. Ion Creangă, nr. 52, Sibiu, înregistrată la Registrul Comerțului sub nr. J20/24020309006, CUI: 50517497, cont IBAN: RO97BTRLRONCRT0CT9460701 deschis la Banca Transilvania, e-mail: office@academillion.com.
            1.2. Beneficiar: Persoană fizică sau juridică ce achiziționează Pachetul Membru Gold.`,
          },
          {
            title: '2. Obiectul Termenilor și Condițiilor',
            content:
              'Prestatorul prestează Beneficiarului serviciile incluse în Pachetul de Membru Gold și transmite informări necesare pentru servicii contra cost.',
          },
          {
            title: '3. Durata serviciilor',
            content: 'Serviciile sunt valabile pentru o perioadă de 12 luni, începând din ziua înregistrării.',
          },
          {
            title: '4. Prețul serviciilor',
            content:
              'Înregistrarea este completă doar după acceptarea prezentelor Termeni și Condiții și efectuarea plății. Beneficiarul înțelege și acceptă că plata reprezintă consimțământul său final pentru accesarea serviciilor oferite de Prestator. În cazul în care plata nu este efectuată, accesul la servicii nu va fi oferit, iar înregistrarea nu va fi considerată validă.',
          },
          {
            title: '5. Servicii oferite',
            content: `Prestatorul oferă următoarele servicii pentru Pachetul de Membru Gold:
            5.1. Plan Financiar Personalizat
            Prestatorul va aloca un coach financiar pentru a elabora un plan financiar personalizat adaptat nevoilor și obiectivelor Beneficiarului. Planul va fi livrat și monitorizat pe parcursul a 10 sesiuni individuale de coaching.
            5.2. Acces la Platforma Academillion
            Beneficiarul va avea acces la cursuri și resurse din categoriile Money și Investments.
            5.3. Workshopuri cu Experți
            Prestatorul va organiza 24 de workshopuri online susținute de experți pentru aprofundarea cunoștințelor financiare.
            5.4. Sesiuni de Mastermind Comunitar
            Prestatorul va facilita 24 de sesiuni online de mastermind comunitar. Aceste sesiuni vor fi ghidate de delegați desemnați de Prestator.
            5.5. Networking, Evenimente și Experiențe
            Prestatorul va organiza întâlniri de networking, evenimente și experiențe educaționale sau recreative destinate dezvoltării personale și profesionale.. În cazul în care unele dintre aceste activități implică costuri suplimentare, Beneficiarul va fi notificat în prealabil.
            5.6. Partener de Responsabilitate
            Prestatorul va desemna, la începutul programului, un partener de responsabilitate care va avea rolul de a sprijini Beneficiarul în realizarea obiectivelor sale. 
            5.7. Acces la Produse de investiții
            Beneficiarul are acces la Produse de Investiții oferite de terți, facilitate de Prestator prin negocierea unor condiții avantajoase. Prestatorul oferă informații relevante pentru a sprijini Beneficiarul în procesul de analiză și selecție a acestor opțiuni. Deciziile finale de investiție, precum și responsabilitatea pentru performanța și rezultatele produselor alese, aparțin exclusiv Beneficiarului.

            Toate serviciile sunt disponibile pe parcursul celor 12 luni de la înregistrare.
            `,
          },
          {
            title: '6. Obligațiile Beneficiarului',
            content: `6.1. Colaborare Activă
            Beneficiarul participă activ la sesiunile individuale de coaching, workshopuri și sesiuni de mastermind respectând calendarului stabilit. Este interzis trimiterea link-ul sesiunilor tip workshop/mastermind sau a eventualelor înregistrări ale acestora către terți.
            6.2. Acces la Platformă
            Beneficiarul va folosi platforma Academillion doar pentru uz personal și nu va împărtăși accesul cu terți.
            6.3. Respectarea Programului 
            Beneficiarul este responsabil de respectarea programului agreat pentru sesiunile de coaching. În cazul în care Beneficiarul nu poate participa, este obligat să anunțe coach-ul financiar cu cel puțin 24 de ore înainte, pentru a reprograma sesiunea. Dacă nu se respectă acest termen, sesiunea de coaching va fi considerată realizată.
            6.4. Furnizarea Informațiilor
            Beneficiarul va furniza Prestatorului toate informațiile necesare pentru elaborarea planului financiar, precum date financiare relevante, obiective specifice și alte detalii solicitate în procesul de coaching/mentorat.
            6.5. Participarea la Networking și Evenimente
            Beneficiarul este responsabil pentru costurile suplimentare ale evenimentelor.
            6.6. Confidențialitate și Respect
            Beneficiarul va păstra confidențialitatea informațiilor și va respecta ceilalți membri.
            6.7. Respectarea Regulilor de Conduită
            Beneficiarul trebuie să respecte regulile de conduită și etică ale Prestatorului și să nu aducă prejudicii imaginii acestuia. Nerespectarea acestor obligații poate duce la suspendarea serviciilor, încetarea unilaterală a contractului fără compensații și la solicitarea de daune-interese din partea Prestatorului.
            6.8. Acceptarea Riscurilor Asociate Investițiilor
            Beneficiarul înțelege și acceptă că deciziile de a investi, luate pe baza cursurilor, informațiilor, materialelor furnizate sau produselor de investiții prezentate de Prestator, implică riscuri financiare. Prestatorul nu poate garanta rezultate sau performanțe specifice în investiții și nu poate fi considerat responsabil pentru eventualele pierderi suferite.
            `,
          },
          {
            title: '7. Obligațiile Prestatorului',
            content: `7.1. Furnizarea Serviciilor la Standardele Acordate
            Prestatorul se angajează ă să furnizeze serviciile incluse în Pachetul Membru Gold conform descrierii și la standardele stabilite. Sesiunile de coaching, workshopurile și evenimentele vor fi organizate conform programului stabilit și vor fi susținute de persoane desemnate de Prestator.
            7.2. Confidențialitate
            Prestatorul se angajează să protejeze confidențialitatea informațiilor furnizate de Beneficiar, inclusiv datele financiare, personale și alte informații sensibile. Aceste informații nu vor fi divulgate către terți fără consimțământul explicit al Beneficiarului.
            7.3. Suport și Asistență
            Prestatorul va oferi suport pentru utilizarea platformei, reprogramarea sesiunilor și clarificarea întrebărilor.
            7.4. Respectarea Programului Stabilit
            Prestatorul va organiza sesiunile de coaching, workshopurile și mastermindurile conform programului stabilit. În caz de imposibilitate, va informa Beneficiarul și va oferi soluții pentru reprogramare.
            7.5. Furnizarea de Materiale
            Prestatorul va asigura accesul la materialele educaționale.
            7.6. Actualizarea Serviciilor
            Prestatorul poate actualiza materialele pentru a reflecta tendințele financiare noi, cât și pentru a răspunde nevoilor Beneficiarilor. Orice modificare semnificativă va fi comunicată Beneficiarului.
            `,
          },
          {
            title: '8. Confidențialitate și proprietate intelectuală',
            content: `Materialele educaționale (cursuri, prezentări, resurse video etc.) sunt proprietatea intelectuală a Prestatorului și sunt destinate exclusiv Beneficiarului. Copierea, distribuirea sau publicarea materialelor fără acordul Prestatorului este strict interzisă. Orice încălcare a acestei clauze poate atrage suspendarea serviciilor și solicitarea de despăgubiri.`,
          },
          {
            title: '9. Răspunderea părților',
            content: `Prestatorul nu este responsabil pentru deciziile de investiții ale Beneficiarului sau pentru eventualele pierderi financiare rezultate. Beneficiarul înțelege că investițiile implică riscuri și că Prestatorul nu oferă garanții privind rezultatele acestora.`,
          },
          {
            title: '10. Forță Majoră',
            content: `În cazul unui eveniment de forță majoră, niciuna dintre părți nu va fi responsabilă pentru neexecutarea obligațiilor. Părțile se vor consulta pentru a găsi soluții alternative.`,
          },
          {
            title: '11. Încetarea Serviciilor',
            content: `Beneficiarul poate renunța unilateral la servicii în primele 2 săptămâni de la achiziție, beneficiind de rambursarea integrală. După acest interval, nu se vor acorda rambursări.
            La implinirea termenului de 12 luni de la data înregistrării. 
            Pentru daune de imagine și neconformitate: Dacă Beneficiarul nu respectă regulile de conduită și etică, Prestatorul are dreptul să rezilieze unilateral înțelegerea, fără despăgubiri.
            În caz de neexecutare cu rea-credință: Dacă Beneficiarul acționează cu rea-credință, Prestatorul poate denunța unilateral contractul fără a plăti despăgubiri.`,
          },
          {
            title: '12. Litigii',
            content: `Orice dispută se va încerca a fi rezolvată pe cale amiabilă. În cazul eșecului, litigiile vor fi soluționate de instanțele competente de la sediul Prestatorului.`,
          },
          {
            title: '13. Dispoziții finale',
            content: `Orice modificare a acestor Termeni și Condiții va fi notificată Beneficiarului. Continuarea utilizării serviciilor după notificare reprezintă acceptarea modificărilor.`,
          },
        ].map((section, index) => (
          <Box key={index} sx={{ mt: 4 }}>
            <Typography
              variant="h5"
              sx={{
                color: '#189AB4',
                fontWeight: 'bold',
                mb: 1,
              }}
            >
              {section.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#1a2b4c',
                whiteSpace: 'pre-line',
                lineHeight: 1.8,
              }}
            >
              {section.content}
            </Typography>
          </Box>
        ))}

        {/* Footer */}
        <Typography
          variant="body2"
          align="center"
          sx={{
            mt: 6,
            pt: 4,
            color: '#1a2b4c',
            borderTop: '1px solid #ddd',
          }}
        >
          Prin înregistrarea ca Membru Gold, Beneficiarul confirmă că a citit, înțeles și acceptat acești Termeni și Condiții.
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsAndConditionsPage;
