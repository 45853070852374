import React, {useState} from "react";
import { Box, Typography, Link, Stack, Modal, Button } from "@mui/material";
import logo from '../assets/images/logos/Logo-01.png';
import anpcSol from '../assets/images/anpc/anpc-sol.png';
import anpcSal from '../assets/images/anpc/anpc-sal.png';
import CopyrightIcon from '@mui/icons-material/Copyright';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TermsAndConditionsPage from '../pages/members/TermsAndConditionsPage';

const Footer = () => {
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  return (
    <Box
      sx={{
        py: { xs: 2, xxl: 4 },
        px: { xs: 2, xxl: 4 },
        borderTop: "1px solid #E0E0E0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          display: "flex",
          direction: "column",
          justifyContent: { xs: "center", xxl: "space-between" },
          alignItems: "center",
          width: { xs: "100%", xxl: "70%" },
          mb: { xs: 0, xxl: 2 },
          flexWrap: "wrap",
          margin: "0 auto"
        }}
      >
        <Box>
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mt: 3,
              }}
            >
            {/* Logo */}
            <Box 
              component="img" 
              src={logo} 
              alt="Logo" 
              sx={{ 
                width: { xs: "277px", xxl: "242px" },
                //height: { xs: "63px", xxl: "55px" }, 
                margin: 0 
              }} 
            />

            {/* Copyright */}
            <Typography
              variant="body3"
              sx={{
                mb: { xs: 0, sm: 0 },
                color: "#000",
                textTransform: "none",
                fontWeight: "300", 
                letterSpacing: "normal", 
              }}
            >
              <CopyrightIcon /> Copyright Academillion 2024. All Rights Reserved.
            </Typography>
          </Box>

          {/* Social Media Links Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center", xxl: "space-between" },
              alignItems: "center",
              width: "100%",
              mt: 3,
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mb: 2, textAlign: { xs: "center", xxl: "left" } }}
            >
              Follow Us
            </Typography>
            <Stack direction="row" spacing={2}>
              <Link href="https://www.facebook.com/academillion" target="_blank">
                <FacebookIcon sx={{ color: "#05445E", fontSize: "30px !important" }} />
              </Link>
              <Link href="https://www.instagram.com/academillion.club" target="_blank">
                <InstagramIcon sx={{ color: "#05445E", fontSize: "30px !important" }} />
              </Link>
              <Link href="https://www.tiktok.com/@academillion" target="_blank">
                <MusicNoteIcon sx={{ color: "#05445E", fontSize: "30px !important" }} />
              </Link>
              <Link href="https://www.youtube.com/@Academillion" target="_blank">
                <YouTubeIcon sx={{ color: "#05445E", fontSize: "30px !important" }} />
              </Link>
              <Link href="https://www.linkedin.com/company/academillion/" target="_blank">
                <LinkedInIcon sx={{ color: "#05445E", fontSize: "30px !important" }} />
              </Link>
            </Stack>
          </Box>
        </Box>

        <Box>
          {/* Navigation Links */}
          <Stack
            direction="row"
            spacing={{ xs: 4, xxl: 6 }}
            sx={{
              mt: 1,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              color: "#000"
            }}
          >
            <Link href="/" underline="none" sx={{ color: "#000 !important", fontSize: "16px", fontWeight: "bold" }}>
              Our Club
            </Link>
            <Link href="/ourculture" underline="none" sx={{ color: "#000 !important", fontSize: "16px", fontWeight: "bold" }}>
              Our Culture
            </Link>
            <Link href="/blogs" underline="none" sx={{ color: "#000 !important", fontSize: "16px", fontWeight: "bold" }}>
              Blog
            </Link>
            <Link href="/contact" underline="none" sx={{ color: "#000 !important", fontSize: "16px", fontWeight: "bold" }}>
              Contact
            </Link>
          </Stack>

          {/* Contact Information Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 3,
              textAlign: "left",
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mb: 2, textAlign: "center" }}
            >
              Contact Information
            </Typography>
            <Stack spacing={2} sx={{ alignItems: "center", fontSize: "12px" }}>
              {/* Address */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HomeIcon sx={{ mr: 1, color: "#189AB4" }} />
                <Typography variant="body1">
                  Ion Creangă 52, Sibiu (Romania)
                </Typography>
              </Box>

              {/* Phone */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PhoneIcon sx={{ mr: 1, color: "#189AB4" }} />
                  <Link href="tel:+40726266412" underline="none" sx={{ color: "#000", fontSize: "12px" }}>
                    +40 726 266 412
                  </Link>
              </Box>

              {/* Email */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EmailIcon sx={{ mr: 1, color: "#189AB4" }} />
                <Link
                  href="mailto:contact@academillion.com"
                  underline="none"
                  sx={{ color: "#000", fontSize: "12px" }}
                >
                  contact@academillion.com
                </Link>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>


      {/* ANPC Images Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          mt: 2,
          mb: 2,
          gap: 2,
        }}
      >
        {/* ANPC SOL Image */}
        <Link href="https://anpc.ro/ce-este-sal/">
          <Box
            component="img"
            src={anpcSal}
            alt="ANPC SOL"
            sx={{
              width: { xs: "150px", md: "200px" },
              cursor: "pointer",
            }}
          />
        </Link>
        {/* ANPC SAL Image */}
        <Link href="https://ec.europa.eu/consumers/odr">
          <Box
            component="img"
            src={anpcSol}
            alt="ANPC SAL"
            sx={{
              width: { xs: "150px", md: "200px" },
              cursor: "pointer",
            }}
          />
        </Link>
      </Box>

      {/* Bottom Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "100%", xxl: "80%" },
          flexWrap: "wrap",
        }}
      >
        {/* Policy Links */}
        <Stack direction="row" spacing={2} sx={{ textAlign: "center" }}>
          <Link
            href="/"
            underline="none"
            sx={{ color: "#189AB4", fontSize: "12px" }}
            onClick={(e) => {
              e.preventDefault(); // Prevent default navigation behavior
              setTermsModalOpen(true); // Open the modal
            }}
          >
            Terms & Conditions
          </Link>
          <Link
            href="#"
            underline="none"
            sx={{ color: "#189AB4", fontSize: "12px" }}
          >
            Cookie Policy
          </Link>
        </Stack>
      </Box>
    
      {/* Modal for Terms & Conditions */}
      <Modal
        open={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        aria-labelledby="terms-modal-title"
        aria-describedby="terms-modal-description"
      >
        <Box
          sx={{
            width: "80%",
            bgcolor: "background.paper",
            margin: "auto",
            mt: "5%",
            p: 2,
            borderRadius: 2,
            boxShadow: 24,
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          {/* Embed TermsAndConditionsPage with Scroll Event */}
          <Box
            id="terms-modal-content"
            sx={{ maxHeight: "70vh", overflowY: "scroll", pr: 1 }}
          >
            <TermsAndConditionsPage />
          </Box>

          {/* Close Modal Button */}
          <Button
            onClick={() => setTermsModalOpen(false)}
            variant="contained"
            color="primary"
            sx={{ mt: 2, color: '#fff' }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Footer;