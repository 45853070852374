import React, { useState } from "react";
import { Box, Typography, Button, Avatar, Card } from "@mui/material";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import valuesBackground from '../../assets/images/ourculture/values-background.png';
import valueYou from '../../assets/images/ourculture/icons/you.png';
import valueFreedom from '../../assets/images/ourculture/icons/freedom.png';
import valueConnections from '../../assets/images/ourculture/icons/connections.png';
import valuePassions from '../../assets/images/ourculture/icons/passions.png';
import valueLegacy from '../../assets/images/ourculture/icons/legacy.png';
import valueGivingBack from '../../assets/images/ourculture/icons/giving-back.png';

import academillionairMihaiPetian from '../../assets/images/thumbnails/academillionaires/mihai-bg-grey.png';
import academillionairDenisaStanila from '../../assets/images/thumbnails/academillionaires/deni-bg-grey.png';
import academillionairLaviniaMotoc from '../../assets/images/thumbnails/academillionaires/lavi-bg-grey.png';
import academillionairSabinaBodea from '../../assets/images/thumbnails/academillionaires/sabi-bg-gri.png';
import academillionairMarianPapara from '../../assets/images/thumbnails/academillionaires/marian-bg-gri.png';
import academillionairMihaelaPapara from '../../assets/images/thumbnails/academillionaires/mihaela-bg-gri.jpg';
import academillionairMariusCobarzan from '../../assets/images/thumbnails/academillionaires/marius-bg-gri.jpg';
import academillionairOanaManase from '../../assets/images/thumbnails/academillionaires/oana-bg-gri.png';
import academillionairStefanDulca from '../../assets/images/thumbnails/academillionaires/stefan-bg-gri.jpg';

import placeholderImage from "../../assets/images/ourculture/people-talking.png";
import worldMap from "../../assets/images/ourculture/world.png";

import testimonialAvatarAndrei from "../../assets/images/thumbnails/testimonials/testimonial-avatar-andrei.jpeg";
import testimonialAvatarCristina from "../../assets/images/thumbnails/testimonials/testimonial-avatar-cristina.jpeg";
import testimonialAvatarElena from "../../assets/images/thumbnails/testimonials/testimonial-avatar-elena.jpeg";
import testimonialAvatarMihai from "../../assets/images/thumbnails/testimonials/testimonial-avatar-mihai.jpeg";

import ambassadorBackground from "../../assets/images/ourculture/ambassador-background.png";
import realEstateIcon from "../../assets/images/ourculture/icons/house.png";
import cryptoIcon from "../../assets/images/ourculture/icons/bitcoin.png";
import artsIcon from "../../assets/images/ourculture/icons/paint.png";
import stockMarketIcon from "../../assets/images/ourculture/icons/stock-market.png";
import jointVenturesIcon from "../../assets/images/ourculture/icons/deal.png";

import Footer from "../../components/Footer";

const OurCulture = () => {

  const authorsAM = [
    { name: "Mihai Petian", pictureUrl: academillionairMihaiPetian, expertise: "The Business Creator" },
    { name: "Denisa Stănilă", pictureUrl: academillionairDenisaStanila, expertise: "The Business Arhitect" },
    { name: "Lavinia Motoc", pictureUrl: academillionairLaviniaMotoc, expertise: "The Money Maker" },
    { name: "Sabina Bodea", pictureUrl: academillionairSabinaBodea, expertise: "The Experience Alchemist" },
    { name: "Mihaela Papară", pictureUrl: academillionairMihaelaPapara, expertise: "Sales Manager" },
    { name: "Marius Cobârzan", pictureUrl: academillionairMariusCobarzan, expertise: "Community Leader" },
    { name: "Oana Manase", pictureUrl: academillionairOanaManase, expertise: "Community Leader" },
  ];

  const values = [
    { name: "CONTRIBUȚIE", icon: valueGivingBack, description: "Investește în a împărtăși darurile tale cu lumea." },
    { name: "⁠TINE", icon: valueYou, description: "Investește în a deveni supereroul propriei tale vieți." },
    { name: "LIBERTATE", icon: valueFreedom, description: "Investește în crearea unui spațiu fără limite pentru mintea, sufletul și contul tău bancar." },
    { name: "CONEXIUNE", icon: valueConnections, description: "Investește în aprecierea frumuseții fiecărei conexiuni pentru a construi o comunitate dinamică și ingenioasă care evoluează împreună." },
    { name: "⁠PASIUNI", icon: valuePassions, description: "Investește în a te simți viu, experimentând emoții și activități care aduc bucurie și minuni." },
    { name: "MOȘTENIRE", icon: valueLegacy, description: "Investește în a lăsa lumea mai bună decât ai găsit-o." },
  ];

  const valuesMobile =[
    { name: "⁠TINE", icon: valueYou, description: "Investește în a deveni supereroul propriei tale vieți." },
    { name: "LIBERTATE", icon: valueFreedom, description: "Investește în crearea unui spațiu fără limite pentru mintea, sufletul și contul tău bancar." },
    { name: "CONEXIUNE", icon: valueConnections, description: "Investește în aprecierea frumuseții fiecărei conexiuni pentru a construi o comunitate dinamică și ingenioasă care evoluează împreună." },
    { name: "⁠PASIUNI", icon: valuePassions, description: "Investește în a te simți viu, experimentând emoții și activități care aduc bucurie și minuni." },
    { name: "MOȘTENIRE", icon: valueLegacy, description: "Investește în a lăsa lumea mai bună decât ai găsit-o." },
    { name: "CONTRIBUȚIE", icon: valueGivingBack, description: "Investește în a împărtăși darurile tale cu lumea." },
  ];

  const [selectedValue, setSelectedValue] = useState(values[2]);

    // Custom Left Arrow Component
    const CustomAuthorLeftArrow = ({ onClick }) => (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          left: "-50px",
          transform: "translateY(-110%)",
          zIndex: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "100",
            color: "#0A435E",
          }}
        >
          &#8249;
        </Typography>
      </Box>
    );
  
    // Custom Right Arrow Component
    const CustomAuthorRightArrow = ({ onClick }) => (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          right: "-50px",
          transform: "translateY(-110%)",
          zIndex: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
        }}
      >
        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "100",
            color: "#0A435E",
          }}
        >
          &#8250;
        </Typography>
      </Box>
    );
  
    const CustomAuthorDots = ({ dots }) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          marginTop: "10px", // Add spacing from the slider
        }}
      >
        {dots.map((dot, index) => (
          <Box
            key={index}
            onClick={dot.props.onClick}
            sx={{
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              backgroundColor: dot.props.className.includes("slick-active")
                ? "#189AB4" // Active dot color
                : "#D9D9D9", // Inactive dot color
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          />
        ))}
      </Box>
    );
  
    {/* Custom Pagination Dots */}
    <div className="custom-pagination" style={{ textAlign: 'center' }}>
      <CustomAuthorDots/>
    </div>
    
  const authorsCarouselSettings = {
    dots: true, 
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1, 
    responsive: [
      {
        breakpoint: 600, 
        settings: {
          slidesToShow: 2.5, 
          slidesToScroll: 1,
          arrows: false, 
          dots: true, 
        },
      },
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 1,
          arrows: true, 
          dots: true, 
        },
      },
    ],
    arrows: true, 
    prevArrow: <CustomAuthorLeftArrow />, 
    nextArrow: <CustomAuthorRightArrow />,
    appendDots: (dots) => <CustomAuthorDots dots={dots} />,
  };

  const textTestimonials = [
    {
      name: "Andrei Iliuț, Entrepreneur & Senior software engineer",
      text: "I joined this club to network and learn more about the real estate field. Here, I found an amazing community of wonderful people and inspiring entrepreneurs with whom you can share valuable ideas and experiences. Moreover, I’ve managed to collaborate with some of the club members and develop exciting projects. It’s a fantastic environment for both personal and professional growth!",
      avatar: testimonialAvatarAndrei,
    },
    {
      name: "Cristina Irimie, Entrepreneur & Investor",
      text: "In AM community, you find the courage to learn and grow, knowing you're supported in reaching your fullest potential. With Academillion, we evolve beautifully, together.",
      avatar: testimonialAvatarCristina,
    },
    {
      name: "Elena Deacu, Therapist & Investor",
      text: "I love new projects initiated by passionate people, full of positive energy and laughter! In the Academillion community, I’ve found people like me, simple, cheerful, and ready to get involved and support others. The people at Academillion bring a wealth of expertise and openness, always willing to help, contribute, and join my mission of creating a generation for whom abundance is the norm. Being a member of Academillion means education, professionally assessed investment opportunities, balance, and relaxation.",
      avatar: testimonialAvatarElena,
    },
    {
      name: "Mihai Udrescu, Entreprenor",
      text: "Academillion provides great investment guidance through its talented team, offering strategies and a supportive community. It’s inspiring to see their dedication to supporting the expat community as well.",
      avatar: testimonialAvatarMihai,
    },
  ];

  return (
    <Box>
      <Box>
        {/* First Section */}
        <Box
          sx={{
            backgroundColor: "#0A435E",
            color: "#fff",
            height: { xs: "735px", xxl: "469px" },
            py: { xs: 4, xxl: 8 },
            px: { xs: 0, xxl: 0 },
            display: "flex",
            justifyContent: "center",
            position: "relative", 
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", xxl: "70%" },
              margin: "0 auto",
              display: "flex",
              flexDirection: { xs: "column", xxl: "row" }, 
              gap: { xs: 4, xxl: 6 },
              alignItems: "center",
            }}
          >
            {/* Text Section */}
            <Box 
              sx={{ 
                flex: 1,
                maxWidth: { xs: "380px", xxl: "548px" }, 
                zIndex: 2, 
                position: "relative", 
                textAlign: { xs: "center", xxl: "left" } 
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  mb: { xs: 3, xxl: 6 },
                  paddingRight: { xs: "10px", xxl: "0" },
                  textAlign: "left"
                }}
              >
                How It All Began: Where Dreams Come to Life
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "left"
                }}
              >
                In a time of ambition and untapped potential, four visionaries united with a shared mission: to create 
                a space where growth, success, and financial freedom became accessible to all. From this vision, Academillion 
                was born, a community rooted in education, investments, and connection. What began as a small group blossomed 
                into a movement, redefining success and unlocking opportunities for everyone involved.
              </Typography>
            </Box>

            {/* Image Section */}
            <Box
              sx={{
                flex: 1,
                position: "relative",
                display: "flex",
                justifyContent: { xs: "center", xxl: "flex-end" }, 
              }}
            >
              <Box
                component="img"
                src={placeholderImage}
                alt="Discussion"
                sx={{
                  position: "absolute",
                  top: { xs: "0px", xxl: "-180px" },
                  width: { xs: "380px", xxl: "443px" },
                  borderRadius: "16px",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Vision Section */}
        <Box
          sx={{
            backgroundColor: "#5FD6DA",
            textAlign: "center",
            paddingTop: 24,
            paddingBottom: 6,
            px: { xs: 2, md: 0 },
            display: "flex",
            justifyContent: { xs: "center", xxl: "flex-start" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", xxl: "70%" },
              margin: "0 auto",
              position: "relative",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: "bold",
                color: "#8DE8EB",
                fontSize: { xs: "100px", xxl: "128px" },
                textTransform: "uppercase",
                position: { xs: "relative", xxl: "absolute" },
                top: "0",
                left: { xs: 0, xxl: "78%" },
              }}
            >
              VISION
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                maxWidth: { xs: "380px", xxl: "748px" },
                mx: { xs: "auto", xxl: "0" },
                textAlign: "left"
              }}
            >
              Our vision is to create a worldwide community where investors achieve great
              success, and enjoy life to the fullest together.
            </Typography>
          </Box>
        </Box>

        {/* Mission Section */}
        <Box
          sx={{
            backgroundColor: "#fff",
            color: "#003A55",
            py: { xs: 4, md: 6 },
            px: { xs: 2, md: 0 },
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", xxl: "70%" },
              margin: "0 auto",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: "bold",
                color: "#E6E6E6",
                fontSize: { xs: "100px", md: "128px" },
                textTransform: "uppercase",
                marginLeft: { xs: "0", xxl: "-120px" }
              }}
            >
              MISSION
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                maxWidth: { xs: "380px", xxl: "597px" },
                textAlign: "right"
              }}
            >
              Our mission is to inspire people to be their true selves and live a joyful,
              meaningful life doing what they love.
            </Typography>
          </Box>
        </Box>
      </Box>


      {/* Our values */}
      <Box 
        sx={{
          display: { xs: "none", xxl: "flex"},
          flexDirection: "column", 
          alignItems: "center",
          gap: 4,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: 625,
            backgroundImage: `url(${valuesBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Circular Container */}
          <Box
            sx={{
              position: "relative",
              width: 760,
              height: 530,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 2,
            }}
          >
            {values.map((value, index) => {
              const total = values.length;
              const baseAngle = Math.PI; // Start from the top (90 degrees)
              const angle = Math.PI + (index / total) * 2 * Math.PI; // Spread values evenly
              const radiusx = 300; // Keep the circle proportionate
              const radiusy = 220; 
              const x = radiusx * Math.cos(angle);
              const y = radiusy * Math.sin(angle);
              
              return (
                <Box
                  key={value.name}
                  onClick={() => setSelectedValue(value)}
                  sx={{
                    position: "absolute",
                    left: `calc(50% + ${x}px - 75px)`,
                    top: `calc(50% + ${y}px - 95px)`,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 153,
                    height: 195,
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <Box 
                    component="img"
                    alt={value.name}
                    src={value.icon}  
                    sx={{
                      width: 150,
                      height: 150,
                      padding: 0.3,
                      borderRadius: "50%",
                      backgroundColor: selectedValue.name === value.name ? "#0A435E" : "#189AB4",
                    }}
                  />
                  <Typography sx={{ mt: 1, fontSize: "24px", fontWeight: "bold", color: selectedValue.name === value.name ? "#003A55" : "#189AB4" }}>
                    {value.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>

          <Typography
            variant="subtitle1"
            align="center"
            maxWidth={297}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              fontWeight: "bold",
              color: "#003A55",
            }}
          >
            {selectedValue.description}
          </Typography>
        </Box>

        <Typography
          variant="h3"
          sx={{
            position: "absolute",
            zIndex: 0,
            bottom: 25,
            right: 135,
            textAlign: "right",
            lineHeight: "0.8",
            fontWeight: "bold",
            color: "#fff",
            fontSize: { xs: "100px", md: "128px" },
            textTransform: "uppercase",
            whiteSpace: "pre-line", // Forces "OUR" and "VALUES" to appear on different lines
          }}
        >
          valori{`\n`}
        </Typography>
      </Box>




      <Box 
        sx={{
          display: { xs: "block", xxl: "none"},
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: 569,
            backgroundImage: `url(${valuesBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              position: "absolute", // Keeps it from shifting
              top: "26px", // Adjust for different screen sizes
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
              width: "100%", // Ensures it's centered properly
            }}
          >
            <Typography
              sx={{
                paddingTop: "32px",
                fontWeight: "bold",
                color: "#fff",
                fontSize: "100px",
                textTransform: "uppercase",
                lineHeight: "0.8",
              }}
            >
              valori
            </Typography>
          </Box>
          <Swiper
            spaceBetween={30}
            slidesPerView={3.4} // Show a bit of the next slide
            centeredSlides={true}
            initialSlide={1}
            style={{
              width: "100%", // Full screen width
              height: 200,
              marginTop: "180px",
              marginBottom: "30px",
            }}
          >
            {valuesMobile.map((value) => (
              <SwiperSlide key={value.name}>
                <Box
                  onClick={() => setSelectedValue(value)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 10,
                    height: 200,
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <Box
                    component="img"
                    alt={value.name}
                    src={value.icon}
                    sx={{
                      width: 105,
                      height: 105,
                      padding: 1,
                      borderRadius: "50%",
                      backgroundColor: selectedValue.name === value.name ? "#0A435E" : "#189AB4",
                    }}
                  />
                  <Typography sx={{ mt: 1, fontSize: "18px", fontWeight: "bold", color: "#003A55" }}>
                    {value.name}
                  </Typography>
                  
                </Box>
              </SwiperSlide>
              
            ))}
          </Swiper>
          <Typography
            variant="subtitle1"
            align="center"
            maxWidth={385}
            sx={{
              height: "150px",
              textAlign: "center",
              fontWeight: "bold",
              color: "#003A55",
              mt: 2,
            }}
          >
            {selectedValue.description}
          </Typography>
        </Box>
      </Box>

      {/* Team section */}
      <Box sx={{ backgroundColor: '#fff', py: 4 }} >
        <Box width={{ xs: "100%", xxxl: "80%"}} margin="0 auto">
          <Typography
            variant="h4"
            align="center"
            sx={{
              mb: {xs: 2, xl: 4 },
              fontWeight: "bold", 
              color: '#0A435E',
            }}
          >
            Meet the team
          </Typography>
          <Box width={{ xs: "100%", md: "100%", lg: "90%", xl: "70%", xxxl: "70%"}}  
            sx={{  
              paddingLeft: { xs: "10px", xxl: "0px" }, 
              margin: '0 auto', 
            }}
          >
            <Slider key={authorsAM.length} {...authorsCarouselSettings}>
              {authorsAM.map((author) => (
                <Box
                  key={author.id}
                  sx={{
                    width: "156px",
                    // minWidth: { xs: 156, xxl: 169 }, 
                    // maxWidth: { xs: 156, xxl: 169 }, 
                    borderRadius: 2,
                    textAlign: 'center',
                    position: 'relative',
                    px: "10px",
                  }}
                >
                  <Box
                    component="img"
                    src={author.pictureUrl}
                    alt={author.name}
                    sx={{
                      width: "100%",
                      height: {xs: 269, xxl: 294},
                      objectFit: 'cover',
                      borderRadius: 5
                    }}
                  />
                  <Typography variant="body1"
                     sx={{
                      mt: 2,
                      color: "#0A435E",
                      textTransform: "none",
                      fontWeight: "700", 
                    }}
                  >
                    {author.name}
                  </Typography>
                  <Typography variant="body2"
                    sx={{
                      mt: 1,
                      color: "#0A435E",
                      textTransform: "none",
                      fontWeight: "100", 
                    }}
                  >
                    {author.expertise}
                  </Typography>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Box>

      {/* Map section */}
      <Box
        sx={{
          backgroundColor: "#189AB4",
          py: { xs: 2, md: 4 },
          px: 0,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#189AB4",
            color: "#fff",
            py: { xs: 2, xxl: 4 },
            px: { xs: 0, xxl: 6 },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center", 
            justifyContent: "center", 
            gap: 4,
          }}
        > 
          {/* Title Section */}
          <Box sx={{ textAlign: "right", whiteSpace: "nowrap" }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "#fff"
              }}
            >
              AM community
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "#fff"
              }}
            >
              over the world
            </Typography>
          </Box>

          {/* Separator */}
          <Box
            sx={{
              display: { xs: "none", xxl: "block" },
              width: "3px", 
              height: "55px", 
              backgroundColor: "#0A435E", 
            }}
          ></Box>

          {/* Paragraph Section */}
          <Typography
            variant="body2"
            sx={{
              maxWidth: { xs: "380px", xxl: "774px" }, 
              lineHeight: 1.6,
              color: "#fff",
              textAlign: "left"
            }}
          >
            What began as a small group driven by a shared vision of success has grown into a thriving community across five countries and over ten cities. 
            Academillion now connects people worldwide through education, investments, and collaboration. With plans for global expansion, our mission remains the same: 
            to redefine success and bring opportunity to even more dreamers and doers around the world.
          </Typography>
        </Box>

        {/* Map Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            overflowX: { xs: "auto", xxl: "hidden" },
            scrollbarWidth: "none", 
            "&::-webkit-scrollbar": { display: "none" }, 
          }}
        >
          <Box
            component="img"
            src={worldMap}
            alt="World Map"
            sx={{
              paddingLeft: { xs: "150px", xxl: "0" },
              width: { xs: "200%", md: "100%" },
              //maxWidth: "974px", 
              maxWidth: "1330px", 
            }}
          />
        </Box>
      </Box>
      
      {/* Testimonials Swiper */}
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          mb: 2
        }}
      >
        <Typography variant="h4" align="center"
          sx={{
            mt: { xs: 2, xxl: 4 },
            mb: { xs: 2, xxl: 4 },
            fontWeight: "bold", 
            color: '#189AB4',
          }}
        >
          Hear from our members
        </Typography>
        <Box
          sx={{
            width: { xs: "100%", xxl: "69%" },
            margin: "0 auto",
          }}
        >
          <Swiper
            modules={[Pagination]}
            pagination={{
              clickable: true,
              bulletClass: "swiper-pagination-bullet",
              bulletActiveClass: "custom-swiper-pagination-bullet-active",
            }}
            slidesPerView={3}
            spaceBetween={5}
            breakpoints={{
              0: { slidesPerView: 1.5, spaceBetween: 10 },
              500: { slidesPerView: 3, spaceBetween: 20 },
            }}
            style={{
              paddingBottom: "40px",
            }}
          >
            {textTestimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <Card
                  sx={{
                    p: { xs: 2, xxl: 3 },
                    borderRadius: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "#F2F2F2",
                    textAlign: "left",
                    width: "80%",
                    maxWidth: { xs: "80%", xxl: "100%" },
                    margin: "0 auto",
                    position: "relative",
                  }}
                >
                  <Avatar
                    src={testimonial.avatar}
                    alt={testimonial.name}
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "16px",
                      position: "absolute",
                      left: "-15px",
                      zIndex: 1,
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <Box sx={{ ml: 6 }}>
                    <Typography variant="body2" sx={{ color: "#000" }}>
                      {testimonial.text}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "#189AB4",
                        mt: 4,
                      }}
                    >
                      {testimonial.name}
                    </Typography>
                  </Box>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>


      {/* Ambassador section */}
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${ambassadorBackground})`,
          backgroundSize: { xs: "cover", xxl: "auto" },
          backgroundPosition: { xs: "center", xxl: "left" },
          backgroundRepeat: { xs: "no-repeat", xxl: "repeat-x" },
          color: "#fff",
          textAlign: "center",
          py: { xs: 6, xxl: 5 },
        }}
      >
        {/* Overlay Filter */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#0A435E",
            opacity: 0.8,
            zIndex: 1,
          }}
        ></Box>

        {/* Content */}
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            px: { xs: 2, xxl: 6 },
            width: { xs: "100%", xxl: "80%" },
            margin: "0 auto"
          }}
        >
          {/* Heading */}
          <Typography
            variant="h2"
            sx={{
              color: "#fff",
              fontWeight: "bold",
              mb: 2,
            }}
          >
            Do you want to become an AM ambassador?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#fff",
              fontWeight: "regular",
              mb: 4,
              maxWidth: "600px",
              mx: "auto",
              lineHeight: 1.8,
            }}
          >
            Elevate your career with Academillion: meaningful work, rewarding opportunities, and limitless flexibility.
          </Typography>

          {/* Icons with Labels */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: { xs: 2, md: 4 },
              mb: 4,
            }}
          >
            {/* Real Estate */}
            <Box
              sx={{
                flex: "1 1 25%", // Adjusts to two columns on mobile
                maxWidth: "208px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <Box
                component="img"
                src={realEstateIcon}
                alt="Real Estate"
                sx={{ width: "53px", mb: 1 }}
              />
              <Typography
                sx={{
                  color: "#74E6DC",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                Real Estate
              </Typography>
            </Box>

            {/* Crypto */}
            <Box
              sx={{
                flex: "1 1 25%",
                maxWidth: "208px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <Box
                component="img"
                src={cryptoIcon}
                alt="Crypto"
                sx={{ width: "53px", mb: 1 }}
              />
              <Typography
                sx={{
                  color: "#74E6DC",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                Crypto
              </Typography>
            </Box>

            {/* Arts */}
            <Box
              sx={{
                flex: "1 1 25%",
                maxWidth: "208px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <Box
                component="img"
                src={artsIcon}
                alt="Arts"
                sx={{ width: "53px", mb: 1 }}
              />
              <Typography
                sx={{
                  color: "#74E6DC",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                Arts
              </Typography>
            </Box>

            {/* Stock Market */}
            <Box
              sx={{
                flex: "1 1 25%",
                maxWidth: "208px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <Box
                component="img"
                src={stockMarketIcon}
                alt="Stock Market"
                sx={{ width: "53px", mb: 1 }}
              />
              <Typography
                sx={{
                  color: "#74E6DC",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                Stock Market
              </Typography>
            </Box>

            {/* Joint Ventures */}
            <Box
              sx={{
                flex: "1 1 50%",
                maxWidth: "208px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <Box
                component="img"
                src={jointVenturesIcon}
                alt="Joint Ventures"
                sx={{ width: "53px", mb: 1 }}
              />
              <Typography
                sx={{
                  color: "#74E6DC",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                Joint Ventures
              </Typography>
            </Box>
          </Box>

          {/* Call-to-Action Button */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              color: "#0A435E",
              fontSize: "20px",
              fontWeight: "bold",
              borderRadius: "24px",
              textTransform: "none",
              px: 4,
              py: 1.5,
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Become an Ambassador
          </Button>
        </Box>
      </Box>






      <Footer/>

    </Box>
  );
};

export default OurCulture;